import { Theme, Typography } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { PureComponent } from "react";

import { iconStyle } from "../../containers/OrganizationContainer";
import { introTextStyle } from "./DeveloperIntro";
import "./DeveloperIntro.css";

export type DeveloperIntroStepsStep3Props = { username: string };

const styles = (theme: Theme) => ({
  introText: introTextStyle,
  icon: iconStyle,
});

class DeveloperIntroStepsStep3 extends PureComponent<
  DeveloperIntroStepsStep3Props & WithStyles<typeof styles>
> {
  public render() {
    const { classes } = this.props;
    return (
      <div>
        <Typography className={classes.introText}>
          Below you’ll see all of the topics and corresponding SSE endpoints
          that you have created. You can preview the data being sent over an
          endpoint by clicking on the "eye" icon (
          <span className="intro-icon">
            <VisibilityIcon className={classes.icon} />
          </span>
          ). In addition, you’ll find sample SSE endpoints (they start with
          “sandbox”) that have pre populated data flowing over them if you don’t
          want to bother publishing your own messages.
        </Typography>
        <Typography className={classes.introText}>
          When you're ready for more advanced configuration, such as connecting
          to your own Kafka broker, press the <strong>Add a New App</strong>{" "}
          button at the top of the screen.
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(DeveloperIntroStepsStep3);
