import { Typography } from "@material-ui/core";
import { ConnectedRouter } from "connected-react-router";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import Types from "Types";

import Header from "../components/Header";
import IEWelcome from "../components/IEWelcome";
import { authThunks } from "../features/auth";
import { usersModels, usersSelectors, usersThunks } from "../features/users";
import * as gtm from "../services/googleTagManager";
import { history } from "../store/store";
import AuthenticatorContainer from "./AuthenticatorContainer";
import OrganizationContainer from "./OrganizationContainer";
import "./Root.css";

type MatchParams = {
  orgName: string;
};

export type RootProps = {
  user: usersModels.User;
  pathname: string;
  fetchSessionUser: () => void;
  signOut: () => void;
};

class Root extends Component<RootProps> {
  public render() {
    const { user, signOut } = this.props;
    return (
      <ConnectedRouter history={history}>
        <div className="App">
          <Header
            onClickDocumentation={this.handleClickDocumentation}
            onClickDemos={this.handleClickDemos}
            onClickTutorial={this.handleClickTutorial}
            user={user}
            signOut={signOut}
          />
          {navigator.appName === "Microsoft Internet Explorer" ||
          !!(
            navigator.userAgent.match(/Trident/) ||
            navigator.userAgent.match(/rv:11/)
          ) ? (
            <IEWelcome />
          ) : (
            <AuthenticatorContainer>
              <div className="App-content">
                <Switch>
                  <Route
                    path="/:orgName"
                    render={this.renderOrganizationContainer}
                  />
                  <Route path="/" render={this.renderRedirect} />
                </Switch>
              </div>
            </AuthenticatorContainer>
          )}

          <footer>
            <Typography variant="caption">
              Copyright © 2020 Tenefit. All rights reserved.
            </Typography>
            <Typography
              variant="caption"
              onClick={this.handleClickPrivacy}
              className="link"
            >
              Privacy
            </Typography>
            <Typography
              variant="caption"
              onClick={this.handleClickTermsOfService}
              className="link"
            >
              Terms of Service
            </Typography>
          </footer>
        </div>
      </ConnectedRouter>
    );
  }

  private renderOrganizationContainer = (
    routerProps: RouteComponentProps<MatchParams>
  ) => {
    const paramTestExists =
      new URLSearchParams(routerProps.location.search).get("test") !== null;
    return (
      <OrganizationContainer
        orgName={routerProps.match.params.orgName}
        paramTest={paramTestExists}
      />
    );
  };

  private renderRedirect = () => {
    const { user } = this.props;
    return user.organizations.length > 0 ? (
      <Redirect to={`/${user.organizations[0]}`} />
    ) : null;
  };

  private handleClickDocumentation = () => {
    gtm.headerClickLink("documentation");
    window.open("https://docs.tenefit.com/tenefit.cloud/what-is-tenefit-cloud");
  };

  private handleClickDemos = () => {
    gtm.headerClickLink("demos");
    window.open("https://demo.tenefit.cloud/");
  };

  private handleClickTutorial = () => {
    gtm.headerClickLink("tutorial");
    window.open(
      "https://docs.tenefit.com/tenefit.cloud/tutorials/tutorials-overview"
    );
  };

  private handleClickPrivacy = () => window.open("https://nten.io/privacy/");

  private handleClickTermsOfService = () =>
    window.open("https://nten.io/terms-of-service/");
}

const mapStateToProps = (state: Types.RootState) => ({
  user: usersSelectors.getSessionUser(state.users)!,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = {
  fetchSessionUser: usersThunks.fetchSessionUser,
  signOut: authThunks.signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
