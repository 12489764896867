import * as partitionedTable from "../../utils/partitionedTable";
import { KeysState } from "./reducer";

export const getKeys = (state: KeysState, orgName: string) => {
  return partitionedTable.read(state.records, orgName);
};

export const getKey = (state: KeysState, orgName: string, keyName: string) => {
  return partitionedTable.read(state.records, orgName, keyName);
};
