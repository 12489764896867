import {
  Collapse,
  IconButton,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { PureComponent } from "react";

import { copyToClipboard } from "../clipboard";
import KafkaTopicTableContainer from "../containers/KafkaTopicTableContainer";
import KafkaTopicTableSandboxContainer from "../containers/KafkaTopicTableSandboxContainer";
import { DomainsState } from "../features/domains";
import { edgeConfigsModels } from "../features/edgeConfigs";
import { UserPrefsState } from "../features/userPrefs";
import {
  PATH_FOR_AUTO_SSE_MAPPING,
  TENEFIT_SANDBOX_CONFIG_NAME,
} from "../globalConstants";
import DeleteAllRoutesDialog from "./DeleteAllRoutesDialog";
import DeleteConfigDialog from "./DeleteConfigDialog";
import DeleteRouteDialog from "./DeleteRouteDialog";
import EdgeState from "./EdgeState";
import CopyIcon from "./icons/Copy";
import Kafka from "./Kafka";
import MapSseEndpointsDialog from "./MapSseEndpointsDialog";

import "./Mapping.css";

export type MappingProps = {
  edgeConfig: edgeConfigsModels.EdgeConfig;
  domainsState: DomainsState;
  userPrefs: UserPrefsState;
  testConnection: (configName: string, orgName: string) => void;
  addRoute: (
    location: string,
    routeType: edgeConfigsModels.RouteType,
    topicName: string,
    routePattern: string,
    replyTo: string | null
  ) => void;
  editRoutePattern: (
    location: string,
    route: edgeConfigsModels.Route,
    newRoutePattern: string
  ) => void;
  reorderRoutes: (
    location: string,
    startIndex: number,
    endIndex: number
  ) => void;
  deleteRoute: (location: string, route: edgeConfigsModels.Route) => void;
  deleteAllRoutes: () => void;
  mapSseEndpoints: (selectedTopics: string[]) => void;
  deleteConfig: () => void;
  dismissNewConfigAddEndpointHint: () => void;
  dismissNewConfigEndpointHint: () => void;
  dismissNewConfigTopicHint: () => void;
  dismissNewConfigSaveHint: () => void;
  openFAQ: () => void;
};

export type MappingState = {
  isDeleting: boolean;
  deletingRoute: edgeConfigsModels.Route | null;
  deletingAllRoutes: boolean;
  showingMapSseEndpointsDialog: boolean;
  collapsed: boolean;
  copiedSnackbarOpen: boolean;
};

export default class Mapping extends PureComponent<MappingProps, MappingState> {
  public state = {
    isDeleting: false,
    deletingRoute: null,
    deletingAllRoutes: false,
    showingMapSseEndpointsDialog: false,
    collapsed: false,
    copiedSnackbarOpen: false,
  };

  public render() {
    const {
      edgeConfig,
      userPrefs,
      addRoute,
      editRoutePattern,
      reorderRoutes,
      deleteConfig,
      openFAQ,
    } = this.props;
    const mapping = edgeConfig.config.mapping[0];
    // Commented out for github ticket kaazing/kaazing.io#1993
    // const mappingLocation =
    //   mapping &&
    //   mapping.location.replace("https://", "").replace(/:443(\/)?/, "");
    // const domain =
    //   mappingLocation &&
    //   domainsSelectors.getDomain(
    //     domainsState,
    //     edgeConfig.organization,
    //     mappingLocation
    //   );
    const {
      isDeleting,
      deletingRoute,
      deletingAllRoutes,
      showingMapSseEndpointsDialog,
      collapsed,
      copiedSnackbarOpen,
    } = this.state;
    const isSandbox = edgeConfig.name === TENEFIT_SANDBOX_CONFIG_NAME;
    const domain = new URL(mapping.location).hostname;
    const ssePathPrefix = `/${PATH_FOR_AUTO_SSE_MAPPING}/`;
    const existingSseRouteTopics = mapping.routes
      .filter((route) => route.routePattern.startsWith(ssePathPrefix))
      .map((route) => route.routePattern.slice(ssePathPrefix.length));
    return (
      <Paper className="Mapping">
        <div className="Mapping-row Mapping-row--header">
          <div className="Mapping-row--header-left">
            <Typography align="left" variant="h5">
              <b>{edgeConfig.name}</b>
            </Typography>
            <div className="Mapping-row--header-left-domain">
              <Typography align="left">{mapping && domain}</Typography>
              <Tooltip
                placement="top"
                title="Copy domain"
                disableFocusListener={true}
              >
                <IconButton
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() => this.handleCopyDomain(domain)}
                  className="domain-copy-button"
                >
                  <CopyIcon className="Endpoint-button-icon" />
                </IconButton>
              </Tooltip>
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={copiedSnackbarOpen}
                message={<span>Copied to clipboard</span>}
              />
            </div>
          </div>
          {/* Commented out for github ticket kaazing/kaazing.io#1993: {domain && <Usage usage={domain.usage} />} */}
          <Tooltip
            title="Delete this application configuration"
            placement="top"
            disableFocusListener={true}
          >
            <IconButton onClick={this.showDeleteDialog}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className="Mapping-row Mapping-row--subheader">
          <Kafka
            address={edgeConfig.config.kafka}
            caCertFilename={edgeConfig.caCertFilename}
            clientCertFilename={edgeConfig.clientCertFilename}
            clientKeyFilename={edgeConfig.clientKeyFilename}
          />
          <div className="Mapping-labeledValue">
            <Typography variant="caption">Status:</Typography>
            <EdgeState edgeConfig={edgeConfig} />
          </div>
        </div>
        {isSandbox && (
          <div className="Mapping-row Mapping-row--subheader intro">
            <div className="Mapping-row--subheader-group">
              <Typography align="left" gutterBottom={true}>
                This <strong>{TENEFIT_SANDBOX_CONFIG_NAME}</strong> app
                configuration will automatically list routes for Kafka topics
                that start with <code>{edgeConfig.organization}.*</code> (see
                Step 1 in the developer intro, above, for details).
              </Typography>
              <Typography align="left">
                In addition, topics that begin with <code>sandbox.*</code> will
                also be shown here. They contain demo data that you can use for
                playing around.
              </Typography>
            </div>
          </div>
        )}
        {mapping && (
          <div>
            <div className="Mapping-row Mapping-row--endpointHeader">
              <div className="Mapping-endpointExpander">
                <IconButton
                  onClick={this.handleToggleCollapsed}
                  className={`Mapping-expandButton ${
                    collapsed ? "" : "Mapping-expandButton--expanded"
                  }`}
                >
                  <ExpandMoreIcon />
                </IconButton>
                <Typography>Endpoints</Typography>
              </div>
            </div>
            <Collapse in={!collapsed} timeout="auto" unmountOnExit={true}>
              {isSandbox ? (
                <KafkaTopicTableSandboxContainer
                  username={edgeConfig.organization}
                  location={mapping.location}
                  routes={mapping.routes}
                  isTesting={edgeConfig.isTestingConnection}
                  kafkaTopics={edgeConfig.topics}
                  edgeState={edgeConfig.edgeState}
                  openFAQ={openFAQ}
                />
              ) : (
                <KafkaTopicTableContainer
                  location={mapping.location}
                  routes={mapping.routes}
                  isTesting={edgeConfig.isTestingConnection}
                  kafkaTopics={edgeConfig.topics}
                  edgeState={edgeConfig.edgeState}
                  userPrefs={userPrefs}
                  isMsk={edgeConfig.config.isMsk}
                  configName={edgeConfig.name}
                  onTestConnection={this.handleRefreshTopics}
                  onAddRoute={addRoute.bind(undefined, mapping.location)}
                  onEditRoutePattern={editRoutePattern.bind(
                    undefined,
                    mapping.location
                  )}
                  onReorderRoutes={reorderRoutes.bind(
                    undefined,
                    mapping.location
                  )}
                  onDeleteRoute={this.showDeleteRouteDialog}
                  onDeleteAllRoutes={this.showDeleteAllRoutesDialog}
                  onClickMapSseEndpoints={this.showMapSseEndpointsDialog}
                  openFAQ={openFAQ}
                />
              )}
            </Collapse>
          </div>
        )}
        <DeleteConfigDialog
          open={isDeleting}
          onClose={this.closeDeleteDialog}
          onConfirm={deleteConfig}
          name={edgeConfig.name}
          location={mapping && mapping.location}
          isDeleting={false}
        />
        <DeleteRouteDialog
          onClose={this.hideDeleteRouteDialog}
          onConfirm={this.confirmDeleteRoute}
          route={deletingRoute}
          location={mapping && mapping.location}
        />
        {showingMapSseEndpointsDialog && (
          <MapSseEndpointsDialog
            location={mapping.location}
            kafkaTopics={edgeConfig.topics}
            existingSseRouteTopics={existingSseRouteTopics}
            onCancel={this.hideMapSseEndpointsDialog}
            onConfirm={this.confirmMapSseEndpoints}
          />
        )}
        {deletingAllRoutes && (
          <DeleteAllRoutesDialog
            configName={edgeConfig.name}
            onClose={this.hideDeleteAllRoutesDialog}
            onConfirm={this.confirmDeleteAllRoutes}
          />
        )}
      </Paper>
    );
  }

  private showDeleteDialog = () => this.setState({ isDeleting: true });
  private closeDeleteDialog = () => this.setState({ isDeleting: false });

  private handleToggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  private handleRefreshTopics = () => {
    const { edgeConfig, testConnection } = this.props;
    testConnection(edgeConfig.name, edgeConfig.organization);
  };

  private showDeleteRouteDialog = (route: edgeConfigsModels.Route) =>
    this.setState({ deletingRoute: route });
  private hideDeleteRouteDialog = () => this.setState({ deletingRoute: null });
  private confirmDeleteRoute = () => {
    this.props.deleteRoute(
      this.props.edgeConfig.config.mapping[0].location,
      this.state.deletingRoute!
    );
    this.hideDeleteRouteDialog();
  };

  private showDeleteAllRoutesDialog = (route: edgeConfigsModels.Route) =>
    this.setState({ deletingAllRoutes: true });
  private hideDeleteAllRoutesDialog = () =>
    this.setState({ deletingAllRoutes: false });
  private confirmDeleteAllRoutes = () => {
    this.props.deleteAllRoutes();
    this.hideDeleteAllRoutesDialog();
  };

  private showMapSseEndpointsDialog = (route: edgeConfigsModels.Route) =>
    this.setState({ showingMapSseEndpointsDialog: true });
  private hideMapSseEndpointsDialog = () =>
    this.setState({ showingMapSseEndpointsDialog: false });
  private confirmMapSseEndpoints = (selectedTopics: string[]) => {
    this.props.mapSseEndpoints(selectedTopics);
    this.hideMapSseEndpointsDialog();
  };

  private handleCopyDomain = (domain: string) => {
    copyToClipboard(domain);
    this.setState({ copiedSnackbarOpen: true });
    setTimeout(() => {
      this.setState({ copiedSnackbarOpen: false });
    }, 2000);
  };
}
