import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import * as partitionedTable from "../../utils/partitionedTable";
import * as actions from "./actions";
import { CREATE_KEY_SUCCESS, FETCH_KEYS_SUCCESS } from "./constants";
import { Key } from "./models";

export type KeysAction = ActionType<typeof actions>;

export type KeysState = {
  records: partitionedTable.PartitionedTable<Key>;
};

export default combineReducers<KeysState, KeysAction>({
  records: (state = {}, action) => {
    switch (action.type) {
      case FETCH_KEYS_SUCCESS:
        return action.payload.reduce(
          (state, key) =>
            partitionedTable.create(state, key.organization, key.name, key),
          state
        );
      case CREATE_KEY_SUCCESS:
        return partitionedTable.create(
          state,
          action.payload.organization,
          action.payload.name,
          action.payload
        );
      default:
        return state;
    }
  },
});
