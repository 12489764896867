import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import InfoIcon from "@material-ui/icons/Info";
import React, { useEffect, useState } from "react";

import { PATH_FOR_AUTO_SSE_MAPPING } from "../globalConstants";

export type MapSseEndpointsDialogProps = {
  location: string;
  kafkaTopics: string[];
  existingSseRouteTopics: string[];
  onCancel: () => void;
  onConfirm: (selectedTopics: string[]) => void;
};

type SelectedTopics = {
  [topic: string]: boolean;
};

const useStyles = makeStyles(
  {
    dialogText: { marginTop: 6, marginBottom: 12 },
    dialogTextContentRoot: { marginBottom: 0 },
    smallLinkText: { fontSize: 12, color: "rgba(0, 0, 0, 0.54)" },
    row: {
      "&.Mui-selected": { backgroundColor: green[500] },
      "&.Mui-selected .MuiTableCell-body": { color: "#ffffff" },
      "&.Mui-selected:hover": { backgroundColor: green[300] },
    },
    rowExists: {
      "& > td": { color: "rgba(0, 0, 0, 0.26)" },
      "&:hover": { backgroundColor: "inherit !important" },
    },
    existsIcon: {
      marginRight: 10,
      color: green[800],
      position: "relative",
    },
  },
  { name: "sensorsViewsContainer" }
);

const MapSseEndpointsDialog = (props: MapSseEndpointsDialogProps) => {
  const classes = useStyles();

  const {
    location,
    kafkaTopics,
    existingSseRouteTopics,
    onCancel,
    onConfirm,
  } = props;

  const [selectedTopics, setSelectedTopics] = useState({} as SelectedTopics);

  const url = new URL(location);
  const endpointBase = `${url.protocol}//${url.host}/${PATH_FOR_AUTO_SSE_MAPPING}`;

  const handleClickSelectAll = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const newSelectedTopics = { ...selectedTopics } as SelectedTopics;
    Object.keys(newSelectedTopics)
      .filter((topic) => !existingSseRouteTopics.includes(topic))
      .forEach((topic) => {
        newSelectedTopics[topic] = true;
      });
    setSelectedTopics({ ...newSelectedTopics });
  };

  const handleClickSelectNone = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const newSelectedTopics = { ...selectedTopics } as SelectedTopics;
    Object.keys(newSelectedTopics)
      .filter((topic) => !existingSseRouteTopics.includes(topic))
      .forEach((topic) => {
        newSelectedTopics[topic] = false;
      });
    setSelectedTopics({ ...newSelectedTopics });
  };

  const handleClickRow = (evt: React.MouseEvent<HTMLElement>) => {
    const topic = evt.currentTarget
      .querySelectorAll(".topicName")[0]
      .getAttribute("data-topic")!;
    if (!existingSseRouteTopics.includes(topic)) {
      setSelectedTopics({
        ...selectedTopics,
        [topic]: !selectedTopics[topic],
      });
    }
  };

  const handleClickConfirm = () => {
    onConfirm(
      Object.keys(selectedTopics).filter((topic) => selectedTopics[topic])
    );
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const selected = {} as SelectedTopics;
    kafkaTopics.forEach((topic) => {
      if (selectedTopics.hasOwnProperty(topic)) {
        selected[topic] = selectedTopics[topic];
      } else {
        selected[topic] = false;
      }
    });
    setSelectedTopics({ ...selected });
  }, [kafkaTopics]);
  /* eslint-disable react-hooks/exhaustive-deps */

  return (
    <Dialog open={true} onClose={onCancel} maxWidth={false}>
      <DialogTitle>Map SSE Endpoints</DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: classes.dialogTextContentRoot }}>
          The following table shows the available topics and the associated SSE
          endpoint that will be created.
        </DialogContentText>
        <DialogContentText classes={{ root: classes.dialogTextContentRoot }}>
          Click the rows you'd like to have mapped and press{" "}
          <strong>Update App</strong>
        </DialogContentText>

        <div className={classes.dialogText}>
          <Typography className={classes.smallLinkText}>
            <Link href="#" onClick={handleClickSelectAll}>
              [select all]
            </Link>{" "}
            <Link href="#" onClick={handleClickSelectNone}>
              [select none]
            </Link>
          </Typography>
        </div>

        <TableContainer component={Paper}>
          <Table size="small" aria-label="endpoints table">
            <TableHead>
              <TableRow>
                <TableCell>Topic</TableCell>
                <TableCell>Endpoint</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {kafkaTopics.map((topic) => {
                const topicAlreadyMapped = existingSseRouteTopics.includes(
                  topic
                );
                return (
                  <TableRow
                    key={topic}
                    selected={selectedTopics[topic]}
                    classes={{
                      root: !topicAlreadyMapped
                        ? classes.row
                        : classes.rowExists,
                    }}
                    hover
                    onClick={handleClickRow}
                  >
                    <TableCell className="topicName" data-topic={topic}>
                      <div style={{ display: "flex" }}>
                        {topicAlreadyMapped && (
                          <Tooltip
                            placement="top-start"
                            title="This endpoint is already in your app configuration"
                            disableFocusListener={true}
                            className={classes.existsIcon}
                            arrow
                          >
                            <InfoIcon fontSize="small" />
                          </Tooltip>
                        )}
                        <Typography>{topic}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {endpointBase}/{topic}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            color="primary"
            onClick={handleClickConfirm}
            disabled={
              Object.keys(selectedTopics).findIndex(
                (topic) => selectedTopics[topic]
              ) === -1
            }
          >
            Update app
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default MapSseEndpointsDialog;
