export type Message = {
  type: MessageType;
  message: string;
};

export enum MessageType {
  Progress = "progress",
  Success = "success",
  Warning = "warning",
  Failure = "failure",
}
