import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Types from "Types";

import KafkaTopicTableRow, {
  KafkaTopicTableRowProps,
} from "../components/KafkaTopicTableRow";
import { userPrefsActions } from "../features/userPrefs";

const KafkaTopicTableRowContainer = (props: KafkaTopicTableRowProps) => {
  return <KafkaTopicTableRow {...props} />;
};

const mapStateToProps = (state: Types.RootState) => {
  return { language: state.userPrefs.step2Language };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  selectDeveloperIntroStepLanguage: (step: string, language: number) =>
    dispatch(userPrefsActions.selectDeveloperIntroStepLanguage(step, language)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KafkaTopicTableRowContainer);
