/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { Component } from "react";

const ENTER_KEY_CODE = 13;

export default class AuthPiece extends Component {
  state = { isBusy: false };
  inputs = {};
  mounted = false;

  componentDidMount() {
    this.setIsBusy = (isBusy) => this.setState({ isBusy });
    this.mounted = true;
    window.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    this.setIsBusy = () => {};
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY_CODE) {
      this.handleSubmit();
    }
  };

  get canSubmit() {
    return true;
  }

  handleSubmit = async () => {
    if (!this.canSubmit) {
      return;
    }
    this.setIsBusy(true);
    try {
      await this.submit();
    } catch (err) {}
    this.setIsBusy(false);
  };

  handleInputChange = (evt) => {
    this.inputs = this.inputs || {};
    const { name, value, type, checked } = evt.target;
    const check_type = ["radio", "checkbox"].includes(type);
    this.inputs[name] = check_type ? checked : value;
    this.inputs["checkedValue"] = check_type ? value : null;
  };

  submit() {}
}
