import { action } from "typesafe-actions";

import { Domain, Usage } from "../../services/api";
import {
  FETCH_DOMAINS,
  FETCH_DOMAINS_FAILURE,
  FETCH_DOMAINS_SUCCESS,
  FETCH_USAGE,
  FETCH_USAGE_FAILURE,
  FETCH_USAGE_SUCCESS,
} from "./constants";

export const fetchDomains = (orgName: string) =>
  action(FETCH_DOMAINS, { orgName });
export const fetchDomainsSuccess = (domains: Domain[]) =>
  action(FETCH_DOMAINS_SUCCESS, domains);
export const fetchDomainsFailure = (orgName: string, error: any) =>
  action(FETCH_DOMAINS_FAILURE, { orgName, error });

export const fetchUsage = (orgName: string, domainName: string) =>
  action(FETCH_USAGE, { orgName, domainName });
export const fetchUsageSuccess = (
  orgName: string,
  domainName: string,
  usage: Usage
) => action(FETCH_USAGE_SUCCESS, { orgName, domainName, usage });
export const fetchUsageFailure = (
  orgName: string,
  domainName: string,
  error: any
) => action(FETCH_USAGE_FAILURE, { orgName, domainName, error });
