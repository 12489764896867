import { combineEpics, Epic } from "redux-observable";
import { catchError, filter, ignoreElements, tap } from "rxjs/operators";
import Types from "Types";
import { isOfType } from "typesafe-actions";

import * as api from "../../services/api";
import { REMOVE_EDGE_CONFIG } from "./constants";

export const deleteEdgeConfig: Epic<
  Types.RootAction,
  Types.RootAction,
  Types.RootState
> = (action$, store$) =>
  action$.pipe(
    filter(isOfType(REMOVE_EDGE_CONFIG)),
    tap((action) => {
      api
        .deleteEdgeConfig(action.payload.orgName, action.payload.configName)
        .pipe(catchError((_, o) => o));
    }),
    ignoreElements()
  );

export default combineEpics(deleteEdgeConfig);
