export enum AuthState {
  signedIn = "signedIn",
  signIn = "signIn",
  signedOut = "signedOut",
  signedUp = "signedUp",
  signUp = "signUp",
  confirmSignUp = "confirmSignUp",
  forgotPassword = "forgotPassword",
  verifyContact = "verifyContact",
  requireNewPassword = "requireNewPassword",
}
