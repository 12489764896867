// The organization is set once when the app is loaded, and then
// included in any events sent to GTM as needed.
let organization: string = "-";

// TODO Remove if no longer needed.
const setOrganization = (name: string) => {
  organization = name;
};

const addNewAppDialogCancel = () => {
  window.dataLayer.push({
    event: "addNewAppDialogCancel",
    organization,
  });
};

const addNewAppDialogCreateConfigCustom = (
  configName: string,
  kafka: string
) => {
  window.dataLayer.push({
    event: "addNewAppDialogCreateConfigCustom",
    organization,
    configName,
    kafka,
  });
};

const addNewAppDialogCreateConfigSandbox = (
  configName: string,
  kafka: string
) => {
  window.dataLayer.push({
    event: "addNewAppDialogCreateConfigSandbox",
    organization,
    configName,
    kafka,
  });
};

const addNewAppDialogOpen = () => {
  window.dataLayer.push({
    event: "addNewAppDialogOpen",
    organization,
  });
};

const authPageLoad = (organizationName: string) => {
  organization = organizationName;
  window.dataLayer.push({
    event: "authPageLoad",
    organization,
  });
};

const authSignIn = (organizationName: string) => {
  organization = organizationName;
  window.dataLayer.push({
    event: "authSignIn",
    organization,
  });
};

const authSignUp = (organizationName: string) => {
  organization = organizationName;
  window.dataLayer.push({
    event: "authSignUp",
    organization,
  });
};

const authSignUpConfirmed = (organizationName: string) => {
  organization = organizationName;
  window.dataLayer.push({
    event: "authSignUpConfirmed",
    organization,
  });
};

const configDelete = (configName: string) => {
  window.dataLayer.push({
    event: "configDelete",
    organization,
    configName,
  });
};

const configOpenCodeTemplate = (endpoint: string) => {
  window.dataLayer.push({
    event: "configOpenCodeTemplate",
    organization,
    endpoint,
  });
};

const configOpenLiveStream = (
  domain: string,
  routePattern: string,
  topicName: string
) => {
  window.dataLayer.push({
    event: "configOpenLiveStream",
    organization,
    endpoint: `${domain}${routePattern}`,
  });
};

const configSelectCodeTemplateLanguage = (codeLanguage: string) => {
  window.dataLayer.push({
    event: "configSelectCodeTemplateLanguage",
    organization,
    codeLanguage,
  });
};

const devIntroHide = () => {
  window.dataLayer.push({
    event: "devIntroHide",
    organization,
  });
};

const devIntroExpandStep = (step: string) => {
  window.dataLayer.push({
    event: "devIntroExpandStep",
    organization,
    stepName: step,
  });
};

const devIntroSelectLanguageStep1 = (codeLanguage: string) => {
  window.dataLayer.push({
    event: "devIntroSelectLanguageStep1",
    organization,
    codeLanguage,
  });
};

const devIntroSelectLanguageStep2 = (codeLanguage: string) => {
  window.dataLayer.push({
    event: "devIntroSelectLanguageStep2",
    organization,
    codeLanguage,
  });
};

const devIntroShow = () => {
  window.dataLayer.push({
    event: "devIntroShow",
    organization,
  });
};

const headerClickLink = (linkName: string) => {
  window.dataLayer.push({
    event: "headerClickLink",
    organization,
    linkName,
  });
};

const clickVpcPeeringButton = () => {
  window.dataLayer.push({
    event: "clickVpcPeeringButton",
    organization,
  });
};

export {
  setOrganization,
  addNewAppDialogCancel,
  addNewAppDialogCreateConfigCustom,
  addNewAppDialogCreateConfigSandbox,
  addNewAppDialogOpen,
  authPageLoad,
  authSignUp,
  authSignUpConfirmed,
  authSignIn,
  configDelete,
  configOpenCodeTemplate,
  configOpenLiveStream,
  configSelectCodeTemplateLanguage,
  devIntroHide,
  devIntroExpandStep,
  devIntroSelectLanguageStep1,
  devIntroSelectLanguageStep2,
  devIntroShow,
  headerClickLink,
  clickVpcPeeringButton,
};
