import React, { PureComponent } from "react";
import { Paper, Popper, RootRef } from "@material-ui/core";

import "./HelpTooltip.css";

export default class HelpTooltip extends PureComponent {
  static defaultProps = {
    placement: "bottom",
    open: true,
  };

  state = { flash: false, userDidClose: false };

  componentDidMount() {
    this.setState({ flash: true });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ flash: true });
    }
    if (this.state.flash) {
      this.setState({ flash: false });
    }
  }

  onRootRef = (ref) => {
    this.childrenRef = ref;
  };

  onClose = (evt) => {
    this.setState({ userDidClose: true });
  };

  render() {
    const { children, placement, tooltipContent, open } = this.props;
    const { flash, userDidClose } = this.state;
    return (
      <React.Fragment>
        <RootRef rootRef={this.onRootRef}>{children}</RootRef>
        {this.childrenRef && (
          <Popper
            placement={placement}
            anchorEl={this.childrenRef}
            open={open && !userDidClose}
            className={`HelpTooltip ${flash ? "HelpTooltip--flash" : ""}`}
          >
            <div className="HelpTooltip-closeButton" onClick={this.onClose}>
              x
            </div>
            <Paper className="HelpTooltip-paper">{tooltipContent}</Paper>
          </Popper>
        )}
      </React.Fragment>
    );
  }
}
