import { Typography } from "@material-ui/core";
// import ClearIcon from "@material-ui/icons/Clear";
// import DoneIcon from "@material-ui/icons/Done";
// import RefreshIcon from "@material-ui/icons/Refresh";
import React, { PureComponent } from "react";

import KafkaTopicTableRowContainer from "../containers/KafkaTopicTableRowContainer";
import { edgeConfigsModels } from "../features/edgeConfigs";
import { TableRoute } from "./KafkaTopicTable";
import "./KafkaTopicTableRow.css";
import "./KafkaTopicTableSandbox.css";

import "./KafkaTopicTable.css";

export type KafkaTopicTableSandboxProps = {
  location: string;
  routes: ReadonlyArray<edgeConfigsModels.Route>;
  isTesting: boolean;
  kafkaTopics: string[];
  edgeState: edgeConfigsModels.EdgeState;
  username: string;
  openFAQ: () => void;
};

export type KafkaTopicTableSandboxState = {
  isAdding: boolean;
  addingTopicName: string;
  addingTopicPath: string;
};

export default class KafkaTopicTableSandbox extends PureComponent<
  KafkaTopicTableSandboxProps,
  KafkaTopicTableSandboxState
> {
  public state = {
    isAdding: false,
    addingTopicName: "",
    addingTopicPath: "",
  };

  public render() {
    const {
      username,
      location,
      routes,
      isTesting,
      kafkaTopics,
      edgeState,
      openFAQ,
    } = this.props;
    const existingPaths = routes.map((route) => route.routePattern);
    return (
      <div className="KafkaTopicTable KafkaTopicTableSandbox">
        <div className="KafkaTopicTableRow KafkaTopicTable-header">
          <div>
            <Typography variant="caption" align="left">
              Endpoint
            </Typography>
          </div>
          <div>
            <Typography variant="caption" align="left">
              Kafka Topic
            </Typography>
          </div>
          <div className="KafkaTopicTable-buttonContainer" />
        </div>
        {kafkaTopics
          .filter((topicName) => topicName.startsWith(username))
          .map((topicName) => {
            const route: edgeConfigsModels.Route & {
              topicName: string;
            } = {
              uid: `/${topicName}`,
              routeType: edgeConfigsModels.RouteType.sse,
              routePattern: `/${topicName}`,
              topicName,
            };
            return (
              <KafkaTopicTableRowContainer
                key={route.routePattern}
                location={location}
                route={route}
                edgeState={edgeState}
                existingPaths={existingPaths}
                isTesting={isTesting}
                topics={kafkaTopics}
                canEditRoutePattern={false}
                canDeleteRoute={false}
                openFAQ={openFAQ}
                tableRoutes={[] as TableRoute[]}
                collisions={[] as string[]}
              />
            );
          })}
        {kafkaTopics.findIndex((topicName) =>
          topicName.startsWith(username)
        ) !== -1 ? (
          <div className="user-topics-separator" />
        ) : null}
        {kafkaTopics
          .filter((topicName) => !topicName.startsWith(username))
          .map((topicName) => {
            const route: edgeConfigsModels.Route & {
              topicName: string;
            } = {
              uid: `/${topicName}`,
              routeType: edgeConfigsModels.RouteType.sse,
              routePattern: `/${topicName}`,
              topicName,
            };
            return (
              <KafkaTopicTableRowContainer
                key={route.routePattern}
                location={location}
                route={route}
                edgeState={edgeState}
                existingPaths={existingPaths}
                isTesting={isTesting}
                topics={kafkaTopics}
                canEditRoutePattern={false}
                canDeleteRoute={false}
                openFAQ={openFAQ}
                tableRoutes={[] as TableRoute[]}
                collisions={[] as string[]}
              />
            );
          })}
      </div>
    );
  }
}
