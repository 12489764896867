/**
 * Downloads a file named `filename` with `text` as the content.
 * Creates a temporary <a> element and simulates a click on it
 * before removing the element, prompting the system fs dialog.
 *
 * @param filename Name of the downloaded file, with extension
 * @param text Content of the downloaded file in utf-8
 */
export default (filename: string, text: string) => {
  const el = document.createElement("a");
  el.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  el.setAttribute("download", filename);
  el.style.display = "none";
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
};
