import {
  Button,
  Card,
  CardContent,
  createStyles,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";

import { edgeConfigsModels } from "../../features/edgeConfigs";
import { keysModels } from "../../features/keys";
import { organizationsModels } from "../../features/organizations";
import {
  MSK_HOSTNAME,
  TENEFIT_SANDBOX_CONFIG_NAME,
} from "../../globalConstants";
import BrowseFile from "../BrowseFile";
import FormSection from "../core/FormSection";

import "../CreateAppDialog.css";
import "./DirectDetails.css";

/*
 * For bring your own Kafka:
 * - preAllocatedDomainName will be undefined
 * - All connectivity fields are shown so the user can enter the details
 *
 * For MSK sandbox:
 * - preAllocatedDomainName will be set to the hostname for the MSK cluster
 * - All fields except config name will be hidden
 */

export type DirectDetailsProps = {
  organization: organizationsModels.Organization;
  existingConfigNames: string[];
  isCreating: boolean;
  temporaryEdgeConfig: edgeConfigsModels.EdgeConfig | null;
  isDone: boolean;
  publicKey?: keysModels.Key;
  isMskSandbox?: boolean;
  createAndTest: (
    name: string,
    orgName: string,
    protocol: string,
    domain: string,
    port: number,
    keyName?: string
  ) => void;
  removeEdgeConfig: (configName: string, orgName: string) => void;
  createKey: (orgName: string) => void;
  setCaCert: (caCert: File | null) => void;
  setClientCert: (clientCert: File | null) => void;
  setClientKey: (clientKey: File | null) => void;
};

export type DirectDetailsState = Readonly<{
  name: string;
  nameError: string | null;
  protocol: "tcp" | "tls";
  domainName: string;
  domainNameError: string | null;
  port: number;
  portError: string | null;
  cryptoFiles: Readonly<{
    caCert: File | null;
    clientCert: File | null;
    clientKey: File | null;
  }>;
}>;

const styles = (theme: Theme) =>
  createStyles({
    card: {
      marginTop: 24,
      marginBottom: 24,
    },
    cardContent: {
      display: "flex",
      flexDirection: "column",
    },
    uploadButton: {
      marginTop: theme.spacing(2),
    },
    uploadButtonIcon: {
      marginLeft: theme.spacing(1),
      fontSize: 20,
    },
  });

class DirectDetails extends Component<
  DirectDetailsProps & WithStyles<typeof styles>,
  DirectDetailsState
> {
  public state: DirectDetailsState = {
    name: "",
    nameError: null,
    protocol: this.props.isMskSandbox ? "tls" : "tcp",
    domainName: this.props.isMskSandbox ? MSK_HOSTNAME : "",
    domainNameError: null,
    port: this.props.isMskSandbox ? 9093 : 9092,
    portError: null,
    cryptoFiles: { caCert: null, clientCert: null, clientKey: null },
  };

  public componentDidUpdate(prevProps: DirectDetailsProps) {
    // If the user switches from MSK sandbox to direct, the hostname, port, etc details
    // are visible in the connectivity details fields.
    // So reset whenever user transitions from one to the other.
    if (prevProps.isMskSandbox !== this.props.isMskSandbox) {
      this.setState({
        protocol: this.props.isMskSandbox ? "tls" : "tcp",
        domainName: this.props.isMskSandbox ? MSK_HOSTNAME : "",
        port: this.props.isMskSandbox ? 9093 : 9092,
      });
    }
  }

  public render() {
    const {
      name,
      nameError,
      protocol,
      domainName,
      domainNameError,
      port,
      portError,
    } = this.state;
    const { isCreating, isDone, classes, isMskSandbox } = this.props;

    return (
      <FormSection title="Connectivity Details">
        <div className="CreateAppDialog-formRowSingleCol">
          <TextField
            name="configName"
            value={name}
            label="Name of your broker"
            placeholder="my-kafka"
            required={true}
            fullWidth={true}
            autoFocus={true}
            autoComplete="off"
            disabled={isDone}
            onChange={this.handleNameChange}
            error={!!nameError}
            helperText={nameError}
            className="CreateAppDialog-textField"
          />
        </div>
        {!isMskSandbox && (
          <div className="CreateAppDialog-formRowSingleCol">
            <FormControl
              disabled={isDone}
              className="CreateAppDialog-protocolInput"
            >
              <InputLabel shrink={true} htmlFor="protocol">
                Protocol
              </InputLabel>
              <Select
                name="protocol"
                value={protocol}
                onChange={this.handleProtocolChange}
                input={<Input name="protocol" id="protocol" />}
              >
                <MenuItem value="tcp">plaintext</MenuItem>
                <MenuItem value="tls">ssl</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="domain"
              value={domainName}
              label="Kafka cluster domain name"
              placeholder="mykafka.example.com"
              required={true}
              error={!!domainNameError}
              helperText={domainNameError}
              onChange={this.handleDomainNameChange}
              disabled={isDone}
            />
            <TextField
              name="port"
              value={port}
              label="Kafka cluster port"
              placeholder="9092"
              required={true}
              error={!!portError}
              helperText={portError}
              onChange={this.handlePortChange}
              disabled={isDone}
              className="CreateAppDialog-portInput"
            />
          </div>
        )}
        {!isMskSandbox && protocol === "tls" && (
          <React.Fragment>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography align="left">
                  <strong>Optional</strong>
                </Typography>
                <Typography align="left">
                  If your Kafka broker uses a server certificate signed by a
                  public CA then you do not need to upload a CA certificate.
                  However if your Kafka broker uses a server certificate signed
                  by a custom CA or is self-signed, then you need to provide the
                  CA certificate that trusts it.
                </Typography>
                <div className="cryptoFile">
                  <BrowseFile
                    labelText="Browse for CA certificate"
                    handleUpdate={this.handleFileUpdate("caCert")}
                  />
                </div>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography align="left">
                  <strong>Optional</strong>
                </Typography>
                <Typography align="left">
                  If your Kafka broker requires a client certificate in order to
                  connect, you need to provide both the client certificate and
                  its associated private key.
                </Typography>
                <div className="cryptoFile">
                  <BrowseFile
                    labelText="Browse for client certificate"
                    handleUpdate={this.handleFileUpdate("clientCert")}
                  />
                </div>
                <div className="cryptoFile">
                  <BrowseFile
                    labelText="Browse for client key"
                    handleUpdate={this.handleFileUpdate("clientKey")}
                  />
                </div>
              </CardContent>
            </Card>
          </React.Fragment>
        )}
        <div className="CreateAppDialog-formRowSingleCol">
          <Button
            variant="contained"
            color="primary"
            disabled={
              !name ||
              !!nameError ||
              !domainName ||
              !!domainNameError ||
              !port ||
              !!portError ||
              isCreating ||
              isDone
            }
            onClick={this.handleTestConnection}
          >
            Test connection
          </Button>
        </div>
      </FormSection>
    );
  }

  private handleNameChange: React.FormEventHandler = (evt) => {
    const target = evt.target as HTMLInputElement;
    const {
      temporaryEdgeConfig,
      removeEdgeConfig,
      existingConfigNames,
    } = this.props;
    if (temporaryEdgeConfig) {
      removeEdgeConfig(
        temporaryEdgeConfig.name,
        temporaryEdgeConfig.organization
      );
    }
    this.setState({
      name: target.value,
      nameError: this.nameError(target.value, existingConfigNames),
    });
  };

  private nameError(name: string, existingConfigNames: string[]) {
    if (existingConfigNames && existingConfigNames.indexOf(name) > -1) {
      return "A kafka with that name already exists";
    } else if (!/^([A-Za-z0-9_-])*$/.test(name)) {
      return "Name can only contain letters, numbers, -, and _";
    } else if (name === TENEFIT_SANDBOX_CONFIG_NAME) {
      return "This name is reserved. Please choose another.";
    } else {
      return null;
    }
  }

  private handleProtocolChange = (
    evt: React.ChangeEvent<{ value: unknown }>
  ) => {
    const target = evt.target as HTMLInputElement;
    const protocol = target.value as "tcp" | "tls";
    this.setState({
      protocol,
      port: protocol === "tcp" ? 9092 : 9093,
      portError: null,
    });
  };

  private handleDomainNameChange: React.FormEventHandler = (evt) => {
    const target = evt.target as HTMLInputElement;
    this.setState({
      domainName: target.value,
      domainNameError: this.domainNameError(target.value),
    });
  };

  private domainNameError(domainName: string) {
    return domainName.indexOf(":") > -1
      ? "Domain should not have a protocol (e.g. tcp://) or a port number (e.g. :9092)"
      : null;
  }

  private handlePortChange: React.FormEventHandler = (evt) => {
    const target = evt.target as HTMLInputElement;
    const value = Number(target.value);
    this.setState({
      port: value,
      portError: this.portError(value),
    });
  };

  private portError(port: number) {
    return isNaN(port) || port < 0 || port > 65535
      ? "Enter a valid port number between 0 and 65535"
      : null;
  }

  // Wrap the call so we know which file it is for.
  private handleFileUpdate = (fileType: string) => {
    return (result: File | null) => {
      const cryptoFiles = { ...this.state.cryptoFiles }; // Clone
      cryptoFiles[fileType] = result;
      this.setState({ cryptoFiles });
      if (fileType === "caCert") {
        this.props.setCaCert(result as File);
      } else if (fileType === "clientCert") {
        this.props.setClientCert(result as File);
      } else {
        this.props.setClientKey(result as File);
      }
    };
  };

  private handleTestConnection = () => {
    const { name, protocol, domainName, port } = this.state;
    const { publicKey } = this.props;

    this.props.createAndTest(
      name,
      this.props.organization.name,
      protocol,
      domainName,
      port,
      protocol === "tls" && publicKey ? publicKey.name : undefined
    );
  };
}

export default withStyles(styles)(DirectDetails);
