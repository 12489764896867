import React from "react";
import PropTypes from "prop-types";
import { Typography, CircularProgress } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";

import "./StatusMessage.css";

const StatusMessage = ({ type, children }) => (
  <div className={`StatusMessage StatusMessage-${type}`}>
    <div className="StatusMessage-iconWrapper">
      {type === "success" && <DoneIcon />}
      {type === "progress" && <CircularProgress size={24} />}
      {type === "warning" && <WarningIcon />}
      {type === "failure" && <ErrorIcon color="error" />}
    </div>
    <Typography color={type === "failure" ? "error" : "primary"}>
      {children}
    </Typography>
  </div>
);

StatusMessage.propTypes = {
  type: PropTypes.oneOf(["success", "progress", "warning", "failure"]),
};

export default StatusMessage;
