import React, { HTMLProps } from "react";

export type MousePropagationBlockerProps = {
  children: JSX.Element[] | JSX.Element;
} & HTMLProps<HTMLDivElement>;

const preventDefault: React.MouseEventHandler = (evt) => evt.stopPropagation();

/**
 * Prevents mouse events originating from children from propagating up to
 * parent components. Useful for example to prevent mouse events on a child of
 * a react-beautiful-dnd Draggable from draggin the whole Draggable.
 */
const MousePropagationBlocker = ({
  children,
  className,
}: MousePropagationBlockerProps) => (
  <div
    onMouseDownCapture={preventDefault}
    onMouseUpCapture={preventDefault}
    className={className}
  >
    {children}
  </div>
);

export default MousePropagationBlocker;
