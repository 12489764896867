import React from "react";
import { connect } from "react-redux";
import NotFound from "../components/NotFound";
import { push } from "connected-react-router";

const NotFoundContainer = ({ ...props }) => <NotFound {...props} />;

export default connect(null, {
  redirectToHome: () => push("/"),
})(NotFoundContainer);
