import { combineReducers } from "redux";
import Types from "Types";

import { addNewAppReducer } from "../features/addNewApp";
import { authConstants, authReducer } from "../features/auth";
import { domainsReducer } from "../features/domains";
import { keysReducer } from "../features/keys";
import { organizationsReducer } from "../features/organizations";
import { userPrefsReducer } from "../features/userPrefs";
import { usersReducer } from "../features/users";

const appReducer = combineReducers({
  auth: authReducer,
  addNewApp: addNewAppReducer,
  domains: domainsReducer,
  keys: keysReducer,
  organizations: organizationsReducer,
  users: usersReducer,
  userPrefs: userPrefsReducer,
});

export default (state: any, action: Types.RootAction) => {
  if (action.type === authConstants.SIGN_OUT) {
    state = undefined;
  }
  return appReducer(state, action as any);
};
