import { IconButton, Tooltip, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import WarningIcon from "@material-ui/icons/Warning";
import React, { PureComponent } from "react";

import { edgeConfigsModels } from "../features/edgeConfigs";
import { RouteType } from "../features/edgeConfigs/models";
import * as gtm from "../services/googleTagManager";
import MousePropagationBlocker from "./core/MousePropagationBlocker";
import TooltipIconButton from "./core/TooltipIconButton";
import Endpoint from "./Endpoint";
import { TableRoute } from "./KafkaTopicTable";
import LiveData from "./LiveData";

import "./KafkaTopicTableRow.css";

// The Route data model has topicName as optional for the $topic case. But topics will
// always render with a topic name, to show either a real topic mapping, or a synthetic
// topic for each topic found under $topic. Therefore topicName is made mandatory as part
// of route in these properties.
export type KafkaTopicTableRowProps = {
  location: string;
  route: edgeConfigsModels.Route & { topicName: string };
  edgeState: edgeConfigsModels.EdgeState;
  existingPaths: string[];
  isTesting: boolean;
  topics: string[];
  canEditRoutePattern: boolean;
  canDeleteRoute: boolean;
  language: number;
  collisions: string[];
  tableRoutes: ReadonlyArray<TableRoute>;
  openFAQ: () => void;
  onEditRoutePattern?: (routePattern: string) => void;
  onDelete?: () => void;
  selectDeveloperIntroStepLanguage: (step: string, language: number) => void;
};

export type KafkaTopicTableRowState = {
  showLiveData: boolean;
  isEditing: boolean;
};

class KafkaTopicTableRow extends PureComponent<
  KafkaTopicTableRowProps,
  KafkaTopicTableRowState
> {
  public state = {
    showLiveData: false,
    isEditing: false,
  };

  public render() {
    const {
      location,
      route,
      edgeState,
      existingPaths,
      isTesting,
      topics,
      canEditRoutePattern,
      canDeleteRoute,
      language,
      collisions,
      tableRoutes,
      onEditRoutePattern,
      onDelete,
      openFAQ,
      selectDeveloperIntroStepLanguage,
    } = this.props;
    const { showLiveData } = this.state;
    const endpoint = new URL(route.routePattern, location).href;
    const isDefunct =
      !isTesting &&
      topics.indexOf(route.topicName) === -1 &&
      route.routeType !== RouteType.kafka;
    const origin: string = new URL(location).origin;
    return (
      <div className="KafkaTopicTableRow-container">
        <div
          className={`KafkaTopicTableRow ${
            isDefunct ? "KafkaTopicTableRow--defunct" : ""
          }`}
        >
          <div className="KafkaTopicTableRow-endpoint">
            <MousePropagationBlocker className="KafkaTopicTableRow-endpoint-mouseWrapper">
              <Endpoint
                uid={route.uid!}
                routeType={route.routeType}
                baseUrl={location}
                routePattern={route.routePattern}
                canEditRoutePattern={canEditRoutePattern}
                onChange={onEditRoutePattern}
                routePatternBlacklist={existingPaths.filter(
                  (path) => path !== route.routePattern
                )}
                tableRoutes={tableRoutes}
                setEditing={this.setEditing}
              />
            </MousePropagationBlocker>
          </div>
          <div className="KafkaTopicTableRow-topic">
            {isDefunct && (
              <Tooltip
                placement="top"
                title="This topic no longer exists on the kafka broker. This route is now inactive and can be deleted."
                disableFocusListener={true}
              >
                <WarningIcon color="error" />
              </Tooltip>
            )}
            <Typography color={isDefunct ? "error" : "inherit"} noWrap={true}>
              {route.topicName}
            </Typography>
          </div>
          <div className="KafkaTopicTableRow-replyTo">
            {route.routeType === edgeConfigsModels.RouteType.rest ? (
              <Typography color={isDefunct ? "error" : "inherit"} noWrap={true}>
                {route.replyTo}
              </Typography>
            ) : null}
          </div>
          <div className="KafkaTopicTableRow-actions KafkaTopicTable-buttonColumn">
            {!isDefunct && (
              <TooltipIconButton
                title={showLiveData ? "Hide live data" : "Show live SSE data"}
                onClick={this.handleClickLiveData}
                disabled={route.routeType !== edgeConfigsModels.RouteType.sse}
              >
                <VisibilityIcon />
              </TooltipIconButton>
            )}
            {canDeleteRoute ? (
              <Tooltip
                title="Delete this endpoint"
                placement="top"
                disableFocusListener={true}
              >
                <IconButton
                  className="KafkaTopicTable-deleteButton"
                  onClick={onDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <span className="emptyButton" />
            )}
          </div>
        </div>
        <div
          className={`endpointWarning ${
            this.state.isEditing ? "endpointWarning-editing" : ""
          }`}
        >
          {collisions &&
            collisions.length > 0 &&
            `Collides with other path patterns: ${collisions.join(", ")}`}
        </div>
        {!isDefunct && showLiveData && (
          <MousePropagationBlocker>
            <LiveData
              topicName={route.topicName}
              endpoint={endpoint}
              origin={origin}
              route={route}
              edgeState={edgeState}
              language={language}
              openFAQ={openFAQ}
              className="KafkaTopicTableRow-liveData"
              selectDeveloperIntroStepLanguage={
                selectDeveloperIntroStepLanguage
              }
            />
          </MousePropagationBlocker>
        )}
      </div>
    );
  }

  private handleClickLiveData = () => {
    this.setState({
      showLiveData: !this.state.showLiveData,
    });

    if (!this.state.showLiveData) {
      gtm.configOpenLiveStream(
        new URL(this.props.location).href.slice(0, -1),
        this.props.route.routePattern,
        this.props.route.topicName
      );
    }
  };

  /**
   * Invoked when the embedded Endpoint starts or stops editing
   */
  private setEditing = (isEditing: boolean) => {
    this.setState({
      isEditing,
    });
  };
}

export default KafkaTopicTableRow;
