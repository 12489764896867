import { action } from "typesafe-actions";

import {
  EXPAND_DEVELOPER_INTRO_STEP,
  FETCH_USER_PREFS_SUCCESS,
  HIDE_DEVELOPER_INTRO,
  HINT_NEWCONFIG_CONFIG_NAME,
  HINT_NEWCONFIG_DISMISS_ADD_ENDPOINT,
  HINT_NEWCONFIG_DISMISS_ENDPOINT,
  HINT_NEWCONFIG_DISMISS_SAVE,
  HINT_NEWCONFIG_DISMISS_TOPIC,
  SELECT_DEVELOPER_INTRO_STEP_LANGUAGE,
  SHOW_DEVELOPER_INTRO,
} from "./constants";
import { UserPrefsState } from "./reducer";

export const fetchUserPrefsSuccess = (userPrefs: UserPrefsState) =>
  action(FETCH_USER_PREFS_SUCCESS, userPrefs);

export const hideDeveloperIntro = () => action(HIDE_DEVELOPER_INTRO);

export const showDeveloperIntro = (newSignUp: boolean = false) =>
  action(SHOW_DEVELOPER_INTRO, newSignUp);

export const expandDeveloperIntroStep = (step: string | null) =>
  action(EXPAND_DEVELOPER_INTRO_STEP, { step });

export const selectDeveloperIntroStepLanguage = (
  step: string,
  language: number
) => action(SELECT_DEVELOPER_INTRO_STEP_LANGUAGE, { step, language });

export const setNewConfigName = (configName: string) =>
  action(HINT_NEWCONFIG_CONFIG_NAME, { configName });

export const dismissNewConfigAddEndpointHint = () =>
  action(HINT_NEWCONFIG_DISMISS_ADD_ENDPOINT);

export const dismissNewConfigEndpointHint = () =>
  action(HINT_NEWCONFIG_DISMISS_ENDPOINT);

export const dismissNewConfigTopicHint = () =>
  action(HINT_NEWCONFIG_DISMISS_TOPIC);

export const dismissNewConfigSaveHint = () =>
  action(HINT_NEWCONFIG_DISMISS_SAVE);
