import { combineEpics, Epic } from "redux-observable";
import { concat, of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import Types from "Types";
import { isOfType } from "typesafe-actions";

import * as api from "../../services/api";
import * as actions from "./actions";
import { FETCH_DOMAINS, FETCH_USAGE } from "./constants";

export const fetchDomains: Epic<
  Types.RootAction,
  Types.RootAction,
  Types.RootState
> = (action$, store$) =>
  action$.pipe(
    filter(isOfType(FETCH_DOMAINS)),
    mergeMap((action) =>
      api.getDomains(action.payload.orgName).pipe(
        mergeMap((domains) =>
          concat(
            of(actions.fetchDomainsSuccess(domains)),
            domains.map((domain) =>
              actions.fetchUsage(action.payload.orgName, domain.domainName)
            )
          )
        ),
        catchError((err) =>
          of(actions.fetchDomainsFailure(action.payload.orgName, err))
        )
      )
    )
  );

export const fetchUsage: Epic<
  Types.RootAction,
  Types.RootAction,
  Types.RootState
> = (action$, store$) =>
  action$.pipe(
    filter(isOfType(FETCH_USAGE)),
    mergeMap((action) =>
      api.getUsage(action.payload.orgName, action.payload.domainName).pipe(
        map((usage) =>
          actions.fetchUsageSuccess(
            action.payload.orgName,
            action.payload.domainName,
            usage
          )
        ),
        catchError((err) =>
          of(
            actions.fetchUsageFailure(
              action.payload.orgName,
              action.payload.domainName,
              err
            )
          )
        )
      )
    )
  );

export default combineEpics(fetchDomains, fetchUsage);
