import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Amplify, { Auth } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Types from "Types";

import Root from "./containers/Root";
import * as env from "./env";
import "./index.css";
import { unregister } from "./registerServiceWorker";
import * as api from "./services/api";
import { loadState, loadUserPrefs } from "./services/localStorage";
import createStore from "./store/store";

// Passed by Jenkins when we npm run build for release
const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

const savedState = loadState<Types.RootState>();
// Since user prefs aren't saved in the saved state, they need to be loaded from local storage separately.
if (savedState && savedState.users && savedState.users.sessionUserName) {
  const userPrefs = loadUserPrefs(savedState.users.sessionUserName);
  if (userPrefs) {
    savedState.userPrefs = userPrefs;
  }
}
const store = createStore(savedState);

Amplify.configure({
  API: {
    endpoints: [
      {
        custom_header: async () => ({
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        }),
        endpoint: env.apiUrl,
        name: api.API_NAME,
      },
    ],
  },
  Auth: {
    mandatorySignIn: true,
    region: "us-east-1",
    userPoolId: env.userPoolId,
    userPoolWebClientId: env.clientId,
  },
});

api.getVersion().subscribe((version) => {
  // tslint:disable-next-line:no-console
  console.log(
    `tenefit.cloud console ${REACT_APP_VERSION || "development"}, api ${
      version.launcherVersion
    }`
  );
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#105975",
    },
    secondary: {
      main: "#eb9a5e",
    },
  },
  typography: {
    body1: {
      fontSize: "14px",
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <Root />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// TODO: Disable service worker for now, causes changes to only show for users on the N+1 visit due to caching
// registerServiceWorker();
unregister();
