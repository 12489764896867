// A component that draws code on the screen. A copy-to-clipboard icon is present.
// Properties:
// code: The code you want to show
// language: Compatible with syntax highlighter, such as "javascript", "html", "shell", etc
// commandPrefix: String to put in front of the given code. e.g. "$", o it could  be "%" if you want to show Windows commands.
// className: your own class with your additional stylings.
// canSave: Should the save icon button be shown?
// saveFilename: The proposed filenanme that will be used in the save dialog (can be changed by the user)

import { Snackbar } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/GetApp";
import React, { PureComponent } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { githubGist as codeStyle } from "react-syntax-highlighter/dist/styles/hljs";

import { copyToClipboard } from "../clipboard";
import downloadFile from "../downloadFile";
import "./CodeTemplate.css";
import TooltipIconButton from "./core/TooltipIconButton";
import CopyIcon from "./icons/Copy";

export type CodeTemplateProps = {
  code: string;
  language?: string;
  commandPrefix?: string;
  className?: string;
  canSave?: boolean;
  saveFilename?: string;
};

export type CodeTemplateState = {
  copiedSnackbarOpen: boolean;
};

// Override some of the default stylings because it interferes with our own styling.
codeStyle.hljs.padding = "";
codeStyle.hljs.backgroundColor = "";

export default class CodeTemplate extends PureComponent<
  CodeTemplateProps,
  CodeTemplateState
> {
  public static defaultProps = {
    className: "",
    canSave: false,
  };

  public state = { copiedSnackbarOpen: false };

  public render() {
    const { canSave, className, code, commandPrefix, language } = this.props;
    const { copiedSnackbarOpen } = this.state;
    return (
      <div className="CodeTemplate">
        <div className="CodeTemplate-buttons">
          {canSave && (
            <TooltipIconButton
              title="Save to file"
              onClick={this.handleSaveToFile}
            >
              <SaveIcon />
            </TooltipIconButton>
          )}
          <TooltipIconButton
            title="Copy sample to clipboard"
            onClick={this.handleCopy}
          >
            <CopyIcon />
          </TooltipIconButton>
        </div>
        <SyntaxHighlighter
          language={language}
          style={codeStyle}
          className={`CodeTemplate--code ${className}`}
          CodeTag="div"
        >
          {`${commandPrefix || ""}${code}`}
        </SyntaxHighlighter>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={copiedSnackbarOpen}
          message={<span>Copied to clipboard</span>}
        />
      </div>
    );
  }

  private handleCopy = () => {
    copyToClipboard(this.props.code);
    this.setState({ copiedSnackbarOpen: true });
    setTimeout(() => {
      this.setState({ copiedSnackbarOpen: false });
    }, 2000);
  };

  private handleSaveToFile = () => {
    downloadFile(this.props.saveFilename || "", this.props.code);
  };
}
