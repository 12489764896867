import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Types from "Types";

import KafkaTopicTable, {
  KafkaTopicTableProps,
} from "../components/KafkaTopicTable";
import { userPrefsActions } from "../features/userPrefs";

const KafkaTopicTableContainer = (props: KafkaTopicTableProps) => {
  return <KafkaTopicTable {...props} />;
};

const mapStateToProps = (state: Types.RootState) => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  dismissNewConfigAddEndpointHint: () =>
    dispatch(userPrefsActions.dismissNewConfigAddEndpointHint()),
  dismissNewConfigEndpointHint: () =>
    dispatch(userPrefsActions.dismissNewConfigEndpointHint()),
  dismissNewConfigTopicHint: () =>
    dispatch(userPrefsActions.dismissNewConfigTopicHint()),
  dismissNewConfigSaveHint: () =>
    dispatch(userPrefsActions.dismissNewConfigSaveHint()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KafkaTopicTableContainer);
