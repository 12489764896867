import { CircularProgress, TextField, Typography } from "@material-ui/core";
import CodeIcon from "@material-ui/icons/Code";
import React, { PureComponent } from "react";

import { edgeConfigsModels } from "../features/edgeConfigs";
import * as gtm from "../services/googleTagManager";
import TooltipIconButton from "./core/TooltipIconButton";
import StreamTemplates, { languages } from "./developerIntro/StreamTemplates";
import "./LiveData.css";

export type LiveDataProps = {
  topicName: string;
  endpoint: string;
  origin: string;
  route: edgeConfigsModels.Route & { topicName: string };
  edgeState: edgeConfigsModels.EdgeState;
  className?: string;
  language: number;
  openFAQ: () => void;
  selectDeveloperIntroStepLanguage: (step: string, language: number) => void;
};

export type LiveDataState = {
  key?: string;
  headers: { [header: string]: string };
  message: object | null;
  showCodeTemplates: boolean;
  subscribing: boolean;
  subscribed: boolean;
  error: boolean;
};

export default class LiveData extends PureComponent<
  LiveDataProps,
  LiveDataState
> {
  public static defaultProps = {
    className: "",
  };

  public state: LiveDataState = {
    headers: {},
    message: null,
    showCodeTemplates: false,
    subscribing: false,
    subscribed: false,
    error: false,
  };

  private stream: EventSource | null = null;
  private startEventSourceTimeout: NodeJS.Timer | null = null;

  public componentDidMount() {
    this.componentDidUpdate({} as any);
  }

  public componentDidUpdate(prevProps: LiveDataProps) {
    const { edgeState } = this.props;
    if (edgeState === "RUNNING" && prevProps.edgeState !== edgeState) {
      this.startEventSource();
    } else if (edgeState !== "RUNNING" && prevProps.edgeState !== edgeState) {
      this.stopEventSource();
    }
  }

  public componentWillUnmount() {
    this.stopEventSource();
  }

  public render() {
    const {
      className,
      topicName,
      endpoint,
      origin,
      route,
      edgeState,
      language,
      openFAQ,
      selectDeveloperIntroStepLanguage,
      ...props
    } = this.props;
    const {
      showCodeTemplates,
      subscribing,
      subscribed,
      error,
      message,
    } = this.state;
    return (
      <div {...props} className={`LiveData ${className}`}>
        <div className="LiveData-buttons">
          <TooltipIconButton
            title={
              showCodeTemplates ? "Hide code template" : "Show code template"
            }
            onClick={this.handleClickTopicTemplate}
          >
            <CodeIcon />
          </TooltipIconButton>
        </div>
        <div className="LiveData-content">
          <StreamTemplates
            isIntro={false}
            origin={origin}
            route={route}
            language={language}
            selectDeveloperIntroStepLanguage={
              this.handleClickCodeTemplateLanguage
            }
            className={`LiveData-code ${
              showCodeTemplates ? "LiveData-code--visible" : ""
            }`}
          />
          <div className="LiveData-messages">
            {edgeState !== "RUNNING" ? (
              <div className="LiveData-subscribing">
                <CircularProgress size={12} />
                <Typography align="left">
                  Updating your configuration. We'll re-subscribe to this
                  endpoint once it's available.
                </Typography>
              </div>
            ) : error ? (
              <Typography align="left" color="error">
                Failed to subscribe. Trying again...
              </Typography>
            ) : subscribing ? (
              <div className="LiveData-subscribing">
                <CircularProgress size={12} />
                <Typography align="left">Subscribing...</Typography>
              </div>
            ) : message ? (
              <Typography align="left">{message}</Typography>
            ) : subscribed ? (
              <Typography align="left">
                Subscribed. Waiting for new messages...
              </Typography>
            ) : (
              <Typography align="left">
                Please fill out the key and header values below. We'll subscribe
                automatically.
              </Typography>
            )}
          </div>
          <div className="LiveData-parameters">
            {this.hasKey && (
              <TextField
                label="$key"
                value={this.state.key}
                onChange={this.handleKeyChange}
              />
            )}
            {this.headers.map((header) => (
              <TextField
                key={header}
                name={header}
                label={`:${header}`}
                value={this.state.headers[header]}
                onChange={this.handleHeaderChange}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  private handleKeyChange: React.FormEventHandler = (evt) => {
    const target = evt.target as HTMLInputElement;
    this.setState({ key: target.value });
    if (this.startEventSourceTimeout) {
      clearTimeout(this.startEventSourceTimeout);
    }
    this.startEventSourceTimeout = setTimeout(this.startEventSource, 1000);
  };

  private handleHeaderChange: React.FormEventHandler = (evt) => {
    const target = evt.target as HTMLInputElement;
    this.setState({
      headers: {
        ...this.state.headers,
        [target.name]: target.value,
      },
    });
    if (this.startEventSourceTimeout) {
      clearTimeout(this.startEventSourceTimeout);
    }
    this.startEventSourceTimeout = setTimeout(this.startEventSource, 1000);
  };

  private get hasKey() {
    return this.props.endpoint.indexOf("$key") > -1;
  }

  private get headers() {
    return this.props.endpoint
      .split("/")
      .filter((part) => part[0] === ":")
      .map((part) => part.slice(1));
  }

  private get allHeadersSet() {
    return this.headers.every((header) => !!this.state.headers[header]);
  }

  private startEventSource = () => {
    this.stopEventSource();
    if (this.state.key === "" || !this.allHeadersSet) {
      return;
    }
    this.setState({ subscribing: true, subscribed: false, message: null });
    this.stream = new EventSource(this.buildEndpoint());
    this.stream.addEventListener("open", this.handleOpen, false);
    this.stream.addEventListener("error", this.handleError, false);
    this.stream.addEventListener("message", this.handleMessage, false);
  };

  private buildEndpoint = () => {
    const { key, headers } = this.state;
    let endpoint = this.props.endpoint;
    if (key !== undefined) {
      endpoint = endpoint.replace("$key", key);
    }
    endpoint = Object.keys(headers).reduce(
      (endpoint, header) => endpoint.replace(`:${header}`, headers[header]),
      endpoint
    );
    return endpoint;
  };

  private stopEventSource = () => {
    this.setState({ subscribed: false });
    if (this.stream) {
      this.stream.removeEventListener("open", this.handleOpen, false);
      this.stream.removeEventListener("error", this.handleError, false);
      this.stream.removeEventListener("message", this.handleMessage, false);
      this.stream.close();
    }
  };

  private handleOpen = () => {
    this.setState({ subscribing: false, subscribed: true, error: false });
  };

  private handleError = () => {
    this.setState({ subscribed: false, error: true });
    // on certain errors the EventSource gives up, others it tries again
    if (this.stream!.readyState === this.stream!.CLOSED) {
      this.stopEventSource();
      this.startEventSource();
    }
  };

  private handleMessage: EventListener = (event) => {
    this.setState({ message: (event as any).data });
  };

  private handleClickTopicTemplate = () => {
    // Only send a Google Tag Manager event when the code template is opened, not closed.
    if (!this.state.showCodeTemplates) {
      gtm.configOpenCodeTemplate(this.props.endpoint);
    }
    this.setState({ showCodeTemplates: !this.state.showCodeTemplates });
  };

  private handleClickCodeTemplateLanguage = (
    step: string,
    language: number
  ) => {
    gtm.configSelectCodeTemplateLanguage(languages[language]);
    this.props.selectDeveloperIntroStepLanguage(step, language);
  };
}
