export const TENEFIT_SANDBOX_CONFIG_NAME = "sandbox";

/**
 * During debugging, especially with Redux, it can be difficult if timers and intervals are constanstly firing.
 * Setting this to true during debugging will increase those times, causing few interruptions.
 * It should be set to false for production deployment.
 */
export const DEBUG_INTERVALS = false;

if (DEBUG_INTERVALS) {
  // tslint:disable-next-line: no-console
  console.log(
    `WARNING: DEBUG_INTERVALS is on so updates will be far less frequent`
  );
}

export const MSK_HOSTNAME = "msk.demo.tenefit.cloud";

export const PATH_FOR_AUTO_SSE_MAPPING = "sse";
