import { action } from "typesafe-actions";

import {
  CLEAR_MESSAGES,
  CLOSE_CREATE_NEW_APP_DIALOG,
  COMPLETE_TASK,
  CREATE_INTRO_SANDBOX_FINISH,
  CREATE_INTRO_SANDBOX_START,
  FAIL_TASK,
  OPEN_CREATE_NEW_APP_DIALOG,
  RESET_CREATE_NEW_APP_DIALOG,
  SET_CA_CERT,
  SET_CLIENT_CERT,
  SET_CLIENT_KEY,
  START_TASK,
} from "./constants";

export const openCreateNewAppDialog = () => action(OPEN_CREATE_NEW_APP_DIALOG);

export const closeCreateNewAppDialog = () =>
  action(CLOSE_CREATE_NEW_APP_DIALOG);

export const resetCreateNewAppDialog = () =>
  action(RESET_CREATE_NEW_APP_DIALOG);

export const clearMessages = () => action(CLEAR_MESSAGES);

export const startTask = (message: string) => action(START_TASK, message);

export const completeTask = (message: string) => action(COMPLETE_TASK, message);

export const failTask = (message: string) => action(FAIL_TASK, message);

export const createIntroSandboxStart = () => action(CREATE_INTRO_SANDBOX_START);

export const createIntroSandboxFinish = () =>
  action(CREATE_INTRO_SANDBOX_FINISH);

export const setCaCert = (caCert: File | null) => action(SET_CA_CERT, caCert);

export const setClientCert = (clientCert: File | null) =>
  action(SET_CLIENT_CERT, clientCert);

export const setClientKey = (clientKey: File | null) =>
  action(SET_CLIENT_KEY, clientKey);
