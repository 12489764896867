/**
 * A table of values indexed by partition keys and sort keys, just like DynamoDB.
 * Partition keys are unique, and sort keys are unique within a single partition.
 *
 * The table's entries and values are completely immutable so that the table is
 * safe to use in a redux reducer.
 */
export type PartitionedTable<V> = {
  readonly [partitionKey: string]: {
    readonly [sortKey: string]: Readonly<V>;
  };
};

/**
 * Add a value to the table at the given partition key and sort key. Replaces
 * the entry that was already there, if applicable.
 * @param table
 * @param partitionKey
 * @param sortKey
 * @param value
 */
export function create<V>(
  table: PartitionedTable<V>,
  partitionKey: string,
  sortKey: string,
  value: V
): PartitionedTable<V> {
  if (!(partitionKey in table)) {
    table = { ...table, [partitionKey]: {} };
  }
  const partition = table[partitionKey];
  return {
    ...table,
    [partitionKey]: {
      ...partition,
      [sortKey]: value,
    },
  };
}

/**
 * Returns an array of all records in the specified partition.
 * @param table
 * @param partitionKey
 * @returns An empty or non-empty array of records.
 */
export function read<V>(table: PartitionedTable<V>, partitionKey: string): V[];

/**
 * Returns the record at the specified partition and sort key, or undefined if
 * such a record does not exist.
 * @param table
 * @param partitionKey
 * @param sortKey
 * @returns The record, or undefined.
 */
export function read<V>(
  table: PartitionedTable<V>,
  partitionKey: string,
  sortKey: string
): V | undefined;

/* implementation */
export function read<V>(
  table: PartitionedTable<V>,
  partitionKey: string,
  sortKey?: string
) {
  const partition = partitionKey in table ? table[partitionKey] : undefined;
  if (sortKey === undefined) {
    return partition ? Object.keys(partition).map((key) => partition[key]) : [];
  } else {
    return partition ? partition[sortKey] : undefined;
  }
}

/**
 * Update the record at the given partition and sort key with new values. The
 * record will contain the result of merging the `diff`'s properties with the
 * record's current value. If there is no record at the given partition and
 * sort key, the table is returned without modifications.
 * @param table
 * @param partitionKey
 * @param sortKey
 * @returns New table.
 */
export function update<V extends object>(
  table: PartitionedTable<V>,
  partitionKey: string,
  sortKey: string,
  diff: Partial<V>
) {
  const partition = partitionKey in table ? table[partitionKey] : undefined;
  if (!partition) {
    return table;
  }
  const currentValue = sortKey in partition ? partition[sortKey] : undefined;
  if (currentValue) {
    return {
      ...table,
      [partitionKey]: {
        ...partition,
        [sortKey]: Object.assign({}, currentValue, diff),
      },
    };
  } else {
    return table;
  }
}

/**
 * Remove the record at the given partition key and sort key. Returns the table
 * unmodified if the partition or sort key do not exist.
 * @param table
 * @param partitionKey
 * @param sortKey
 */
export function del<V>(
  table: PartitionedTable<V>,
  partitionKey: string,
  sortKey: string
) {
  const partition = partitionKey in table ? table[partitionKey] : undefined;
  if (partition) {
    const { [sortKey]: deleted, ...rest } = partition;
    return { ...table, [partitionKey]: rest };
  } else {
    return table;
  }
}
