import { API, Auth } from "aws-amplify";
import { from, Observable } from "rxjs";

import { apiUrl } from "../env";
import { EdgeConfigConfig } from "../features/edgeConfigs/models";
import { UserPrefsState } from "../features/userPrefs";
import { loadUserPrefs, saveUserPrefs } from "./localStorage";
import * as transformEdgeConfigs from "./transform/edgeConfigs";

export const API_NAME = "PortalAPI";

export type User = {
  username: string;
  email: string;
  organizations: string[] | null;
};

export const getSessionUser = async () => {
  const user: User = await API.post(API_NAME, "/user", {});
  return user;
};

export type Organization = {
  name: string;
};

export const getOrganization = (name: string): Observable<Organization> => {
  return from(API.get(API_NAME, `/organizations/${name}`, {}));
};

export const createOrganization = (name: string) => {
  return from(
    API.post(API_NAME, `/organizations`, {
      body: { name },
    }).then((_) => ({ name }))
  );
};

export type Domain = {
  organization: string;
  domainName: string;
};

export const getDomains = (orgName: string): Observable<Domain[]> => {
  return from(API.get(API_NAME, `/organizations/${orgName}/domains`, {}));
};

export type Key = {
  organization: string;
  name: string;
  publicKey: string;
};

export const getKeys = (orgName: string): Observable<Key[]> => {
  return from(API.get(API_NAME, `/${orgName}/keys`, {}));
};

export const createKey = (
  orgName: string,
  keyName: string
): Observable<Key> => {
  return from(
    API.post(API_NAME, `/${orgName}/keys`, {
      body: {
        name: keyName,
        organization: orgName,
      },
    })
  );
};

export type Usage = {
  [date: string]: number;
};

export const getUsage = (
  orgName: string,
  domainName: string
): Observable<Usage> => {
  return from(
    API.get(
      API_NAME,
      `/organizations/${orgName}/domains/${encodeURIComponent(
        domainName
      )}/usage`,
      {}
    )
  );
};

export type EdgeConfigResponse = {
  organization: string;
  name: string;
  version: number;
  config: string;
  caCertFilename: string | null;
  clientCertFilename: string | null;
  clientKeyFilename: string | null;
  edgeState: "INIT" | "RESTARTING" | "RUNNING" | "ERROR";
  edgeConfigState: "NEW" | "DEPLOYED";
};

export const getEdgeConfigs = async (orgName: string) => {
  const edgeConfigs: EdgeConfigResponse[] = await API.get(
    API_NAME,
    `/organizations/${orgName}/edge-configs`,
    {}
  );
  return transformEdgeConfigs.serverToClient(edgeConfigs);
};

type KafkaTopicsResponse =
  | { connected: false; topics: null }
  | { connected: true; topics: string[] };

export const getKafkaTopics = async (orgName: string, configName: string) => {
  const kafkaTopicsResponse: KafkaTopicsResponse = await API.get(
    API_NAME,
    `/organizations/${orgName}/edge-configs/${configName}/kafkaTopics`,
    {}
  );
  return kafkaTopicsResponse;
};

export const postEdgeConfig = async (
  orgName: string,
  configName: string,
  version: number,
  config: EdgeConfigConfig,
  caCertFilename: string | null,
  clientCertFilename: string | null,
  clientKeyFilename: string | null
) => {
  await API.post(API_NAME, `/organizations/${orgName}/edge-configs`, {
    body: {
      name: configName,
      version,
      config: transformEdgeConfigs.clientToServer(config),
      caCertFilename,
      clientCertFilename,
      clientKeyFilename,
    },
  });
};

export const deleteEdgeConfig = (
  orgName: string,
  configName: string
): Observable<void> => {
  return from(
    API.del(
      API_NAME,
      `/organizations/${orgName}/edge-configs/${configName}`,
      {}
    )
  );
};

export type Version = {
  launcherVersion: string;
};

export const getVersion = (): Observable<Version> =>
  from(API.get(API_NAME, "/version", {}));

export const fetchUserPrefs = async (
  username: string
): Promise<UserPrefsState> => {
  // TODO Once API has the ability to fetch preferences in the backend db, then replace
  // the following lines with that API call.
  const userPrefs = loadUserPrefs(username);
  if (userPrefs) {
    return userPrefs;
  } else {
    // Sensible defaults the server would return
    return {
      isShowingDeveloperIntro: false,
      expandedStep: "step3",
      step1Language: 0,
      step2Language: 0,
      hints: {
        newConfig: {
          dismissedAddEndpoint: false,
          dismissedEndpoint: false,
          dismissedTopic: false,
          dismissedSave: false,
        },
      },
    };
  }
};

export const storeUserPrefs = async (
  username: string,
  userPrefs: UserPrefsState
): Promise<UserPrefsState> => {
  // TODO Once API has the ability to store preferences in the backend db, then replace
  // the following line with that API call.
  saveUserPrefs(username, userPrefs);
  return userPrefs;
};

export const uploadCerts = async (
  orgName: string,
  configName: string,
  caCertFile: File | null,
  clientCertFile: File | null,
  clientKeyFile: File | null
): Promise<any> => {
  const formdata = new FormData();
  if (caCertFile) {
    formdata.append("ca-cert", caCertFile);
  }
  if (clientCertFile && clientKeyFile) {
    formdata.append("client-cert", clientCertFile);
    formdata.append("client-key", clientKeyFile);
  }

  const authToken = (await Auth.currentSession()).getIdToken().getJwtToken();

  return fetch(`${apiUrl}/certs/${orgName}/${configName}/upload`, {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: authToken,
    },
    body: formdata,
  }).then((response) => {
    if (response.status !== 201) {
      return response.text().then((data) => {
        throw new Error(data);
      });
    } else {
      return Promise.resolve();
    }
  });
};
