import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import * as actions from "./actions";
import {
  CLEAR_MESSAGES,
  CLOSE_CREATE_NEW_APP_DIALOG,
  COMPLETE_TASK,
  CREATE_INTRO_SANDBOX_FINISH,
  CREATE_INTRO_SANDBOX_START,
  FAIL_TASK,
  OPEN_CREATE_NEW_APP_DIALOG,
  RESET_CREATE_NEW_APP_DIALOG,
  SET_CA_CERT,
  SET_CLIENT_CERT,
  SET_CLIENT_KEY,
  START_TASK,
} from "./constants";
import { Message, MessageType } from "./models";

export type AddNewAppAction = ActionType<typeof actions>;

export type AddNewAppState = {
  isDialogOpen: boolean;
  messages: Message[];
  isCreatingIntroSandbox: boolean;
  caCert: File | null;
  clientCert: File | null;
  clientKey: File | null;
};

export default combineReducers<AddNewAppState, AddNewAppAction>({
  isDialogOpen: (state = false, action) => {
    switch (action.type) {
      case OPEN_CREATE_NEW_APP_DIALOG:
        return true;
      case CLOSE_CREATE_NEW_APP_DIALOG:
        return false;
      default:
        return state;
    }
  },
  messages: (state = [], action) => {
    switch (action.type) {
      case CLEAR_MESSAGES:
      case RESET_CREATE_NEW_APP_DIALOG:
        return [];
      case START_TASK:
        return [
          ...state,
          { type: MessageType.Progress, message: action.payload },
        ];
      case COMPLETE_TASK:
        return [
          ...state.slice(0, state.length - 1),
          {
            type: MessageType.Success,
            message: action.payload,
          },
        ];
      case FAIL_TASK:
        return [
          ...state.slice(0, state.length - 1),
          { type: MessageType.Failure, message: action.payload },
          {
            type: MessageType.Failure,
            message: "Fix the settings above and click Test Connection again.",
          },
        ];
      default:
        return state;
    }
  },
  isCreatingIntroSandbox: (state = false, action) => {
    switch (action.type) {
      case CREATE_INTRO_SANDBOX_START:
        return true;
      case CREATE_INTRO_SANDBOX_FINISH:
        return false;
      default:
        return state;
    }
  },
  caCert: (state = null, action) => {
    switch (action.type) {
      case SET_CA_CERT:
        if (action.payload) {
          return action.payload;
        }
        return state;
      case RESET_CREATE_NEW_APP_DIALOG:
        return null;
      default:
        return state;
    }
  },
  clientCert: (state = null, action) => {
    switch (action.type) {
      case SET_CLIENT_CERT:
        if (action.payload) {
          return action.payload;
        }
        return state;
      case RESET_CREATE_NEW_APP_DIALOG:
        return null;
      default:
        return state;
    }
  },
  clientKey: (state = null, action) => {
    switch (action.type) {
      case SET_CLIENT_KEY:
        if (action.payload) {
          return action.payload;
        }
        return state;
      case RESET_CREATE_NEW_APP_DIALOG:
        return null;
      default:
        return state;
    }
  },
});
