import { action } from "typesafe-actions";

import { Organization } from "../../services/api";
import {
  CREATE_ORGANIZATION,
  CREATE_ORGANIZATION_FAILURE,
  CREATE_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_FAILURE,
  FETCH_ORGANIZATION_SUCCESS,
} from "./constants";

export const fetchOrganization = (orgName: string) =>
  action(FETCH_ORGANIZATION, { orgName });
export const fetchOrganizationSuccess = (organization: Organization) =>
  action(FETCH_ORGANIZATION_SUCCESS, organization);
export const fetchOrganizationFailure = (orgName: string, error: any) =>
  action(FETCH_ORGANIZATION_FAILURE, { orgName, error });

export const createOrganization = (orgName: string) =>
  action(CREATE_ORGANIZATION, { orgName });
export const createOrganizationSuccess = (organization: Organization) =>
  action(CREATE_ORGANIZATION_SUCCESS, organization);
export const createOrganizationFailure = (orgName: string, error: any) =>
  action(CREATE_ORGANIZATION_FAILURE, { orgName, error });
