import { JS } from "@aws-amplify/core";
import { Auth } from "aws-amplify";
import { push } from "connected-react-router";
import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import Types from "Types";

import * as gtm from "../../services/googleTagManager";
import { usersThunks } from "../users";
import * as actions from "./actions";
import { AuthState } from "./models";

export const changeState = (
  state: AuthState,
  data: any = null
): ThunkAction<Promise<void>, void, void, Types.RootAction> => {
  return async (dispatch) => {
    if (state === "signedIn") {
      try {
        await dispatch(usersThunks.fetchSessionUser());
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.error(error);
        return dispatch(signOut());
      }
    }
    dispatch(actions.changeState(state, data));
  };
};

export const currentAuthenticatedUser = (): ThunkAction<
  Promise<void>,
  void,
  void,
  Types.RootAction
> => {
  return async (dispatch) => {
    try {
      await Auth.currentAuthenticatedUser();
      dispatch(changeState(AuthState.signedIn));
    } catch (err) {}
  };
};

export const signIn = (
  username: string,
  password: string
): ThunkAction<Promise<void>, void, void, Types.RootAction> => {
  return async (dispatch) => {
    try {
      const user = await Auth.signIn(username, password);
      if (
        user.challengeName === "SMS_MFA" ||
        user.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        throw new Error("MFA not implemented");
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        await dispatch(changeState(AuthState.requireNewPassword, user));
      } else if (user.challengeName === "MFA_SETUP") {
        throw new Error("TOTP not implemented");
      } else {
        await dispatch(checkContact(user));
      }
      gtm.authSignIn(user.username);
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        await dispatch(changeState(AuthState.confirmSignUp));
      } else {
        await dispatch(actions.error(err));
      }
    }
  };
};

const checkContact = (
  user: object
): ThunkAction<Promise<void>, void, void, Types.RootAction> => {
  return async (dispatch) => {
    const data = await Auth.verifiedContact(user);
    if (!JS.isEmpty(data.verified)) {
      dispatch(changeState(AuthState.signedIn, user));
    } else {
      user = Object.assign(user, data);
      dispatch(changeState(AuthState.verifyContact, user));
    }
  };
};

export const signUp = (
  username: string,
  email: string,
  password: string
): ThunkAction<Promise<void>, void, void, Types.RootAction> => {
  return async (dispatch) => {
    try {
      await Auth.signUp({
        attributes: {
          email,
        },
        password,
        username,
      });
      dispatch(changeState(AuthState.confirmSignUp, { username, password }));
      gtm.authSignUp(username);
    } catch (err) {
      dispatch(actions.error(err));
    }
  };
};

export const confirmSignUp = (
  username: string,
  code: string,
  password: string
): ThunkAction<Promise<void>, void, void, Types.RootAction> => {
  return async (dispatch) => {
    try {
      await Auth.confirmSignUp(username, code);
      gtm.authSignUpConfirmed(username);
      if (password) {
        await dispatch(signIn(username, password));
      } else {
        dispatch(changeState(AuthState.signIn));
      }
    } catch (err) {
      dispatch(actions.error(err));
    }
  };
};

export const resendSignUp = (
  username: string
): ThunkAction<Promise<void>, void, void, Types.RootAction> => {
  return async (dispatch) => {
    try {
      await Auth.resendSignUp(username);
    } catch (err) {
      dispatch(actions.error(err));
    }
  };
};

export const forgotPassword = (
  username: string
): ThunkAction<Promise<void>, void, void, Types.RootAction> => {
  return async (dispatch) => {
    try {
      const data = await Auth.forgotPassword(username);
      dispatch(actions.setDelivery(data.CodeDeliveryDetails));
    } catch (err) {
      dispatch(actions.error(err));
    }
  };
};

export const forgotPasswordSubmit = (
  username: string,
  code: string,
  password: string
): ThunkAction<Promise<void>, void, void, Types.RootAction> => {
  return async (dispatch) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      dispatch(actions.changeState(AuthState.signIn));
      dispatch(actions.setDelivery(null));
    } catch (err) {
      dispatch(actions.error(err));
    }
  };
};

export const completeNewPassword = (
  user: string,
  password: string,
  requiredAttributes: any
): ThunkAction<Promise<void>, void, void, any> => {
  return async (dispatch) => {
    try {
      const data = await Auth.completeNewPassword(
        user,
        password,
        requiredAttributes
      );
      if (data.challengeName === "SMS_MFA") {
        throw new Error("MFA not implemented");
      } else if (data.challengeName === "MFA_SETUP") {
        throw new Error("TOTP not implemented");
      } else {
        dispatch(verifiedContact(data));
      }
    } catch (err) {
      dispatch(actions.error(err));
    }
  };
};

export const verifiedContact = (user: any) => async (dispatch: Dispatch) => {
  const data = await Auth.verifiedContact(user);
  if (!JS.isEmpty(data.verified)) {
    await dispatch(actions.changeState(AuthState.signedIn, user));
  } else {
    user = { ...user, ...data };
    dispatch(actions.changeState(AuthState.verifyContact, user));
  }
};

export const verifyCurrentUserAttribute = (attribute: string) => async (
  dispatch: Dispatch
) => {
  try {
    await Auth.verifyCurrentUserAttribute(attribute);
    dispatch(actions.setVerifyAttr(attribute));
  } catch (err) {
    dispatch(actions.error(err));
  }
};

export const verifyCurrentUserAttributeSubmit = (
  attribute: string,
  code: string
): ThunkAction<Promise<void>, Types.RootState, void, Types.RootAction> => {
  return async (dispatch, getState) => {
    try {
      await Auth.verifyCurrentUserAttributeSubmit(attribute, code);
      await dispatch(changeState(AuthState.signedIn, getState().auth.data));
      dispatch(actions.setVerifyAttr(null));
    } catch (err) {
      dispatch(actions.error(err));
    }
  };
};

export const signOut = (): ThunkAction<
  Promise<void>,
  void,
  void,
  Types.RootAction
> => {
  return async (dispatch) => {
    try {
      await Auth.signOut();
      // tslint:disable-next-line:no-empty
    } catch (err) {}
    dispatch(actions.signedOut());
    dispatch(push("/"));
  };
};
