import { FormControl, Typography } from "@material-ui/core";
import React from "react";

import "./ReachabilitySelect.css";

export type ReachabilitySelectProps = {
  name: string;
  value: any;
  disabled?: boolean;
  onChange?: (ev: any) => void;
  children: JSX.Element[];
};

const defaultHandler = (ev: any) => {};

const ReachabilitySelect = ({
  name,
  value,
  disabled,
  onChange = defaultHandler,
  children,
}: ReachabilitySelectProps) => {
  const childrenClone = children.map((child, i) => {
    return React.cloneElement(child as React.ReactElement<any>, {
      key: `ReachabilitySelect-item-${i}`,
      disabled: disabled || child.props.disabled,
      selected: value === child.props.value,
      onSelect: () =>
        onChange({
          target: {
            name,
            value: child.props.value,
          },
        }),
    });
  });
  return (
    <FormControl>
      <Typography variant="caption">Select one of the following:</Typography>
      <div className="ReachabilitySelect-options">{childrenClone}</div>
    </FormControl>
  );
};

export default ReachabilitySelect;
