import React from "react";

import { CircularProgress, Typography } from "@material-ui/core";
import "./Loading.css";

export type LoadingProps = {
  message?: string;
};

export default ({ message }: LoadingProps) => (
  <div className="Loading">
    <CircularProgress />
    {message && (
      <Typography align="center" className="message">
        {message}
      </Typography>
    )}
  </div>
);
