/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React from "react";
import PropTypes from "prop-types";
import { Typography, Button, TextField } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import "./SignIn.css";
import AuthPiece from "./AuthPiece";
import { FormControl, Link } from ".";
import ProgressButton from "../core/ProgressButton";

const styles = (theme) => ({
  prodMessage: { marginTop: 20, marginBottom: 20 },
  heading: {
    fontWeight: 300,
    fontSize: 18,
  },
});

class SignIn extends AuthPiece {
  static propTypes = {
    changeState: PropTypes.func.isRequired,
    signIn: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };

  submit = async () => {
    const { username, password } = this.inputs;
    await this.props.signIn(username, password);
  };

  render() {
    const { changeState } = this.props;
    const { isBusy } = this.state;
    return (
      <div>
        <FormControl>
          <TextField
            name="username"
            label="Username or email"
            autoFocus
            fullWidth
            placeholder="Email"
            onChange={this.handleInputChange}
            className="SignIn-textField"
          />
        </FormControl>
        <FormControl>
          <TextField
            name="password"
            label="Password"
            fullWidth
            type="password"
            placeholder="Password"
            onChange={this.handleInputChange}
            className="SignIn-textField"
          />
          <Link
            align="right"
            onClick={() => changeState("forgotPassword")}
            className="SignIn-forgotPassword"
          >
            Forgot Password?
          </Link>
          <ProgressButton
            variant="contained"
            color="primary"
            fullWidth
            isPending={isBusy}
            onClick={this.handleSubmit}
            className="SignIn-signInButton"
          >
            Sign In
          </ProgressButton>
        </FormControl>
        {/* Disable  sign up page */}
        {/* <FormControl>
          <Typography align="center" className="SignIn-dontHaveAccount">
            Don't have an account?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => changeState("signUp")}
            className="SignIn-signUpButton"
          >
            Sign Up for Free
          </Button>
        </FormControl> */}
      </div>
    );
  }
}

export default withStyles(styles)(SignIn);
