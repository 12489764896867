import * as partitionedTable from "../../utils/partitionedTable";
import { DomainsState } from "./reducer";

export const getDomains = (state: DomainsState, orgName: string) => {
  return partitionedTable.read(state.entries, orgName);
};

export const getDomain = (
  state: DomainsState,
  orgName: string,
  domainName: string
) => {
  return partitionedTable.read(state.entries, orgName, domainName);
};
