import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import { detokenizePath, generateUid, isMskAddress } from "../../utils/utils";
import * as actions from "./actions";
import {
  ADD_EDGE_CONFIG,
  ADD_MAPPING,
  ADD_ROUTE,
  CREATE_EDGE_CONFIG,
  FETCH_EDGE_CONFIG_SUCCESS,
  FINALIZE_TEMPORARY_EDGE_CONFIG,
  MOVE_ROUTE,
  REMOVE_EDGE_CONFIG,
  REMOVE_ROUTE,
  TEST_KAFKA_CONNECTION,
  TEST_KAFKA_CONNECTION_DONE,
  UPDATE_CONFIG,
  UPDATE_ROUTE_PATH,
} from "./constants";
import { EdgeConfig, Mapping, Route } from "./models";

export type EdgeConfigsAction = ActionType<typeof actions>;

export type EdgeConfigsState = {
  byName: {
    [name: string]: EdgeConfig;
  };
  didFetchEdgeConfig: boolean;
  temporaryEdgeConfigName: string | null;
};

export default combineReducers<EdgeConfigsState, EdgeConfigsAction>({
  byName: (state = {}, action) => {
    let edgeConfig: EdgeConfig;
    switch (action.type) {
      case ADD_EDGE_CONFIG:
        const existingEdgeConfig =
          action.payload.configName in state
            ? state[action.payload.configName]
            : undefined;
        const shouldUpdateConfig =
          !existingEdgeConfig ||
          action.payload.version > existingEdgeConfig.version;
        return {
          ...state,
          [action.payload.configName]: {
            name: existingEdgeConfig
              ? existingEdgeConfig.name
              : action.payload.configName,
            organization: existingEdgeConfig
              ? existingEdgeConfig.organization
              : action.payload.orgName,
            version: shouldUpdateConfig
              ? action.payload.version
              : existingEdgeConfig!.version,
            caCertFilename: shouldUpdateConfig
              ? action.payload.caCertFilename
              : existingEdgeConfig!.caCertFilename,
            clientCertFilename: shouldUpdateConfig
              ? action.payload.clientCertFilename
              : existingEdgeConfig!.clientCertFilename,
            clientKeyFilename: shouldUpdateConfig
              ? action.payload.clientKeyFilename
              : existingEdgeConfig!.clientKeyFilename,
            config: shouldUpdateConfig
              ? {
                  ...action.payload.config,
                  isMsk: isMskAddress(action.payload.config.kafka),
                  mapping: mappingsReducer(
                    action.payload.config.mapping,
                    action
                  ),
                }
              : existingEdgeConfig!.config,
            edgeState: action.payload.edgeState,
            edgeConfigState: action.payload.edgeConfigState,
            lastModified: action.payload.time,
            topics: existingEdgeConfig ? existingEdgeConfig.topics : [],
            isConnected: existingEdgeConfig
              ? existingEdgeConfig.isConnected
              : false,
            isTestingConnection: existingEdgeConfig
              ? existingEdgeConfig.isTestingConnection
              : false,
          },
        };
      case CREATE_EDGE_CONFIG:
        return {
          ...state,
          [action.payload.configName]: {
            name: action.payload.configName,
            organization: action.payload.orgName,
            version: 0,
            caCertFilename: action.payload.caCertFilename,
            clientCertFilename: action.payload.clientCertFilename,
            clientKeyFilename: action.payload.clientKeyFilename,
            config: {
              kafka: action.payload.address,
              isMsk: isMskAddress(action.payload.address),
              store:
                action.payload.caCertFilename ||
                (action.payload.clientCertFilename &&
                  action.payload.clientKeyFilename)
                  ? `${action.payload.orgName}/${action.payload.configName}`
                  : null,
              subnet: "0.0.0.0/0",
              mapping: [],
            },
            edgeState: "INIT",
            edgeConfigState: "NEW",
            lastModified: action.payload.time,
            topics: [],
            isConnected: false,
            isTestingConnection: false,
          },
        };
      case REMOVE_EDGE_CONFIG:
        const newState = { ...state };
        delete newState[action.payload.configName];
        return newState;
      case UPDATE_CONFIG:
        edgeConfig = state[action.payload.configName];
        return {
          ...state,
          [action.payload.configName]: {
            ...edgeConfig,
            config: {
              ...action.payload.config,
              store:
                action.payload.caCertFilename ||
                (action.payload.clientCertFilename &&
                  action.payload.clientKeyFilename)
                  ? `${action.payload.orgName}/${action.payload.configName}`
                  : null,
              mapping: mappingsReducer(action.payload.config.mapping, action),
            },
            version: action.payload.version,
            caCertFilename: action.payload.caCertFilename,
            clientCertFilename: action.payload.clientCertFilename,
            clientKeyFilename: action.payload.clientKeyFilename,
          },
        };
      case TEST_KAFKA_CONNECTION:
        return {
          ...state,
          [action.payload.configName]: {
            ...state[action.payload.configName],
            isTestingConnection: true,
            isConnected: false,
          },
        };
      case TEST_KAFKA_CONNECTION_DONE:
        // A corner case is possible if the API call kafkaTopics takes a long
        // time to test the connection (on the API). If so, then the config
        // won't be here as expected.
        if (state[action.payload.configName]) {
          return {
            ...state,
            [action.payload.configName]: {
              ...state[action.payload.configName],
              isTestingConnection: false,
              isConnected: action.payload.connectionError === undefined,
              topics: action.payload.topics || [],
            },
          };
        } else {
          return { ...state };
        }
      case ADD_MAPPING:
      case ADD_ROUTE:
      case UPDATE_ROUTE_PATH:
      case MOVE_ROUTE:
      case REMOVE_ROUTE:
        edgeConfig = state[action.payload.configName];
        return {
          ...state,
          [action.payload.configName]: {
            ...edgeConfig,
            config: {
              ...edgeConfig.config,
              mapping: mappingsReducer(edgeConfig.config.mapping, action),
            },
          },
        };
      default:
        return state;
    }
  },
  didFetchEdgeConfig: (state = false, action) => {
    switch (action.type) {
      case FETCH_EDGE_CONFIG_SUCCESS:
        return true;
      default:
        return state;
    }
  },
  temporaryEdgeConfigName: (state = null, action) => {
    switch (action.type) {
      case CREATE_EDGE_CONFIG:
        return action.payload.configName;
      case FINALIZE_TEMPORARY_EDGE_CONFIG:
        return null;
      case REMOVE_EDGE_CONFIG:
        if (action.payload.configName === state) {
          return null;
        } else {
          return state;
        }
      default:
        return state;
    }
  },
});

export type MappingsState = ReadonlyArray<Mapping>;

export const mappingsReducer = (
  state: MappingsState = [],
  action: EdgeConfigsAction
): MappingsState => {
  switch (action.type) {
    case ADD_EDGE_CONFIG:
    case UPDATE_CONFIG:
      return state.map((mapping) => {
        return {
          ...mapping,
          routes: routesReducer(mapping.routes, action),
        };
      });
    case ADD_MAPPING:
      return [
        ...state,
        {
          location: action.payload.location,
          store: action.payload.store,
          routes: routesReducer(action.payload.routes, {} as any),
        },
      ];
    case ADD_ROUTE:
    case UPDATE_ROUTE_PATH:
    case MOVE_ROUTE:
    case REMOVE_ROUTE:
      return state.map((mapping) => {
        if (mapping.location === action.payload.mappingLocation) {
          return {
            ...mapping,
            routes: routesReducer(mapping.routes, action),
          };
        } else {
          return mapping;
        }
      });
    default:
      return state;
  }
};

export type RoutesState = ReadonlyArray<Route>;

export const routesReducer = (
  state: RoutesState = [],
  action: EdgeConfigsAction
): RoutesState => {
  switch (action.type) {
    case ADD_EDGE_CONFIG:
    case UPDATE_CONFIG:
    case ADD_MAPPING:
      return state.map((route) => {
        const updatedRoute = { ...route };
        if (!route.uid) {
          updatedRoute.uid = generateUid();
        }
        if (!route.routePatternExpression) {
          updatedRoute.routePatternExpression = detokenizePath(
            route.routePattern
          );
        }
        return updatedRoute;
      });
    case ADD_ROUTE:
      let route: Route = {
        uid: generateUid(),
        routeType: action.payload.routeType,
        routePattern: action.payload.routePattern,
      };
      if (action.payload.replyTo) {
        route.replyTo = action.payload.replyTo;
      }
      // Topics containing the special term $topic do not have a topicName because the topic
      // will be bound at runtime by tenefit.cloud
      if (!route.routePattern.includes("$topic")) {
        route = { ...route, topicName: action.payload.topicName };
      }
      return [
        ...state.slice(0, action.payload.index),
        route,
        ...state.slice(action.payload.index),
      ];
    case UPDATE_ROUTE_PATH:
      return state.map((route) => {
        if (route.uid === action.payload.uid) {
          const newRoute = {
            ...route,
            routePattern: action.payload.newRoutePattern,
            routePatternExpression: undefined,
          };
          return newRoute;
        } else {
          return route;
        }
      });
    case MOVE_ROUTE:
      const newState = [...state];
      const [removed] = newState.splice(action.payload.startIndex, 1);
      newState.splice(action.payload.endIndex, 0, removed);
      return newState;
    case REMOVE_ROUTE:
      return state.filter((route) => route.uid !== action.payload.uid);
    default:
      return state;
  }
};
