import { Tab, Tabs, Theme, Typography } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { ChangeEvent, PureComponent } from "react";

import { iconStyle } from "../../containers/OrganizationContainer";
import { TENEFIT_SANDBOX_AUTHORITY } from "../../features/addNewApp/thunks";
import * as gtm from "../../services/googleTagManager";
import CodeTemplate from "../CodeTemplate";
import { introNoteHeaderStyle, introTextStyle } from "./DeveloperIntro";
import "./DeveloperIntro.css";

export type DeveloperIntroStepsStep1Props = {
  username: string;
  language: number;
  selectDeveloperIntroStepLanguage: (step: string, language: number) => void;
};

const styles = (theme: Theme) => ({
  introNoteHeader: introNoteHeaderStyle,
  introText: introTextStyle,
  icon: iconStyle,
});

export const languages = ["Node", "Command Line"];

const exampleMessageKey = 1234;

const exampleMessage = {
  orderId: exampleMessageKey,
  orderAmount: 10.55,
  customerName: "Will Smith",
};

class DeveloperIntroStepsStep1 extends PureComponent<
  DeveloperIntroStepsStep1Props & WithStyles<typeof styles>
> {
  public render() {
    const { classes, language, username } = this.props;
    return (
      <div>
        <Typography className={classes.introText}>
          The Sandbox is a publicly reachable Kafka broker with address{" "}
          <strong>{TENEFIT_SANDBOX_AUTHORITY}</strong> that you can publish
          messages into from any backend.
        </Typography>
        <Typography className={classes.introText}>
          When you publish messages to the Sandbox you assign them to a
          specified message category group called “topics”. Topics should have
          the following naming convention to work with the tenefit.cloud
          sandbox: <code>[your username].[topic name]</code>, e.g.{" "}
          <code>{username}.realtimeSportScores</code>.
        </Typography>
        <div className="note">
          <Typography className={classes.introNoteHeader}>Note</Typography>
          <Typography className={classes.introText}>
            Topics in the Sandbox Kafka are automatically configured with{" "}
            <code>cleanup.policy=compact</code>. Therefore when publishing you
            must supply a message key.
          </Typography>
        </div>
        <Typography className={classes.introText}>
          Below are code snippets for publishing into the Sandbox from select
          backend frameworks. If you don’t see your framework of choice, simply
          search for a library that will allow you to publish to a specified
          Kafka broker.
        </Typography>
        <div className="developer-intro-languages">
          <Tabs
            aria-label="Language"
            name="language"
            value={language}
            onChange={this.handleChange}
          >
            <Tab label={languages[0]} />
            <Tab label={languages[1]} />
          </Tabs>
          <div className="intro-tab-details">
            {language === 0 && (
              <div>
                <Typography className={classes.introText}>
                  You can publish to Kafka with Javascript and Node.js using a
                  variety of libraries. For the example below we selected{" "}
                  <a
                    href="https://www.npmjs.com/package/kafka-node"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    kafka-node
                  </a>
                  . You install it with:
                </Typography>
                <CodeTemplate
                  className="developer-intro-code"
                  language="shell"
                  commandPrefix="$ "
                  code={`npm install kafka-node --save --no-optional`}
                />
                <Typography className={classes.introText}>
                  Here is some sample code that will publish a single message:
                </Typography>
                <CodeTemplate
                  className="developer-intro-code"
                  language="javascript"
                  canSave={true}
                  saveFilename="app.js"
                  code={`const KAFKA_ADDRESS = '${TENEFIT_SANDBOX_AUTHORITY}';
const TOPIC_NAME = '${username}.mytopic';

const kafka = require('kafka-node');
const Producer = kafka.Producer;
const KeyedMessage = kafka.KeyedMessage;

const client = new kafka.KafkaClient({kafkaHost: KAFKA_ADDRESS});

const producer = new Producer(client);

const message = new KeyedMessage(${exampleMessageKey}, JSON.stringify(${JSON.stringify(
                    exampleMessage,
                    null,
                    2
                  )}));

const payloads = [
  { topic: TOPIC_NAME, messages: message }
];

producer.on('ready', function () {
  producer.send(payloads, function (err, data) {
    console.log('Sent:', data);
  });
});

producer.on('error', function (err) {
  console.log('There was an error');
});`}
                />
                <div className="note">
                  <Typography className={classes.introNoteHeader}>
                    Note
                  </Typography>
                  <ul>
                    <li>
                      <Typography className={classes.introText}>
                        Prefix your topic name with <code>{username}.</code> in
                        <code>TOPIC_NAME</code> to have your topics
                        automatically listed in the sandbox configuration,
                        further below.
                      </Typography>
                    </li>
                    <li>
                      <Typography className={classes.introText}>
                        In the <code>KeyedMessage</code> function, the first
                        parameter, <code>{exampleMessageKey}</code>, is the
                        message key. The second parameter is the message body,
                        in this case it's a JSON message formatted as a string.
                      </Typography>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {language === 1 && (
              <div>
                <Typography className={classes.introText}>
                  There are several tools and libraries that will publish to
                  Kafka from the command line; here we're showing you
                  instructions that will use the native tools from Kafka itself.
                </Typography>
                <Typography className={classes.introText}>
                  Download and install the latest release from the{" "}
                  <a
                    href="https://kafka.apache.org/downloads"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    apache.org Kafka download page
                  </a>
                  . (In particular, you'll need to ensure that the{" "}
                  <code>kafka-console-producer</code> command works.)
                </Typography>
                <Typography className={classes.introText}>
                  The following command will publish a single message:
                </Typography>
                <CodeTemplate
                  className="developer-intro-code"
                  language="shell"
                  commandPrefix="$ "
                  code={`echo '${exampleMessageKey}:${JSON.stringify(
                    exampleMessage
                  )}' | \
  kafka-console-producer \\
    --broker-list ${TENEFIT_SANDBOX_AUTHORITY} \\
    --property "parse.key=true" \\
    --property "key.separator=:" \\
    --topic ${username}.mytopic`}
                />
                <div className="note">
                  <Typography className={classes.introNoteHeader}>
                    Note
                  </Typography>
                  <ul>
                    <li>
                      <Typography className={classes.introText}>
                        The <code>kafka-console-producer</code> command may be
                        invoked by <code>kafka-console-producer</code> or{" "}
                        <code>kafka-console-producer.sh</code>, depending on
                        your platform and installation.
                      </Typography>
                    </li>
                    <li>
                      <Typography className={classes.introText}>
                        The <code>{exampleMessageKey}</code> before the{" "}
                        <code>:</code> is the message key. The string after the{" "}
                        <code>:</code> is the message body. In this case, it's a
                        JSON message formatted as a string.
                      </Typography>
                    </li>
                    <li>
                      <Typography className={classes.introText}>
                        Prefix your topic name with <code>{username}.</code> in
                        the <code>--topic</code> argument to have your topics
                        automatically listed in the sandbox configuration,
                        further below.
                      </Typography>
                    </li>
                    <li>
                      <Typography className={classes.introText}>
                        You may get a warning the first time you publish. This
                        is Kafka letting you know the topic doesn't exist and is
                        being auto-created for you. The warning can be safely
                        ignored and should not occur again for subsequent
                        publishes.
                      </Typography>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
        <Typography className={classes.introText}>
          Once you have published one or more messages, you can scroll down to
          the <strong>sandbox</strong> app configuration further below and click
          the "eye" icon (
          <span className="intro-icon">
            <VisibilityIcon className={classes.icon} />
          </span>
          ) for your topic to see your data. Then continue to{" "}
          <strong>Step 2</strong> to learn how to stream messages to your client
          application.
        </Typography>
      </div>
    );
  }

  private handleChange = (event: ChangeEvent<object>, value: number) => {
    // Don't do anything if the user clicked the same tab they're already on.
    if (value !== this.props.language) {
      gtm.devIntroSelectLanguageStep1(languages[value]);
    }
    this.props.selectDeveloperIntroStepLanguage("step1", value);
  };
}

export default withStyles(styles)(DeveloperIntroStepsStep1);
