/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React from "react";
import PropTypes from "prop-types";
import { Button, Typography, TextField } from "@material-ui/core";
import AuthPiece from "./AuthPiece";
import { ButtonRow, FormControl } from ".";
import ProgressButton from "../core/ProgressButton";

export default class ConfirmSignUp extends AuthPiece {
  static propTypes = {
    data: PropTypes.shape({
      username: PropTypes.string,
      password: PropTypes.string,
    }),
    confirmSignUp: PropTypes.func.isRequired,
    resendSignUp: PropTypes.func.isRequired,
  };

  get username() {
    return this.props.data ? this.props.data.username : this.inputs.username;
  }

  get password() {
    return this.props.data && this.props.data.password;
  }

  submit = async () => {
    const { code } = this.inputs;
    await this.props.confirmSignUp(this.username, code, this.password);
  };

  resend = () => {
    this.props.resendSignUp(this.username);
  };

  render() {
    const { changeState } = this.props;
    const { isBusy } = this.state;
    return (
      <div>
        <FormControl>
          {this.username ? (
            <Typography align="left">
              We sent you an email with your confirmation code. Enter it below:
            </Typography>
          ) : (
            <TextField
              fullWidth
              label="Username or email"
              key="username"
              name="username"
              onChange={this.handleInputChange}
            />
          )}
        </FormControl>
        <FormControl>
          <TextField
            fullWidth
            autoFocus
            label="Code"
            name="code"
            autoComplete="off"
            onChange={this.handleInputChange}
          />
        </FormControl>
        <ButtonRow>
          <Button variant="contained" fullWidth onClick={this.resend}>
            Resend code
          </Button>
          <ProgressButton
            variant="contained"
            color="primary"
            fullWidth
            isPending={isBusy}
            onClick={this.handleSubmit}
          >
            Confirm
          </ProgressButton>
        </ButtonRow>
        <ButtonRow>
          <Button variant="contained" onClick={() => changeState("signIn")}>
            Back to sign in
          </Button>
        </ButtonRow>
      </div>
    );
  }
}
