import React from "react";
import PropTypes from "prop-types";
import { Typography, Button } from "@material-ui/core";
import "./NotFound.css";

const NotFound = ({ redirectToHome }) => (
  <div className="NotFound">
    <Typography variant="h6">The page you requested does not exist</Typography>
    <Typography>
      This could either mean the organization you tried to visit does not exist,
      or you do not have access to it.
    </Typography>
    <Button onClick={redirectToHome} variant="contained" color="primary">
      Return to home
    </Button>
  </div>
);

NotFound.propTypes = {
  redirectToHome: PropTypes.func.isRequired,
};

export default NotFound;
