/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { Paper, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import * as React from "react";

import {
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  SignUp,
  VerifyContact,
} from ".";
import { authModels } from "../../features/auth";
import "./Authenticator.css";

const messageMap = [
  ["User with given username/email not found", /user.*not.*exist/i],
  [
    "User with given username/email not found",
    /username\/client id .* not found/i,
  ],
  ["A user with that username/email already exists", /user.*already.*exist/i],
  ["Incorrect username or password", /incorrect.*username.*password/i],
  [
    "Invalid password. Passwords must contain: at least 8 characters, one upper-case letter, one lower-case letter, one symbol",
    /validation.*password/i,
  ],
];

export type AuthenticatorProps = {
  authState: authModels.AuthState;
  data: any;
  error: Error | null;
  delivery: object | null;
  verifyAttribute: string | null;
  changeState: (state: authModels.AuthState, data?: any) => any;
  completeNewPassword: (
    user: string,
    password: string,
    requiredAttributes: any
  ) => any;
  confirmSignUp: (username: string, code: string, password: string) => any;
  currentAuthenticatedUser: () => any;
  forgotPassword: (username: string) => any;
  forgotPasswordSubmit: (
    username: string,
    code: string,
    password: string
  ) => any;
  resendSignUp: (username: string) => any;
  signIn: (username: string, password: string) => any;
  signUp: (username: string, email: string, password: string) => any;
  verifiedContact: (user: any) => any;
  verifyCurrentUserAttribute: (attribute: string) => any;
  verifyCurrentUserAttributeSubmit: (attribute: string, code: string) => any;
};

export default class Authenticator extends React.Component<AuthenticatorProps> {
  public static defaultProps: any = {
    children: [],
    onStateChange: () => {},
  };

  public componentDidMount() {
    this.props.currentAuthenticatedUser();
  }

  get errorMessage(): string {
    const { error } = this.props;
    if (error) {
      const message =
        typeof error === "string"
          ? error
          : error.message || JSON.stringify(error);
      const messageMapEntry = messageMap.find((entry) =>
        (entry[1] as RegExp).test(message)
      );

      const errorMessage = messageMapEntry ? (messageMapEntry[0] as string) : message;
      return errorMessage.includes('User is disabled.') ? "tenefit.cloud is no longer accessible. Please contact info@tenefit.com with any questions" : errorMessage;
    } else {
      return "";
    }
  }

  public render() {
    const { authState, error, children } = this.props;
    return authState === "signedIn" ? (
      children
    ) : (
      <Paper className="Authenticator">
        {error && (
          <div className="Authenticator-error">
            <ErrorIcon />
            <Typography>{this.errorMessage}</Typography>
          </div>
        )}
        <div className="Authenticator-content">{this.authPiece()}</div>
      </Paper>
    );
  }

  public authPiece() {
    const {
      authState,
      changeState,
      data,
      delivery,
      verifyAttribute,
      signIn,
      signUp,
      confirmSignUp,
      resendSignUp,
      forgotPassword,
      forgotPasswordSubmit,
      verifyCurrentUserAttribute,
      verifyCurrentUserAttributeSubmit,
    } = this.props;
    switch (authState) {
      case "signIn":
        return <SignIn changeState={changeState} signIn={signIn} />;
      case "signUp":
        return <SignUp changeState={changeState} signUp={signUp} />;
      case "confirmSignUp":
        return (
          <ConfirmSignUp
            changeState={changeState}
            data={data}
            confirmSignUp={confirmSignUp}
            resendSignUp={resendSignUp}
          />
        );
      case "forgotPassword":
        return (
          <ForgotPassword
            changeState={changeState}
            delivery={delivery}
            forgotPassword={forgotPassword}
            forgotPasswordSubmit={forgotPasswordSubmit}
          />
        );
      case "verifyContact":
        return (
          <VerifyContact
            changeState={changeState}
            verifyAttribute={verifyAttribute}
            verifyUserAttribute={verifyCurrentUserAttribute}
            verifyUserAttributeSubmit={verifyCurrentUserAttributeSubmit}
          />
        );
      case "requireNewPassword":
        return <RequireNewPassword changeState={changeState} data={data} />;
      default:
        return null;
    }
  }
}
