import { Theme, Typography } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React, { PureComponent } from "react";

import { edgeConfigsModels } from "../../features/edgeConfigs";
import * as gtm from "../../services/googleTagManager";
import { introNoteHeaderStyle, introTextStyle } from "./DeveloperIntro";
import "./DeveloperIntro.css";
import StreamTemplates, { languages } from "./StreamTemplates";

export type DeveloperIntroStepsStep2Props = {
  origin: string;
  route: edgeConfigsModels.Route & { topicName: string };
  language: number;
  selectDeveloperIntroStepLanguage: (step: string, language: number) => void;
};

const styles = (theme: Theme) => ({
  introNoteHeader: introNoteHeaderStyle,
  introText: introTextStyle,
});

class DeveloperIntroStepsStep2 extends PureComponent<
  DeveloperIntroStepsStep2Props & WithStyles<typeof styles>
> {
  public render() {
    const { origin, route, language, classes } = this.props;
    return (
      <div>
        <Typography className={classes.introText}>
          For each topic that you create in <strong>Step 1</strong>,
          tenefit.cloud will automatically generate a corresponding SSE
          endpoint. Drop this endpoint into your client application and it will
          receive a stream of messages that are published to its underlying
          topic.
        </Typography>
        <Typography className={classes.introText}>
          Below are some examples of how to work with SSE on various client
          frameworks.
        </Typography>
        <StreamTemplates
          isIntro={true}
          origin={origin}
          route={route}
          language={language}
          selectDeveloperIntroStepLanguage={
            this.handleClickCodeTemplateLanguage
          }
        />
      </div>
    );
  }

  private handleClickCodeTemplateLanguage = (
    step: string,
    language: number
  ) => {
    gtm.devIntroSelectLanguageStep2(languages[language]);
    this.props.selectDeveloperIntroStepLanguage(step, language);
  };
}

export default withStyles(styles)(DeveloperIntroStepsStep2);
