import { Typography } from "@material-ui/core";
import React, { PureComponent } from "react";

import "./IEWelcome.css";

export default class IEWelcome extends PureComponent {
  public render() {
    return (
      <div>
        <div className="ie-welcome">
          <div className="ie-welcome-header">
            <Typography variant="h6">
              Internet Explorer not supported for tenefit.cloud Console
            </Typography>
          </div>
          <div>
            <Typography className="ie-welcome-p">
              Don't worry! Your client applications can run just fine in
              Internet Explorer and stream data from tenefit.cloud.
            </Typography>
            <Typography className="ie-welcome-p">
              What this means is that the tenefit.cloud Console, where you do
              your adminstration tasks for tenefit.cloud, is not supported in
              Internet Explorer.
            </Typography>
            <Typography className="ie-welcome-p">
              That's because we want to provide the best user experience
              possible, which requires a modern browser like Chrome, Firefox,
              Safari, or Edge.
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}
