export const FETCH_EDGE_CONFIG_SUCCESS = "FETCH_EDGE_CONFIG_SUCCESS";
export const ADD_EDGE_CONFIG = "ADD_EDGE_CONFIG";
export const CREATE_EDGE_CONFIG = "CREATE_EDGE_CONFIG";
export const FINALIZE_TEMPORARY_EDGE_CONFIG = "FINALIZE_TEMPORARY_EDGE_CONFIG";
export const REMOVE_EDGE_CONFIG = "REMOVE_EDGE_CONFIG";
export const UPDATE_CONFIG = "UPDATE_CONFIG";
export const TEST_KAFKA_CONNECTION = "TEST_KAFKA_CONNECTION";
export const TEST_KAFKA_CONNECTION_DONE = "TEST_KAFKA_CONNECTION_DONE";

// mapping actions
export const ADD_MAPPING = "ADD_MAPPING";
export const UPDATE_MAPPING_LOCATION = "UPDATE_MAPPING_LOCATION";
export const REMOVE_MAPPING = "REMOVE_MAPPING";

// route actions
export const ADD_ROUTE = "ADD_ROUTE";
export const UPDATE_ROUTE_PATH = "UPDATE_ROUTE_PATH";
export const REMOVE_ROUTE = "REMOVE_ROUTE";
export const MOVE_ROUTE = "MOVE_ROUTE";
