import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

import { edgeConfigsModels } from "../features/edgeConfigs";

export type DeleteConfigDialogProps = {
  onClose: () => void;
  onConfirm: () => void;
  location: string;
  route: edgeConfigsModels.Route | null;
};

const DeleteConfigDialog = ({
  onClose,
  onConfirm,
  location,
  route,
}: DeleteConfigDialogProps) =>
  route ? (
    <Dialog open={true} onClose={onClose} maxWidth={false}>
      <DialogTitle>Delete Endpoint</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete{" "}
          <b>{new URL(route.routePattern, location).href}</b>?
        </DialogContentText>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="primary" onClick={onConfirm}>
            Delete
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  ) : null;

export default DeleteConfigDialog;
