/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React from "react";
import PropTypes from "prop-types";
import { TextField, Button, Typography } from "@material-ui/core";

import AuthPiece from "./AuthPiece";
import { FormControl, Link, ButtonRow } from ".";
import ProgressButton from "../core/ProgressButton";

const USERNAME_REGEX = /^[A-Za-z0-9-_]*$/;

export default class SignUp extends AuthPiece {
  static propTypes = {
    signUp: PropTypes.func.isRequired,
  };

  state = { isUsernameValid: true };

  handleUsernameChange = (evt) => {
    this.handleInputChange(evt);
    this.setState({
      isUsernameValid: USERNAME_REGEX.test(evt.target.value),
    });
  };

  get canSubmit() {
    return this.state.isUsernameValid;
  }

  submit = async () => {
    const { username, email, password } = this.inputs;
    await this.props.signUp(username, email, password);
  };

  render() {
    const { changeState } = this.props;
    const { isBusy, isUsernameValid } = this.state;
    return (
      <div>
        <FormControl>
          <TextField
            fullWidth
            autoFocus
            label="Username"
            key="username"
            name="username"
            onChange={this.handleUsernameChange}
            error={!isUsernameValid}
            helperText={
              !isUsernameValid &&
              "Username can only contain letters, numbers, - and _"
            }
          />
        </FormControl>
        <FormControl>
          <TextField
            fullWidth
            label="Email"
            key="email"
            name="email"
            onChange={this.handleInputChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            fullWidth
            label="Password"
            type="password"
            key="password"
            name="password"
            onChange={this.handleInputChange}
          />
        </FormControl>
        <FormControl>
          <Typography>Password must contain at least:</Typography>
          <Typography>- 8 characters</Typography>
          <Typography>- 1 lowercase letter</Typography>
          <Typography>- 1 uppercase letter</Typography>
          <Typography>
            - 1 special character {'e.g. ($ * . { ( ? - " ! @ # % & / < : ; _)'}
          </Typography>
        </FormControl>
        <FormControl>
          <Link align="right" onClick={() => changeState("confirmSignUp")}>
            I already have a code
          </Link>
        </FormControl>
        <ButtonRow>
          <Button
            variant="contained"
            fullWidth
            onClick={() => changeState("signIn")}
          >
            Back to Sign In
          </Button>
          <ProgressButton
            variant="contained"
            color="primary"
            fullWidth
            isPending={isBusy}
            disabled={!this.canSubmit}
            onClick={this.handleSubmit}
          >
            Sign Up
          </ProgressButton>
        </ButtonRow>
      </div>
    );
  }
}
