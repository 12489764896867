/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React from "react";
import PropTypes from "prop-types";
import { Button, Typography, TextField } from "@material-ui/core";

import AuthPiece from "./AuthPiece";
import { FormControl, ButtonRow } from ".";
import ProgressButton from "../core/ProgressButton";

export default class ForgotPassword extends AuthPiece {
  static propTypes = {
    delivery: PropTypes.any,
    forgotPassword: PropTypes.func.isRequired,
    forgotPasswordSubmit: PropTypes.func.isRequired,
  };

  submit = async () => {
    const { username, code, password } = this.inputs;
    if (this.props.delivery) {
      await this.props.forgotPasswordSubmit(username, code, password);
    } else {
      await this.props.forgotPassword(username);
    }
  };

  sendView() {
    const { changeState } = this.props;
    const { isBusy } = this.state;
    return (
      <div>
        <FormControl>
          <Typography align="left">
            Enter your username or email below, and we'll send you a code that
            you can use to set a new password.
          </Typography>
        </FormControl>
        <FormControl>
          <TextField
            fullWidth
            autoFocus
            label="Username or email"
            key="username"
            name="username"
            onChange={this.handleInputChange}
          />
        </FormControl>
        <ButtonRow>
          <Button
            fullWidth
            variant="contained"
            onClick={() => changeState("signIn")}
          >
            Back to Sign In
          </Button>
          <ProgressButton
            fullWidth
            variant="contained"
            color="primary"
            isPending={isBusy}
            onClick={this.handleSubmit}
          >
            Send Code
          </ProgressButton>
        </ButtonRow>
      </div>
    );
  }

  submitView() {
    const { changeState } = this.props;
    const { isBusy } = this.state;
    return (
      <div>
        <FormControl>
          <Typography align="left">
            A code has been sent. Please check your inbox and spam folders.
          </Typography>
        </FormControl>
        <FormControl>
          <TextField
            fullWidth
            label="Code"
            key="code"
            name="code"
            autoComplete="off"
            onChange={this.handleInputChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            fullWidth
            label="New Password"
            name="password"
            type="password"
            onChange={this.handleInputChange}
          />
        </FormControl>
        <ButtonRow>
          <Button
            fullWidth
            variant="contained"
            onClick={() => changeState("signIn")}
          >
            Back to Sign In
          </Button>
          <ProgressButton
            fullWidth
            variant="contained"
            color="primary"
            isPending={isBusy}
            onClick={this.handleSubmit}
          >
            Set New Password
          </ProgressButton>
        </ButtonRow>
      </div>
    );
  }

  render() {
    const { delivery } = this.props;
    return <div>{delivery ? this.submitView() : this.sendView()}</div>;
  }
}
