import { action } from "typesafe-actions";
import uuid from "uuid/v4";

import { Key } from "../../services/api";
import {
  CREATE_KEY,
  CREATE_KEY_FAILURE,
  CREATE_KEY_SUCCESS,
  DOWNLOAD_KEY,
  FETCH_KEYS,
  FETCH_KEYS_FAILURE,
  FETCH_KEYS_SUCCESS,
} from "./constants";

export const fetchKeys = (orgName: string) => action(FETCH_KEYS, { orgName });
export const fetchKeysSuccess = (keys: Key[]) =>
  action(FETCH_KEYS_SUCCESS, keys);
export const fetchKeysFailure = (orgName: string, error: any) =>
  action(FETCH_KEYS_FAILURE, { orgName, error });

export const createKey = (orgName: string, keyName: string = uuid()) =>
  action(CREATE_KEY, { orgName, keyName });
export const createKeySuccess = (key: Key) => action(CREATE_KEY_SUCCESS, key);
export const createKeyFailure = (orgName: string, keyName: string, err: any) =>
  action(CREATE_KEY_FAILURE, { orgName, keyName, err });

export const downloadKey = (orgName: string, keyName: string) =>
  action(DOWNLOAD_KEY, { orgName, keyName });
