import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

export type DeleteAllRoutesDialogProps = {
  configName: string;
  onClose: () => void;
  onConfirm: () => void;
};

const DeleteAllRoutesDialog = ({
  configName,
  onClose,
  onConfirm,
}: DeleteAllRoutesDialogProps) => (
  <Dialog open={true} onClose={onClose} maxWidth={false}>
    <DialogTitle>Delete All Endpoints</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to delete ALL endpoints in the{" "}
        <strong>{configName}</strong> configuration?
      </DialogContentText>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={onConfirm}>
          Delete all endpoints
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
);

export default DeleteAllRoutesDialog;
