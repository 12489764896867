import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Types from "Types";

import Mapping, { MappingProps } from "../components/Mapping";
import {
  edgeConfigsActions,
  edgeConfigsModels,
  edgeConfigsThunks,
} from "../features/edgeConfigs";
import { PATH_FOR_AUTO_SSE_MAPPING } from "../globalConstants";
import * as gtm from "../services/googleTagManager";

const MappingContainer = (props: MappingProps) => <Mapping {...props} />;

type OwnProps = {
  edgeConfig: edgeConfigsModels.EdgeConfig;
};

const mapStateToProps = (state: Types.RootState, { edgeConfig }: OwnProps) => ({
  edgeConfig,
  domainsState: state.domains,
  userPrefs: state.userPrefs,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<Types.RootState, void, Types.RootAction>,
  { edgeConfig }: OwnProps
) => ({
  testConnection: (configName: string, orgName: string) =>
    dispatch(edgeConfigsThunks.testConnection(configName, orgName)),
  addRoute: async (
    location: string,
    routeType: edgeConfigsModels.RouteType,
    topicName: string,
    routePattern: string,
    replyTo: string | null
  ) => {
    dispatch(
      edgeConfigsActions.addRoute(
        edgeConfig.name,
        edgeConfig.organization,
        routeType,
        location,
        routePattern,
        topicName,
        replyTo
      )
    );
    await dispatch(
      edgeConfigsThunks.saveNextVersion(
        edgeConfig.name,
        edgeConfig.organization
      )
    );
  },
  editRoutePattern: async (
    location: string,
    route: edgeConfigsModels.Route,
    newRoutePattern: string
  ) => {
    dispatch(
      edgeConfigsActions.updateRoutePath(
        edgeConfig.name,
        edgeConfig.organization,
        location,
        route.uid!,
        route.routePattern,
        newRoutePattern
      )
    );
    await dispatch(
      edgeConfigsThunks.saveNextVersion(
        edgeConfig.name,
        edgeConfig.organization
      )
    );
  },
  reorderRoutes: async (
    location: string,
    startIndex: number,
    endIndex: number
  ) => {
    dispatch(
      edgeConfigsActions.moveRoute(
        edgeConfig.name,
        edgeConfig.organization,
        location,
        startIndex,
        endIndex
      )
    );
    await dispatch(
      edgeConfigsThunks.saveNextVersion(
        edgeConfig.name,
        edgeConfig.organization
      )
    );
  },
  deleteRoute: async (location: string, route: edgeConfigsModels.Route) => {
    dispatch(
      edgeConfigsActions.removeRoute(
        edgeConfig.name,
        edgeConfig.organization,
        location,
        route.uid!,
        route.routePattern
      )
    );
    await dispatch(
      edgeConfigsThunks.saveNextVersion(
        edgeConfig.name,
        edgeConfig.organization
      )
    );
  },
  deleteAllRoutes: async () => {
    const mapping = edgeConfig.config.mapping[0];
    if (mapping.routes.length > 0) {
      mapping.routes.forEach((route) => {
        dispatch(
          edgeConfigsActions.removeRoute(
            edgeConfig.name,
            edgeConfig.organization,
            mapping.location,
            route.uid!,
            route.routePattern
          )
        );
      });
      await dispatch(
        edgeConfigsThunks.saveNextVersion(
          edgeConfig.name,
          edgeConfig.organization
        )
      );
    }
  },
  mapSseEndpoints: async (selectedTopics: string[]) => {
    if (selectedTopics.length > 0) {
      selectedTopics.forEach((topic) =>
        dispatch(
          edgeConfigsActions.addRoute(
            edgeConfig.name,
            edgeConfig.organization,
            edgeConfigsModels.RouteType.sse,
            edgeConfig.config.mapping[0].location,
            `/${PATH_FOR_AUTO_SSE_MAPPING}/${topic}`,
            topic,
            null
          )
        )
      );
      await dispatch(
        edgeConfigsThunks.saveNextVersion(
          edgeConfig.name,
          edgeConfig.organization
        )
      );
    }
  },
  deleteConfig: async () => {
    gtm.configDelete(edgeConfig.name);
    dispatch(
      edgeConfigsActions.remove(edgeConfig.name, edgeConfig.organization)
    );
  },
  openFAQ: () => {
    window.open("https://docs.tenefit.com/tenefit.cloud/faq");
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MappingContainer);
