import { ThunkAction } from "redux-thunk";
import Types from "Types";

import * as api from "../../services/api";
import { organizationsActions } from "../organizations";
import * as actions from "./actions";

export const fetchSessionUser = (): ThunkAction<
  Promise<void>,
  void,
  void,
  Types.RootAction
> => {
  return async (dispatch) => {
    const { username, email, organizations } = await api.getSessionUser();
    dispatch(actions.addUser(username, email, organizations));
    if (!organizations || organizations.length === 0) {
      dispatch(organizationsActions.createOrganization(username));
    }
    dispatch(actions.fetchSessionUserSuccess(username));
  };
};
