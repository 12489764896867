import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import * as actions from "./actions";
import { ADD_USER, FETCH_SESSION_USER_SUCCESS } from "./constants";
import { User } from "./models";

import { OrganizationsAction } from "../organizations";
import { CREATE_ORGANIZATION_SUCCESS } from "../organizations/constants";

export type UsersAction = ActionType<typeof actions>;

export type UsersState = {
  readonly byName: {
    [name: string]: User;
  };
  readonly sessionUserName: string | null;
};

export default combineReducers<UsersState, UsersAction | OrganizationsAction>({
  byName: (state = {}, action) => {
    switch (action.type) {
      case ADD_USER:
        return {
          ...state,
          [action.payload.username]: {
            username: action.payload.username,
            email: action.payload.email,
            organizations: action.payload.organizations || [],
          },
        };
      case CREATE_ORGANIZATION_SUCCESS:
        const existingOrgs = state[action.payload.name].organizations;
        const newOrg = action.payload.name;
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            organizations: [...existingOrgs, newOrg],
          },
        };
      default:
        return state;
    }
  },
  sessionUserName: (state = null, action) => {
    switch (action.type) {
      case FETCH_SESSION_USER_SUCCESS:
        return action.payload;
      default:
        return state;
    }
  },
});
