import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Typography } from "@material-ui/core";
import "./FormSection.css";

const FormSection = ({ title, children }) => (
  <FormGroup className="FormSection">
    <div className="FormSection-title">
      <Typography
        variant="subtitle1"
        color="inherit"
        className="FormSection-title"
      >
        {title}
      </Typography>
    </div>
    <div className="FormSection-content">{children}</div>
  </FormGroup>
);

FormSection.propTypes = {
  title: PropTypes.string.isRequired,
};

export default FormSection;
