import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Types from "Types";

import CreateAppDialog, {
  CreateAppDialogProps,
} from "../components/CreateAppDialog";
import {
  addNewAppActions,
  addNewAppSelectors,
  addNewAppThunks,
} from "../features/addNewApp";
import {
  edgeConfigsActions,
  edgeConfigsSelectors,
} from "../features/edgeConfigs";
import { keysActions, keysSelectors } from "../features/keys";
import { organizationsModels } from "../features/organizations";

export type CreateAppContainerProps = {
  open: boolean;
} & CreateAppDialogProps;

const CreateAppContainer = ({ open, ...props }: CreateAppContainerProps) =>
  open ? <CreateAppDialog {...props} /> : null;

type OwnProps = {
  organization: organizationsModels.Organization;
};

const mapStateToProps = (
  state: Types.RootState,
  { organization }: OwnProps
) => ({
  open: addNewAppSelectors.getIsCreateDialogOpen(state.addNewApp),
  existingConfigNames: edgeConfigsSelectors
    .getEdgeConfigs(organization.edgeConfigs)
    .map((edgeConfig) => edgeConfig.name),
  isCreating: addNewAppSelectors.getIsCreating(state.addNewApp),
  temporaryEdgeConfig: edgeConfigsSelectors.getTemporaryEdgeConfig(
    organization.edgeConfigs
  ),
  messages: addNewAppSelectors.getMessages(state.addNewApp),
  isDone: addNewAppSelectors.getIsDone(state.addNewApp),
  publicKey: keysSelectors.getKeys(state.keys, organization.name)[0],
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<Types.RootState, void, Types.RootAction>
) => ({
  clearMessages: () => dispatch(addNewAppActions.clearMessages()),
  createSandbox: (orgName: string) =>
    dispatch(addNewAppThunks.createSandbox(orgName)),
  createAndTest: (
    name: string,
    orgName: string,
    protocol: string,
    domain: string,
    port: number,
    keyName?: string
  ) =>
    dispatch(
      addNewAppThunks.createAndTest(
        name,
        orgName,
        protocol,
        domain,
        port,
        keyName
      )
    ),
  confirmCreate: (orgName: string) =>
    dispatch(addNewAppThunks.confirmCreate(orgName)),
  cancelCreate: (orgName: string) =>
    dispatch(addNewAppThunks.cancelCreate(orgName)),
  removeEdgeConfig: (configName: string, orgName: string) => {
    dispatch(edgeConfigsActions.remove(configName, orgName));
  },
  createKey: (orgName: string) => dispatch(keysActions.createKey(orgName)),
  setCaCert: (caCert: File | null) =>
    dispatch(addNewAppActions.setCaCert(caCert)),
  setClientCert: (clientCert: File | null) =>
    dispatch(addNewAppActions.setClientCert(clientCert)),
  setClientKey: (clientKey: File | null) =>
    dispatch(addNewAppActions.setClientKey(clientKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAppContainer);
