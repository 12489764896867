import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, { PureComponent } from "react";

import { addNewAppModels } from "../features/addNewApp";
import { edgeConfigsModels } from "../features/edgeConfigs";
import { keysModels } from "../features/keys";
import { organizationsModels } from "../features/organizations";
import { TENEFIT_SANDBOX_CONFIG_NAME } from "../globalConstants";
import * as gtm from "../services/googleTagManager";
import { Link } from "./auth";
import FormSection from "./core/FormSection";
import StatusMessage from "./core/StatusMessage";
import DirectDetails from "./createKafka/DirectDetails";
import ReachabilityItem from "./createKafka/ReachabilityItem";
import ReachabilitySelect from "./createKafka/ReachabilitySelect";

import "./CreateAppDialog.css";

const MAX_NUMBER_OF_APPS = 5;
const SALES_EMAIL = "sales@tenefit.io";
const EMAIL_SUBJECT = "Upgrading my number of tenefit.cloud apps";
const EMAIL_BODY =
  "I have configured my maximum number of tenefit.cloud apps. Please contact me to discuss upgrading my limit.";

export type CreateAppDialogProps = {
  organization: organizationsModels.Organization;
  existingConfigNames: string[];
  isCreating: boolean;
  temporaryEdgeConfig: edgeConfigsModels.EdgeConfig | null;
  messages: addNewAppModels.Message[];
  isDone: boolean;
  publicKey?: keysModels.Key;
  clearMessages: () => void;
  createSandbox: (orgName: string) => void;
  createAndTest: (
    name: string,
    orgName: string,
    protocol: string,
    domain: string,
    port: number,
    keyName?: string
  ) => void;
  confirmCreate: (orgName: string) => void;
  cancelCreate: (orgName: string) => void;
  removeEdgeConfig: (configName: string, orgName: string) => void;
  createKey: (orgName: string) => void;
  setCaCert: (caCert: File | null) => void;
  setClientCert: (clientCert: File | null) => void;
  setClientKey: (clientKey: File | null) => void;
};

export type CreateAppDialogState = {
  reachabilityType: string | null;
};

export default class CreateAppDialog extends PureComponent<
  CreateAppDialogProps,
  CreateAppDialogState
> {
  public state = { reachabilityType: null };

  private dialogContentRef = React.createRef<HTMLDivElement>();

  public componentDidUpdate(oldProps: CreateAppDialogProps) {
    const current = this.dialogContentRef.current;
    if (current && this.props.messages.length > oldProps.messages.length) {
      current.scrollTop = current.scrollHeight;
    }
  }

  public render() {
    const {
      organization,
      existingConfigNames,
      isCreating,
      temporaryEdgeConfig,
      isDone,
      messages,
      publicKey,
      createAndTest,
      confirmCreate,
      cancelCreate,
      removeEdgeConfig,
      createKey,
      setCaCert,
      setClientCert,
      setClientKey,
    } = this.props;
    const { reachabilityType } = this.state;
    return (
      <Dialog open={true} maxWidth="md">
        <DialogTitle>Add a new app</DialogTitle>
        <div ref={this.dialogContentRef} className="CreateAppDialog-content">
          {!temporaryEdgeConfig &&
          existingConfigNames.length >= MAX_NUMBER_OF_APPS
            ? this.tooManyAppsMessage()
            : this.reachabilitySection()}
          {(reachabilityType === "direct" ||
            reachabilityType === "mskSandbox") && (
            <DirectDetails
              organization={organization}
              existingConfigNames={existingConfigNames}
              isCreating={isCreating}
              temporaryEdgeConfig={temporaryEdgeConfig}
              isDone={isDone}
              publicKey={publicKey}
              isMskSandbox={reachabilityType === "mskSandbox"}
              createAndTest={createAndTest}
              removeEdgeConfig={removeEdgeConfig}
              createKey={createKey}
              setCaCert={setCaCert}
              setClientCert={setClientCert}
              setClientKey={setClientKey}
            />
          )}
          <div className="CreateAppDialog-status">
            {messages.map((message) => (
              <StatusMessage key={message.message} type={message.type}>
                {message.message}
              </StatusMessage>
            ))}
            {isDone && (
              <Typography align="center">
                All set! Press the "DONE" button below to see your new app.
              </Typography>
            )}
          </div>
        </div>
        <DialogActions>
          {isDone ? (
            <Button
              color="primary"
              onClick={confirmCreate.bind(undefined, organization.name)}
            >
              Done
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={cancelCreate.bind(undefined, organization.name)}
            >
              Cancel
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  private handleChange = (evt: any) => {
    this.props.clearMessages();
    if (evt.target.value === TENEFIT_SANDBOX_CONFIG_NAME) {
      this.props.createSandbox(this.props.organization.name);
    }
    this.setState({ reachabilityType: evt.target.value });
  };

  private handleContactSales = () => {
    window.location.href = `mailto:${SALES_EMAIL}?subject=${encodeURIComponent(
      EMAIL_SUBJECT
    )}&body=${encodeURIComponent(EMAIL_BODY)}`;
  };

  private tooManyAppsMessage() {
    return (
      <div className="CreateAppDialog-tooManyAppsMessage">
        <Typography align="center">
          You have already configured your maximum of {MAX_NUMBER_OF_APPS} apps
        </Typography>
        <Typography align="center">
          To add another, please delete one of your existing apps or contact us
          at
        </Typography>
        <Link onClick={this.handleContactSales} align="center">
          sales@tenefit.io
        </Link>
      </div>
    );
  }

  private handleClickMsk = () => {
    gtm.clickVpcPeeringButton();
    window.open("https://tenefit.com/vpc-peering-request/");
  };

  private reachabilitySection() {
    const { isDone } = this.props;
    const { reachabilityType } = this.state;
    const { existingConfigNames } = this.props;
    // User should be prevented from creating a new sandbox configuration if one already exists.
    const disableSandbox =
      existingConfigNames.indexOf(TENEFIT_SANDBOX_CONFIG_NAME) > -1;
    return (
      <FormSection title="How do we reach your Kafka cluster?">
        <div className="CreateAppDialog-reachOptions">
          <ReachabilitySelect
            name="reachabilityType"
            value={reachabilityType}
            disabled={isDone}
            onChange={this.handleChange}
          >
            <ReachabilityItem
              title="Amazon MSK sandbox"
              value="mskSandbox"
              description="Use our AWS MSK cluster as your backend Kafka broker"
              extra={
                <div className="mskExtra">
                  <hr className="mskExtra-line" />
                  <Typography className="mskExtra-text">
                    If you would like to use your own
                    <br />
                    Amazon MSK, then click below:
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={this.handleClickMsk}
                    color={"primary"}
                    className="mskExtra-button"
                  >
                    AWS MSK Peering
                  </Button>
                </div>
              }
            />
            <ReachabilityItem
              title="Bring your own Kafka"
              value="direct"
              description="Your kafka broker is reachable directly over the internet"
            />
            <ReachabilityItem
              title="Don't have one? Use ours"
              value={TENEFIT_SANDBOX_CONFIG_NAME}
              description="We'll connect you to our sandbox Kafka broker"
              disabled={disableSandbox}
            />
          </ReachabilitySelect>
        </div>
      </FormSection>
    );
  }
}
