/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React from "react";
import PropTypes from "prop-types";
import { Button, TextField, Typography } from "@material-ui/core";

import AuthPiece from "./AuthPiece";
import { FormControl, ButtonRow } from ".";
import ProgressButton from "../core/ProgressButton";

export default class RequireNewPassword extends AuthPiece {
  static propTypes = {
    data: PropTypes.any,
    completeNewPassword: PropTypes.func.isRequired,
  };

  submit = async () => {
    const { data } = this.props;
    const { password } = this.inputs;
    const { requiredAttributes } = data.challengeParam;
    await this.props.completeNewPassword(data, password, requiredAttributes);
  };

  render() {
    const { changeState } = this.props;
    const { isBusy } = this.state;
    return (
      <div>
        <FormControl>
          <Typography align="left">Please set a new password</Typography>
        </FormControl>
        <FormControl>
          <TextField
            autoFocus
            fullWidth
            label="New password"
            key="password"
            name="password"
            type="password"
            onChange={this.handleInputChange}
          />
        </FormControl>
        <ButtonRow>
          <Button variant="contained" onClick={() => changeState("signIn")}>
            Back to Sign in
          </Button>
          <ProgressButton
            variant="contained"
            color="primary"
            isPending={isBusy}
            onClick={this.handleSubmit}
          >
            Change Password
          </ProgressButton>
        </ButtonRow>
      </div>
    );
  }
}
