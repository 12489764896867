import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import { DomainsAction } from "../domains";
import {
  edgeConfigsActions,
  edgeConfigsConstants,
  edgeConfigsReducer,
} from "../edgeConfigs";
import * as actions from "./actions";
import {
  CREATE_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_FAILURE,
  FETCH_ORGANIZATION_SUCCESS,
} from "./constants";
import { Organization } from "./models";

const LOCATION_CHANGE = "@@router/LOCATION_CHANGE";
type LocationChangeAction = {
  type: typeof LOCATION_CHANGE;
  payload: {
    location: {
      pathname: string;
    };
  };
};

export type OrganizationsAction = ActionType<
  typeof actions & typeof edgeConfigsActions
>;

export type OrganizationsState = {
  byName: {
    [name: string]: Organization;
  };
  isFetchingOrganization: boolean;
};

export default combineReducers<
  OrganizationsState,
  OrganizationsAction | LocationChangeAction | DomainsAction
>({
  byName: (state = {}, action) => {
    let organization;
    switch (action.type) {
      case FETCH_ORGANIZATION_SUCCESS:
      case CREATE_ORGANIZATION_SUCCESS:
        organization =
          action.payload.name in state ? state[action.payload.name] : undefined;
        return {
          ...state,
          [action.payload.name]: {
            name: action.payload.name,
            edgeConfigs: edgeConfigsReducer(
              organization && organization.edgeConfigs,
              {} as any
            ),
          },
        };
      case edgeConfigsConstants.FETCH_EDGE_CONFIG_SUCCESS:
      case edgeConfigsConstants.ADD_EDGE_CONFIG:
      case edgeConfigsConstants.ADD_MAPPING:
      case edgeConfigsConstants.ADD_ROUTE:
      case edgeConfigsConstants.CREATE_EDGE_CONFIG:
      case edgeConfigsConstants.FINALIZE_TEMPORARY_EDGE_CONFIG:
      case edgeConfigsConstants.MOVE_ROUTE:
      case edgeConfigsConstants.REMOVE_EDGE_CONFIG:
      case edgeConfigsConstants.REMOVE_ROUTE:
      case edgeConfigsConstants.TEST_KAFKA_CONNECTION:
      case edgeConfigsConstants.TEST_KAFKA_CONNECTION_DONE:
      case edgeConfigsConstants.UPDATE_CONFIG:
      case edgeConfigsConstants.UPDATE_ROUTE_PATH:
        return {
          ...state,
          [action.payload.orgName]: {
            ...state[action.payload.orgName],
            edgeConfigs: edgeConfigsReducer(
              state[action.payload.orgName].edgeConfigs,
              action
            ),
          },
        };
      default:
        return state;
    }
  },
  isFetchingOrganization: (state = false, action) => {
    switch (action.type) {
      case FETCH_ORGANIZATION:
        return true;
      case FETCH_ORGANIZATION_SUCCESS:
      case FETCH_ORGANIZATION_FAILURE:
        return false;
      default:
        return state;
    }
  },
});
