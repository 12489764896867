import { action } from "typesafe-actions";

import {
  ADD_EDGE_CONFIG,
  ADD_MAPPING,
  ADD_ROUTE,
  CREATE_EDGE_CONFIG,
  FETCH_EDGE_CONFIG_SUCCESS,
  FINALIZE_TEMPORARY_EDGE_CONFIG,
  MOVE_ROUTE,
  REMOVE_EDGE_CONFIG,
  REMOVE_ROUTE,
  TEST_KAFKA_CONNECTION,
  TEST_KAFKA_CONNECTION_DONE,
  UPDATE_CONFIG,
  UPDATE_ROUTE_PATH,
} from "./constants";
import {
  EdgeConfigConfig,
  EdgeConfigState,
  EdgeState,
  Route,
  RouteType,
} from "./models";

export const fetchEdgeConfigSuccess = (orgName: string) =>
  action(FETCH_EDGE_CONFIG_SUCCESS, { orgName });

export const addEdgeConfig = (
  configName: string,
  orgName: string,
  version: number,
  caCertFilename: string | null,
  clientCertFilename: string | null,
  clientKeyFilename: string | null,
  config: EdgeConfigConfig,
  edgeState: EdgeState,
  edgeConfigState: EdgeConfigState
) =>
  action(ADD_EDGE_CONFIG, {
    configName,
    orgName,
    version,
    config,
    caCertFilename,
    clientCertFilename,
    clientKeyFilename,
    edgeState,
    edgeConfigState,
    time: new Date(),
  });

export const createEdgeConfig = (
  configName: string,
  orgName: string,
  address: string,
  caCertFilename: string | null,
  clientCertFilename: string | null,
  clientKeyFilename: string | null,
  keyName?: string
) =>
  action(CREATE_EDGE_CONFIG, {
    configName,
    orgName,
    address,
    caCertFilename,
    clientCertFilename,
    clientKeyFilename,
    keyName,
    time: new Date(),
  });

export const finalizeTemporaryEdgeConfig = (orgName: string) =>
  action(FINALIZE_TEMPORARY_EDGE_CONFIG, { orgName });

export const updateConfig = (
  configName: string,
  orgName: string,
  config: EdgeConfigConfig,
  version: number,
  caCertFilename: string | null,
  clientCertFilename: string | null,
  clientKeyFilename: string | null
) =>
  action(UPDATE_CONFIG, {
    configName,
    orgName,
    config,
    version,
    caCertFilename,
    clientCertFilename,
    clientKeyFilename,
  });

export const testKafkaConnection = (configName: string, orgName: string) =>
  action(TEST_KAFKA_CONNECTION, { configName, orgName });

export const testKafkaConnectionDone = (
  configName: string,
  orgName: string,
  topics: string[] | null,
  connectionError?: Error
) =>
  action(TEST_KAFKA_CONNECTION_DONE, {
    configName,
    orgName,
    topics: topics || [],
    connectionError,
  });

export const remove = (configName: string, orgName: string) =>
  action(REMOVE_EDGE_CONFIG, { configName, orgName });

export const addMapping = (
  configName: string,
  orgName: string,
  location: string,
  store: string = "portal",
  routes: Route[] = []
) => action(ADD_MAPPING, { configName, orgName, location, store, routes });

export const addRoute = (
  configName: string,
  orgName: string,
  routeType: RouteType,
  mappingLocation: string,
  routePattern: string,
  topicName: string,
  replyTo: string | null,
  index = 0
) => {
  return action(ADD_ROUTE, {
    configName,
    orgName,
    routeType,
    mappingLocation,
    routePattern,
    topicName,
    replyTo,
    index,
  });
};

export const removeRoute = (
  configName: string,
  orgName: string,
  mappingLocation: string,
  uid: string,
  routePattern: string
) =>
  action(REMOVE_ROUTE, {
    configName,
    orgName,
    mappingLocation,
    uid,
    routePattern,
  });

export const updateRoutePath = (
  configName: string,
  orgName: string,
  mappingLocation: string,
  uid: string,
  oldRoutePattern: string,
  newRoutePattern: string
) =>
  action(UPDATE_ROUTE_PATH, {
    configName,
    orgName,
    mappingLocation,
    uid,
    oldRoutePattern,
    newRoutePattern,
  });

export const moveRoute = (
  configName: string,
  orgName: string,
  mappingLocation: string,
  startIndex: number,
  endIndex: number
) =>
  action(MOVE_ROUTE, {
    configName,
    orgName,
    mappingLocation,
    startIndex,
    endIndex,
  });
