import { combineEpics, Epic } from "redux-observable";
import { of } from "rxjs";
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap,
} from "rxjs/operators";
import Types from "Types";
import { isOfType } from "typesafe-actions";

import downloadFile from "../../downloadFile";
import * as api from "../../services/api";
import * as actions from "./actions";
import { CREATE_KEY, DOWNLOAD_KEY, FETCH_KEYS } from "./constants";
import { getKey } from "./selectors";

export const fetchKeys: Epic<
  Types.RootAction,
  Types.RootAction,
  Types.RootState
> = (action$, store$) =>
  action$.pipe(
    filter(isOfType(FETCH_KEYS)),
    mergeMap((action) =>
      api.getKeys(action.payload.orgName).pipe(
        map((keys) => actions.fetchKeysSuccess(keys)),
        catchError((err) =>
          of(actions.fetchKeysFailure(action.payload.orgName, err))
        )
      )
    )
  );

export const createKey: Epic<
  Types.RootAction,
  Types.RootAction,
  Types.RootState
> = (action$, store$) =>
  action$.pipe(
    filter(isOfType(CREATE_KEY)),
    mergeMap((action) =>
      api.createKey(action.payload.orgName, action.payload.keyName).pipe(
        map((key) => actions.createKeySuccess(key)),
        catchError((err) =>
          of(
            actions.createKeyFailure(
              action.payload.orgName,
              action.payload.keyName,
              err
            )
          )
        )
      )
    )
  );

export const downloadKey: Epic<
  Types.RootAction,
  Types.RootAction,
  Types.RootState
> = (action$, store$) =>
  action$.pipe(
    filter(isOfType(DOWNLOAD_KEY)),
    tap((action) => {
      const key = getKey(
        store$.value.keys,
        action.payload.orgName,
        action.payload.keyName
      );
      if (key) {
        downloadFile(`tenefit-cloud-${key.organization}.pub`, key.publicKey);
      }
    }),
    ignoreElements()
  );

export default combineEpics(fetchKeys, createKey, downloadKey);
