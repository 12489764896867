import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Types from "Types";

import HelpTooltip from "../components/HelpTooltip";
import Loading from "../components/Loading";
import PublicKeyExplanation from "../components/PublicKeyExplanation";
import { addNewAppActions } from "../features/addNewApp";
import {
  edgeConfigsSelectors,
  edgeConfigsThunks,
} from "../features/edgeConfigs";
import { keysActions, keysModels, keysSelectors } from "../features/keys";
import {
  organizationsActions,
  organizationsModels,
  organizationsSelectors,
} from "../features/organizations";
import { userPrefsActions } from "../features/userPrefs";
import { DEBUG_INTERVALS } from "../globalConstants";
import * as gtm from "../services/googleTagManager";
import CreateAppContainer from "./CreateAppContainer";
import DeveloperIntroContainer from "./DeveloperIntroContainer";
import MappingContainer from "./MappingContainer";
import NotFoundContainer from "./NotFoundContainer";
import TestContainer from "./TestContainer";

import "./Organization.css";

const TEST_DELAY = !DEBUG_INTERVALS ? 30000 : 3000000;

export const iconStyle = {
  color: "rgba(0, 0, 0, 0.54)",
};

// paramTest comes from query parameter ?test=true to activate TestContainer to use for testing Typescript code.
type OwnProps = {
  orgName: string;
  paramTest: boolean;
};

type OrganizationContainerProps = {
  isFetchingOrganization: boolean;
  didFetchEdgeConfig: boolean;
  organization: organizationsModels.Organization | null;
  publicKey?: keysModels.Key;
  isShowingDeveloperIntro: boolean;
  isCreatingIntroSandbox: boolean;
  fetchOrganization: (name: string) => void;
  streamEdgeConfigs: (name: string) => any;
  testEdgeConfig: (configName: string, orgName: string) => void;
  openCreateNewAppDialog: () => void;
  downloadKey: (key: keysModels.Key) => void;
  hideDeveloperIntro: () => void;
  showDeveloperIntro: () => void;
  handleExpandDeveloperIntroStep: (step: string | null) => void;
};

type OrganizationContainerState = {
  isShowingPublicKeyExplanation: boolean;
};
class OrganizationContainer extends Component<
  OrganizationContainerProps & OwnProps,
  OrganizationContainerState
> {
  public state: OrganizationContainerState = {
    isShowingPublicKeyExplanation: false,
  };
  private cancelEdgeConfigsStream?: () => void;

  private testConfigInterval: NodeJS.Timer | null = null;

  public async componentDidMount() {
    const { orgName, fetchOrganization, streamEdgeConfigs } = this.props;
    gtm.authPageLoad(orgName);
    fetchOrganization(orgName);
    if (!this.props.paramTest) {
      this.cancelEdgeConfigsStream = streamEdgeConfigs(orgName);
      this.testConfigInterval = setInterval(() => {
        const { organization, testEdgeConfig } = this.props;
        if (organization) {
          edgeConfigsSelectors
            .getEdgeConfigs(organization.edgeConfigs)
            .forEach((edgeConfig) =>
              testEdgeConfig(edgeConfig.name, edgeConfig.organization)
            );
        }
      }, TEST_DELAY);
    }
  }

  public componentWillUnmount() {
    if (this.testConfigInterval) {
      clearInterval(this.testConfigInterval);
    }
    if (this.cancelEdgeConfigsStream) {
      this.cancelEdgeConfigsStream();
    }
  }

  public render() {
    const {
      didFetchEdgeConfig,
      organization,
      isFetchingOrganization,
      openCreateNewAppDialog,
      isShowingDeveloperIntro,
      isCreatingIntroSandbox,
      hideDeveloperIntro,
      showDeveloperIntro,
    } = this.props;
    const { isShowingPublicKeyExplanation } = this.state;
    if (organization && didFetchEdgeConfig) {
      const edgeConfigs = edgeConfigsSelectors.getEdgeConfigs(
        organization.edgeConfigs
      );
      return this.props.paramTest ? (
        <React.Fragment>
          <TestContainer edgeConfigs={edgeConfigs} />
        </React.Fragment>
      ) : (
        <div className="Organization">
          <div className="Organization-header">
            <Typography
              variant="h6"
              align="left"
              className="Organization-header-title"
            >
              Apps
            </Typography>
            {isShowingDeveloperIntro ? (
              <Button color="primary" onClick={hideDeveloperIntro}>
                Hide developer intro
              </Button>
            ) : (
              <Button color="primary" onClick={showDeveloperIntro}>
                Show developer intro
              </Button>
            )}
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleClickMsk}
            >
              AWS MSK Peering
            </Button>
            {/* {publicKey && (
              <div>
                <Button variant="contained" onClick={this.handleDownloadKey}>
                  <DownloadIcon />
                  Download public key
                </Button>
                <TooltipIconButton
                  title="What's this?"
                  onClick={this.handleExplainPublicKey}
                  className="Organization-header-explainPublicKeyButton"
                >
                  <HelpIcon />
                </TooltipIconButton>
              </div>
            )} */}
            <HelpTooltip
              open={edgeConfigs.length === 0 && !isShowingDeveloperIntro}
              tooltipContent={
                <>
                  <Typography variant="subtitle1">
                    You currently have no app configurations!
                  </Typography>
                  <Typography>
                    Click the "Add a new app" button to create a new
                    configuration.
                  </Typography>
                </>
              }
            >
              <Button
                variant="contained"
                color="primary"
                onClick={openCreateNewAppDialog}
              >
                <AddIcon />
                Add a new app
              </Button>
            </HelpTooltip>
          </div>
          {isShowingDeveloperIntro && <DeveloperIntroContainer />}
          {isCreatingIntroSandbox ? (
            <div className="intro-sandbox-loading">
              <Loading message="Creating initial sandbox app configuration..." />
            </div>
          ) : (
            edgeConfigs.map((edgeConfig) => (
              <div key={edgeConfig.name} className="Organization-mapping">
                <MappingContainer edgeConfig={edgeConfig} />
              </div>
            ))
          )}
          <div className="Organization-whitespace" />
          <Dialog open={isShowingPublicKeyExplanation}>
            <DialogTitle>Public Key</DialogTitle>
            <DialogContent>
              <PublicKeyExplanation />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={this.handleClosePublicKeyExplanation}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <CreateAppContainer organization={organization} />
        </div>
      );
    } else if (isFetchingOrganization || !didFetchEdgeConfig) {
      return <Loading message="Fetching configuration..." />;
    } else {
      return <NotFoundContainer />;
    }
  }

  private handleClosePublicKeyExplanation = () => {
    this.setState({
      isShowingPublicKeyExplanation: false,
    });
  };

  private handleClickMsk = () => {
    gtm.clickVpcPeeringButton();
    window.open("https://tenefit.com/vpc-peering-request/");
  };
}

const mapStateToProps = (state: Types.RootState, ownProps: OwnProps) => {
  let didFetchEdgeConfig = false;
  if (
    state.organizations.byName &&
    state.organizations.byName[ownProps.orgName] &&
    state.organizations.byName[ownProps.orgName].edgeConfigs
  ) {
    didFetchEdgeConfig =
      state.organizations.byName[ownProps.orgName].edgeConfigs
        .didFetchEdgeConfig;
  }
  const organization = organizationsSelectors.getOrganization(
    state.organizations,
    ownProps.orgName
  );
  return {
    organization,
    isFetchingOrganization: state.organizations.isFetchingOrganization,
    didFetchEdgeConfig,
    publicKey: keysSelectors.getKeys(state.keys, ownProps.orgName)[0],
    isShowingDeveloperIntro: state.userPrefs.isShowingDeveloperIntro,
    isCreatingIntroSandbox: state.addNewApp.isCreatingIntroSandbox,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  fetchOrganization: (name: string) =>
    dispatch(organizationsActions.fetchOrganization(name)),
  streamEdgeConfigs: (name: string) =>
    dispatch(edgeConfigsThunks.streamEdgeConfigs(name)),
  testEdgeConfig: (configName: string, orgName: string) =>
    dispatch(edgeConfigsThunks.testConnection(configName, orgName)),
  openCreateNewAppDialog: () => {
    gtm.addNewAppDialogOpen();
    dispatch(addNewAppActions.openCreateNewAppDialog());
  },
  downloadKey: (key: keysModels.Key) =>
    dispatch(keysActions.downloadKey(key.organization, key.name)),
  hideDeveloperIntro: () => dispatch(userPrefsActions.hideDeveloperIntro()),
  showDeveloperIntro: () => dispatch(userPrefsActions.showDeveloperIntro()),
  handleExpandDeveloperIntroStep: (step: string | null) =>
    dispatch(userPrefsActions.expandDeveloperIntroStep(step)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationContainer);
