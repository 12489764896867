import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { SelectProps } from "@material-ui/core/Select";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React from "react";

export type KafkaTopicSelectProps = {
  usedTopics: string[];
  unusedTopics: string[];
  allowEmpty?: boolean;
  helperText?: string;
  topicRef?: (instance: HTMLElement) => void;
} & SelectProps;

const styles = (theme: Theme) => ({
  select: {
    flexGrow: 1,
  },
});

const KafkaTopicSelect = ({
  unusedTopics,
  usedTopics,
  allowEmpty = false,
  helperText,
  topicRef,
  classes,
  ...props
}: KafkaTopicSelectProps & WithStyles<typeof styles>) => (
  <FormControl className={classes.select}>
    <Select {...props} ref={topicRef}>
      {allowEmpty && <MenuItem value="" />}
      <MenuItem disabled={true}>Topics without endpoints</MenuItem>
      {unusedTopics.map((topic) => (
        <MenuItem key={topic} value={topic}>
          {topic}
        </MenuItem>
      ))}
      <MenuItem disabled={true}>Topics with endpoints</MenuItem>
      {usedTopics.map((topic) => (
        <MenuItem key={topic} value={topic}>
          {topic}
        </MenuItem>
      ))}
    </Select>
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
);

export default withStyles(styles)(KafkaTopicSelect);
