import Uri from "jsuri";

import { RouteType } from "../features/edgeConfigs/models";

export type LocationScheme =
  | "https"
  | "mqtt+tls"
  | "mqtt+wss"
  | "amqp+tls"
  | "amqp+wss"
  | "kafka+tls";

type LocationParts = {
  scheme: LocationScheme;
  domain: string;
  port: string | undefined;
  path: string;
};

/**
 * Create a short unique identifier
 *
 * @param length The number of characters in the UID that should be generated
 */
export const generateUid = (length = 4) =>
  Math.random().toString(36).substr(2, length);

/**
 * Break a fully schemed address into its constituent parts
 *
 * @param location fully schemed address, e.g. `mqtt+tls://30verjor.streaming-staging.tenefit.cloud:1883/sensors`
 *
 * @returns an object with `scheme`, `domain`, `port`, and `path`.
 *
 * Example:
 *
 * ```
 * const {scheme, domain, port, path} =
 *   locationSplitter(mqtt+tls://30verjor.streaming-staging.tenefit.cloud/sensors);
 * ```
 */
export const locationSplitter = (location: string): LocationParts => {
  const uri = new Uri(location);
  return {
    scheme: uri.protocol() as LocationScheme,
    domain: uri.host(),
    port: uri.port().toString(10),
    path: uri.path(),
  };
};

export const isMskAddress = (kafkaAddress: string): boolean => {
  const { domain, port } = locationSplitter(kafkaAddress);
  return /^b-\d*\..*\.amazonaws\.com$/.test(domain) && port === "9094";
};

/**
 * Removes key and header tokens on a path path pattern so two paths can be compared for equality.
 * Key and header tokens are replaced with the same string.
 * Ensures there is a leading slash
 * Removes the trailing slash.
 *
 * Example:
 *
 *     /events/:eventId/markets/:marketId/
 *
 * becomes:
 *
 *     /events/.../markets/...
 */
export const detokenizePath = (routePattern: string): string => {
  return (
    (routePattern.startsWith("/") ? `^${routePattern}` : `^/${routePattern}`)
      .trim()
      .replace(/[:$].*?((?=\/)|$)/g, "[^/]+")
      .replace(/\/$/, "")
      .replace(/\//g, "\\/") + "$"
  );
};

export const routeTypeToNiceName = (routeType: RouteType) => {
  switch (routeType) {
    case RouteType.sse:
      return "SSE";
    case RouteType.rest:
      return "REST";
    case RouteType.mqttOverTls:
      return "MQTT over TLS";
    case RouteType.mqttOverWss:
      return "MQTT over WSS";
    case RouteType.amqpOverTls:
      return "AMQP over TLS";
    case RouteType.amqpOverWss:
      return "AMQP over WSS";
    default:
      return "Kafka";
  }
};

export const routeTypeToScheme = (routeType: RouteType) => {
  switch (routeType) {
    case RouteType.sse:
      return "https://";
    case RouteType.rest:
      return "https://";
    case RouteType.mqttOverTls:
      return "mqtt+tls://";
    case RouteType.mqttOverWss:
      return "mqtt+wss://";
    case RouteType.amqpOverTls:
      return "amqp+tls://";
    case RouteType.amqpOverWss:
      return "amqp+wss://";
    default:
      return "kafka+tls://";
  }
};

/**
 * Show a sanitized version of the scheme in the GUI.
 */
export const routeTypeToSchemeGui = (routeType: RouteType) => {
  switch (routeType) {
    case RouteType.sse:
      return "https://";
    case RouteType.rest:
      return "https://";
    case RouteType.mqttOverTls:
      return "mqtts://";
    case RouteType.mqttOverWss:
      return "wss://";
    case RouteType.amqpOverTls:
      return "amqps://";
    case RouteType.amqpOverWss:
      return "wss://";
    default:
      return "kafkas://";
  }
};

export const routeTypeToAnnotation = (routeType: RouteType) => {
  switch (routeType) {
    case RouteType.sse:
      return "SSE";
    case RouteType.rest:
      return "REST";
    case RouteType.mqttOverTls:
      return "MQTT";
    case RouteType.mqttOverWss:
      return "MQTT";
    case RouteType.amqpOverTls:
      return "AMQP";
    case RouteType.amqpOverWss:
      return "AMQP";
    default:
      return "Kafka";
  }
};
