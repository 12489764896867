import {
  makeStyles,
  Paper,
  Popper,
  PopperPlacementType,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import React, { useState } from "react";

import TooltipIconButton from "./TooltipIconButton";

type HintArrowProps = {
  children: JSX.Element;
  anchor: HTMLElement | null;
  isOpen: boolean;
  placement?: PopperPlacementType;
  bgColor?: string;
  margin?: number | string;
  arrowOffset?: number;
  handleClose: () => void;
};

const defaultBgColor = "#FFB400";

const getBgColor = (color: string | undefined): string => {
  return color ? color : defaultBgColor;
};

const paperStyles = makeStyles(
  {
    root: {
      backgroundColor: (props: HintArrowProps) => getBgColor(props.bgColor),
      display: "flex",
    },
  },
  { name: "hintArrowPaper" }
);

/**
 *
 * A hint arrow that points to a DOM element and displays some content until it is dimissed.
 *
 * The children of the HintArrow element will be the content of the hint arrow.
 *
 * props:
 *
 * `anchor: React.RefObject<HTMLElement>;` // The DOM element that the arrow will point to
 *
 * `isOpen: boolean;` // Whether to display the hint arrow or not
 *
 * `bgColor?: string;` // The color of the hint arrow background. Defaults to a beautiful classic color
 *
 * `margin?: number | string;` // The gap between the hint arrow and the anchor. Default: 0
 *
 * `arrowOffset?: number;` // The number of pixels to offset the arrow. Defaults to the center
 *
 * `handleClose: () => void;` // Receive a callback when the hint arrow is dismissed
 *
 */
const HintArrow: React.FC<HintArrowProps> = (props) => {
  const useStyles = makeStyles(
    (theme) => ({
      popper: {
        zIndex: 1,
        '&[x-placement*="bottom"]': {
          marginTop: props.margin ? props.margin : 0,
        },
        '&[x-placement*="top"]': {
          marginBottom: props.margin ? props.margin : 0,
        },
        '&[x-placement*="right"]': {
          marginLeft: props.margin ? props.margin : 0,
        },
        '&[x-placement*="left"]': {
          marginRight: props.margin ? props.margin : 0,
        },
        '&[x-placement*="bottom"] $arrow': {
          top: 0,
          left: 0,
          marginTop: "-0.9em",
          width: "3em",
          height: "1em",
          "&::before": {
            borderWidth: "0 1em 1em 1em",
            borderColor: `transparent transparent ${getBgColor(
              props.bgColor
            )} transparent`,
          },
        },
        '&[x-placement*="top"] $arrow': {
          bottom: 0,
          left: 0,
          marginBottom: "-0.9em",
          width: "3em",
          height: "1em",
          "&::before": {
            borderWidth: "1em 1em 0 1em",
            borderColor: `${getBgColor(
              props.bgColor
            )} transparent transparent transparent`,
          },
        },
        '&[x-placement*="right"] $arrow': {
          left: 0,
          marginLeft: "-0.9em",
          height: "2em",
          width: "1em",
          "&::before": {
            borderWidth: "1em 1em 1em 0",
            borderColor: `transparent ${getBgColor(
              props.bgColor
            )} transparent transparent`,
          },
        },
        '&[x-placement*="left"] $arrow': {
          right: 0,
          marginRight: "-0.9em",
          height: "2em",
          width: "1em",
          "&::before": {
            borderWidth: "1em 0 1em 1em",
            borderColor: `transparent transparent transparent ${getBgColor(
              props.bgColor
            )}`,
          },
        },
      },
      arrow: {
        position: "absolute",
        fontSize: 10,
        width: "3em",
        height: "3em",
        "&::before": {
          content: '""',
          margin: "auto",
          display: "block",
          width: 0,
          height: 0,
          borderStyle: "solid",
        },
      },
      closeable: {
        cursor: "pointer",
      },
      paperChildren: {
        padding: 10,
      },
      closeButton: {
        marginTop: 7,
        marginRight: 6,
      },
      close2Button: {
        position: "absolute",
        top: -8,
        right: -8,
      },
    }),
    { name: "hintArrow" }
  );

  const classes = useStyles();
  const paperClasses = paperStyles(props);

  const {
    children,
    isOpen,
    anchor,
    arrowOffset,
    placement = "top",
    handleClose,
  } = props;

  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null);

  return (
    <>
      {anchor && (
        <Popper
          open={isOpen}
          anchorEl={anchor}
          placement={placement}
          modifiers={{
            arrow: {
              enabled: true,
              element: arrowRef,
            },
            offset: {
              enabled: arrowOffset ? true : false,
              offset: arrowOffset ? arrowOffset : 0,
            },
          }}
          className={classes.popper}
        >
          <span className={classes.arrow} ref={setArrowRef} />
          <TooltipIconButton
            title=""
            onClick={handleClose}
            size="small"
            className={classes.close2Button}
          >
            <CancelIcon fontSize="small" />
          </TooltipIconButton>

          <div className={classes.closeable} onClick={handleClose}>
            <Paper elevation={10} classes={paperClasses}>
              <div className={classes.paperChildren}>{children}</div>
            </Paper>
          </div>
        </Popper>
      )}
    </>
  );
};

export default HintArrow;
