import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Button,
  ButtonBase,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import "./Header.css";
import tenefitInsignia from "../assets/tenefit-icon.png";
import UserChip from "../components/UserChip";

const styles = {
  brandName: {
    color: "#ffffff",
    paddingLeft: 5,
    paddingRight: 100,
  },
};

class Header extends Component {
  static propTypes = {
    onClickDocumentation: PropTypes.func.isRequired,
    onClickDemos: PropTypes.func.isRequired,
    onClickTutorial: PropTypes.func.isRequired,
    user: PropTypes.shape({}),
    signOut: PropTypes.func,
  };

  menuAnchorRef = React.createRef();
  state = { userMenuAnchorEl: null };

  showMenu = (target) => this.setState({ userMenuAnchorEl: target });
  hideMenu = () => this.setState({ userMenuAnchorEl: null });

  handleSignOut = () => {
    this.hideMenu();
    this.props.signOut();
  };

  render() {
    const {
      user,
      onClickDocumentation,
      onClickDemos,
      onClickTutorial,
      classes,
    } = this.props;
    const { userMenuAnchorEl } = this.state;
    return (
      <AppBar position="static">
        <Toolbar>
          <ButtonBase
            color="inherit"
            aria-label="Home"
            component={Link}
            to="/"
            className="Header-homeButton"
          >
            <img src={tenefitInsignia} alt="logo" />
            <Typography
              variant="button"
              className={`Header-brandName ${classes.brandName}`}
              align="left"
            >
              TENEFIT.CLOUD CONSOLE
            </Typography>
          </ButtonBase>
          <Button color="inherit" onClick={onClickDocumentation}>
            Documentation
          </Button>
          <Button color="inherit" onClick={onClickDemos}>
            Demos
          </Button>
          <Button color="inherit" onClick={onClickTutorial}>
            Tutorials
          </Button>
          <div className="Header-spacing" />
          {user && (
            <UserChip
              name={user.email}
              onClick={(event) => this.showMenu(event.target)}
            />
          )}
          <div ref={this.menuAnchorRef} className="Header-menuAnchor" />
          <Menu
            open={!!userMenuAnchorEl}
            anchorEl={this.menuAnchorRef.current}
            onClose={this.hideMenu}
            anchorOrigin={{
              vertical: -30,
              horizontal: "left",
            }}
            getContentAnchorEl={null}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem key="signOut" onClick={this.handleSignOut}>
              Sign Out
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(Header);
