import {
  Button,
  FormControl,
  IconButton,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { PureComponent } from "react";

import "./BrowseFile.css";

export type BrowseFileProps = {
  labelText: string;
  className?: string;
  handleUpdate: (result: File | null) => void;
};

type BrowseFileState = {
  file: File | null;
};

const styles = (theme: Theme) => ({
  buttonLeftIcon: {
    marginRight: theme.spacing(1),
  },
  buttonIconSmall: {
    fontSize: 20,
  },
  filename: {
    top: -8,
  },
});

class BrowseFile extends PureComponent<
  BrowseFileProps & WithStyles<typeof styles>,
  BrowseFileState
> {
  public state: BrowseFileState = {
    file: null,
  };
  public render() {
    const { labelText, className, classes } = this.props;
    return (
      <FormControl>
        <div className={`BrowseFile ${className}`}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            component="label"
          >
            {labelText}
            <input
              onChange={this.handleSelectFile}
              style={{ display: "none" }}
              type="file"
              value=""
            />
          </Button>
          {this.state.file && (
            <React.Fragment>
              <Tooltip
                title="Remove this file from the configuration"
                placement="top"
                disableFocusListener={true}
              >
                <IconButton onClick={this.handleRemoveFile}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Typography className={classes.filename}>
                {this.state.file.name}
              </Typography>
            </React.Fragment>
          )}
        </div>
      </FormControl>
    );
  }

  private handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file: File = event.target.files[0] as File;
      this.setState({ file });
      this.props.handleUpdate(file);
    }
  };

  private handleRemoveFile = () => {
    this.setState({ file: null });
    this.props.handleUpdate(null);
  };
}

export default withStyles(styles)(BrowseFile);
