import { UserPrefsState } from "../features/userPrefs/reducer";

const NODE_ENV = process.env.NODE_ENV;
// Passed by Jenkins when we npm run build for release
const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

const STATE_KEY = `PortalSerializedState.${
  NODE_ENV === "development" ? "dev" : REACT_APP_VERSION
}`;

const USER_PREFS_KEY = "UserPrefs";

/**
 * Loads the application state as saved in localStorage. This state can be used
 * to rehydrate the application and start where the user last left off.
 */
export function loadState<T = object>(): T | undefined {
  const { localStorage } = window;
  try {
    const serializedState = localStorage.getItem(STATE_KEY);
    if (serializedState == null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}

export function loadUserPrefs(
  username: string | null
): UserPrefsState | undefined {
  if (!username) {
    return undefined;
  }
  const { localStorage } = window;
  try {
    const serializedUserPrefs = localStorage.getItem(
      `${USER_PREFS_KEY}.${username}`
    );
    if (serializedUserPrefs == null) {
      return undefined;
    }
    const userPrefs = JSON.parse(serializedUserPrefs);
    return userPrefs[username];
  } catch (err) {
    return undefined;
  }
}

/**
 * Stores the Redux state into localStorage so that it can be used to rehydrate
 * the app on future sessions.
 *
 * Warning: This function is expensive. Use with throttling.
 */
export function saveState<T = object>(state: T) {
  try {
    const serializedState = JSON.stringify(state);
    window.localStorage.setItem(STATE_KEY, serializedState);
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.error("Error saving state:", err);
  }
}

export function saveUserPrefs(
  username: string | null,
  userPrefs: UserPrefsState
) {
  if (!username) {
    return;
  }
  try {
    const serializedUserPrefs = JSON.stringify({ [username]: userPrefs });
    window.localStorage.setItem(
      `${USER_PREFS_KEY}.${username}`,
      serializedUserPrefs
    );
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.error("Error saving state:", err);
  }
}
