const STAGING_USERPOOL_ID = "us-east-1_iRRxwdzGj";
const PROD_USERPOOL_ID = "us-east-1_paKD5DLL5";

const STAGING_CLIENT_ID = "7o12gq9c5e9al55ci225tvpmvr";
const PROD_CLIENT_ID = "67qj0fl8ipepmvc5q72m4u63v";

const DEV_API_URL = "https://console-staging.tenefit.cloud/api";

const isDev = window.location.hostname === "localhost";
const isStaging = window.location.hostname.indexOf("staging") > -1;
export const isProd = !isDev && !isStaging;

export const userPoolId =
  isDev || isStaging ? STAGING_USERPOOL_ID : PROD_USERPOOL_ID;

export const clientId = isDev || isStaging ? STAGING_CLIENT_ID : PROD_CLIENT_ID;

export const apiUrl = isDev
  ? DEV_API_URL
  : new URL("/api", window.location.href);

export const redirectUrl = window.location;
