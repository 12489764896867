import { domainsSelectors, DomainsState } from "../domains";
import { Organization } from "./models";
import { OrganizationsState } from "./reducer";

export const getOrganization = (state: OrganizationsState, name: string) =>
  state.byName[name];

export const getEdgeConfigs = (organization: Organization) =>
  Object.keys(organization.edgeConfigs.byName)
    .map((key) => organization.edgeConfigs.byName[key])
    .filter(
      (edgeConfig) =>
        edgeConfig.name !== organization.edgeConfigs.temporaryEdgeConfigName
    );

export const getEdgeConfig = (organization: Organization, name: string) =>
  organization.edgeConfigs.byName[name];

export const getUnusedDomainNames = (
  organization: Organization,
  domains: DomainsState
) => {
  const orgDomains = domainsSelectors.getDomains(domains, organization.name);
  const usedDomainNames = ([] as string[]).concat(
    ...getEdgeConfigs(organization).map((edgeConfig) =>
      edgeConfig.config.mapping.map((mapping) =>
        mapping.location.replace(/^https:\/\//, "").replace(/:443(\/)?$/, "")
      )
    )
  );
  return orgDomains.filter(
    (domain) => usedDomainNames.indexOf(domain.domainName) === -1
  );
};
