import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Types from "Types";

import KafkaTopicTableSandbox, {
  KafkaTopicTableSandboxProps,
} from "../components/KafkaTopicTableSandbox";

const KafkaTopicTableSandboxContainer = (
  props: KafkaTopicTableSandboxProps
) => {
  return <KafkaTopicTableSandbox {...props} />;
};

const mapStateToProps = (state: Types.RootState) => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KafkaTopicTableSandboxContainer);
