import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import React from "react";

export type TooltipIconButtonProps = {
  title: Exclude<React.ReactNode, null | undefined>;
  disabled?: boolean;
  children: JSX.Element;
  className?: string;
  size?: "small" | "medium";
  open?: boolean | undefined;
  arrow?: boolean;
  margin?: number;
  onClick: () => void;
};

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    margin: (props: TooltipIconButtonProps) =>
      props.margin ? props.margin : undefined,
  },
}));

const TooltipIconButton = (props: TooltipIconButtonProps) => {
  const {
    title,
    disabled = false,
    children,
    className = "",
    size = "medium",
    open,
    arrow = false,
    onClick,
  } = props;
  const tooltipClasses = useTooltipStyles(props);
  return (
    <Tooltip
      placement="top"
      title={title}
      disableFocusListener={true}
      open={open}
      arrow={arrow}
      classes={tooltipClasses}
    >
      <span>
        <IconButton
          disabled={disabled}
          onClick={onClick}
          className={className}
          size={size}
        >
          {children}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default TooltipIconButton;
