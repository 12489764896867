import { combineEpics } from "redux-observable";

import app from "../features/app/epics";
import domains from "../features/domains/epics";
import edgeConfigs from "../features/edgeConfigs/epics";
import keys from "../features/keys/epics";
import organizations from "../features/organizations/epics";
import userPrefs from "../features/userPrefs/epics";

export default combineEpics(
  app,
  domains,
  edgeConfigs,
  keys,
  organizations,
  userPrefs
);
