import { Typography } from "@material-ui/core";
import React from "react";

import Link from "./auth/Link";

function open2WayAuthenticationDoc() {
  window.open(
    "https://docs.confluent.io/current/kafka/authentication_ssl.html"
  );
}

function openConfigureTruststoreDoc() {
  window.open("https://nten.io/tenefit.cloud/doc/2-way-authentication");
}

const PublicKeyExplanation = () => (
  <Typography align="left">
    If you have configured your kafka broker to use{" "}
    <Link onClick={open2WayAuthenticationDoc} className="DirectDetails-link">
      2-way authentication
    </Link>
    , you need to add your tenefit.cloud public key to the broker's truststore.
    Do so by downloading the public key and following{" "}
    <Link onClick={openConfigureTruststoreDoc} className="DirectDetails-link">
      these instructions
    </Link>
    . If you did not configure your kafka broker to use 2-way authentication,
    then your broker will accept all ssl connections and you do not need to
    download the public key.
  </Typography>
);

export default PublicKeyExplanation;
