import React from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

import "./UserChip.css";

/**
 * Takes a first name or a first and last name and returns an abbreviation
 * to use in the user avatar badge.
 * Returns "" if the name is empty.
 */
const abbreviateName = (name) => name.split(" ").map(firstUpper).join("");

/**
 * Returns the first letter of a string in uppercase.
 * "" if the string is empty.
 */
const firstUpper = (name) => (name.length > 0 ? name[0].toUpperCase() : "");

const UserChip = ({ name, onClick }) => (
  <div className="UserChip" onClick={onClick}>
    <Typography variant="body2" color="inherit" className="UserChip-name">
      {name}
    </Typography>
    <Avatar>{abbreviateName(name)}</Avatar>
  </div>
);

UserChip.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default UserChip;
