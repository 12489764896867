import {
  EdgeConfigConfig,
  Mapping,
  Route,
  RouteType,
} from "../../features/edgeConfigs/models";
import {
  LocationScheme,
  locationSplitter,
  routeTypeToScheme,
} from "../../utils/utils";
import { EdgeConfigResponse } from "../api";

type ServerRouteType = "sse" | "rest";

type RestMethods = "GET" | "PUT" | "POST" | "DELETE";

type BrokerAddress = {
  host: string;
  port: number;
};

export type RouteServer = {
  type?: ServerRouteType;
  pathPattern?: string;
  topicPattern?: string;
  addressPattern?: string;
  topicName?: string;
  methods?: RestMethods[];
  replyTo?: string;
  external?: BrokerAddress;
  internal?: BrokerAddress;
};

type MappingServer = {
  location: string;
  store: string;
  routes: RouteServer[];
};

type EdgeConfigConfigServer = {
  kafka: string;
  store: string | null;
  mapping: MappingServer[];
};

const removeDoubleSlashes = (str: string) => {
  return str.replace(/\/\/+/g, "/");
};

const getRouteType = (
  scheme: LocationScheme,
  type?: ServerRouteType
): RouteType => {
  switch (scheme) {
    case "https":
      return type && type === "rest" ? RouteType.rest : RouteType.sse;
    case "mqtt+tls":
      return RouteType.mqttOverTls;
    case "mqtt+wss":
      return RouteType.mqttOverWss;
    case "amqp+tls":
      return RouteType.amqpOverTls;
    case "amqp+wss":
      return RouteType.amqpOverWss;
    default:
      return RouteType.kafka;
  }
};

const clientToServerTransformRoute = (route: Route): RouteServer => {
  const isHttpRoute =
    route.routeType === RouteType.sse || route.routeType === RouteType.rest;
  const isMqttRoute =
    route.routeType === RouteType.mqttOverTls ||
    route.routeType === RouteType.mqttOverWss;
  const isAmqpRoute =
    route.routeType === RouteType.amqpOverTls ||
    route.routeType === RouteType.amqpOverWss;
  const isKafkaRoute = route.routeType === RouteType.kafka;
  return {
    ...route,
    topicName: !isKafkaRoute ? route.topicName : undefined,
    type: route.routeType === RouteType.rest ? route.routeType : undefined,
    pathPattern: isHttpRoute ? route.routePattern : undefined,
    topicPattern: isMqttRoute ? route.routePattern : undefined,
    addressPattern: isAmqpRoute ? route.routePattern : undefined,
    methods:
      route.routeType === RouteType.rest
        ? ["GET", "POST", "PUT", "DELETE"]
        : undefined,
    uid: undefined,
    routeType: undefined,
    routePattern: undefined,
    routePatternExpression: undefined,
    external: isKafkaRoute ? { host: "", port: 9093 } : undefined,
    internal: isKafkaRoute ? { host: "", port: 9094 } : undefined,
  } as RouteServer;
};

const clientToServerTransformMappings = (
  config: EdgeConfigConfig
): MappingServer[] => {
  const mappings = config.mapping as ReadonlyArray<Mapping>;
  const mappingsServer = [] as MappingServer[];
  mappings.forEach((mapping) => {
    mapping.routes.forEach((route) => {
      const consoleHost = new URL(mapping.location).host;
      let newLocation = `${routeTypeToScheme(route.routeType)}${consoleHost}/`;
      const routeServer = clientToServerTransformRoute(route);
      if (
        route.routeType === RouteType.sse &&
        route.routePattern.startsWith("/sse/")
      ) {
        newLocation = `${newLocation}sse/`;
        routeServer.pathPattern = routeServer.pathPattern!.replace("/sse", "");
      }
      if (
        route.routeType === RouteType.rest &&
        route.routePattern.startsWith("/rest/")
      ) {
        newLocation = `${newLocation}rest/`;
        routeServer.pathPattern = routeServer.pathPattern!.replace("/rest", "");
      }
      if (route.routeType === RouteType.kafka) {
        const { domain } = locationSplitter(config.kafka);
        const internalHost = "b-#" + domain.slice(domain.indexOf("."));
        routeServer.external!.host = `${consoleHost}:10001+`;
        routeServer.internal!.host = internalHost;
      }
      mappingsServer.push({
        ...mapping,
        location: newLocation,
        routes: [routeServer],
      });
    });
    // Mappings that have no routes need to handled explicity since they
    // will fall through the previous loop without being processed.
    if (mapping.routes.length === 0) {
      const { scheme, domain, path } = locationSplitter(mapping.location);
      mappingsServer.push({
        ...mapping,
        location: `${scheme}://${domain}${path}`,
        routes: [],
      });
    }
  });
  return mappingsServer;
};

/**
 * Convert the data model from that used by the client application to the data model used by the server.
 *
 * @param config the configuration conforming with the server's data model, as a string for transporting over the wire
 */
export const clientToServer = (config: EdgeConfigConfig): string => {
  const serverConfig: EdgeConfigConfigServer = {
    ...config,
    mapping: clientToServerTransformMappings(config),
  };
  Reflect.deleteProperty(serverConfig, "isMsk"); // Local property, not for the server
  return JSON.stringify(serverConfig, null, 2);
};

const serverToClientTransformRoute = (
  route: RouteServer & { type?: ServerRouteType },
  scheme: LocationScheme,
  locationSuffix: string
): Route => {
  const routeType = getRouteType(scheme, route.type);

  let routePattern: string;
  if (routeType === RouteType.sse || routeType === RouteType.rest) {
    routePattern = route.pathPattern!;
  } else if (
    routeType === RouteType.mqttOverTls ||
    routeType === RouteType.mqttOverWss
  ) {
    routePattern = route.topicPattern!;
  } else if (
    routeType === RouteType.amqpOverTls ||
    routeType === RouteType.amqpOverWss
  ) {
    routePattern = route.addressPattern!;
  } else {
    // RouteType.kafka
    routePattern = "";
  }

  return {
    ...route,
    routeType,
    routePattern:
      locationSuffix === "/"
        ? routePattern
        : removeDoubleSlashes(`${locationSuffix}${routePattern}`),
    pathPattern: undefined,
    topicPattern: undefined,
    addressPattern: undefined,
    type: undefined,
    methods: undefined,
    external: undefined,
    internal: undefined,
  } as Route;
};

const serverToClientTransformMappings = (
  mappings: ReadonlyArray<MappingServer>
): ReadonlyArray<Mapping> => {
  const newMappings = {} as { [domain: string]: Mapping };
  const newRoutes = {} as { [domain: string]: Route[] };
  mappings.forEach((mapping) => {
    const { scheme, domain, path } = locationSplitter(mapping.location);
    if (!Object.prototype.hasOwnProperty.call(newRoutes, domain)) {
      newRoutes[domain] = [];
    }
    if (mapping.routes.length > 0) {
      mapping.routes.forEach((route) => {
        newRoutes[domain].push(
          serverToClientTransformRoute(route, scheme as LocationScheme, path)
        );
      });
    } else {
      const routeType = getRouteType(scheme);
      if (routeType === RouteType.kafka) {
        newRoutes[domain].push({ routeType, routePattern: "" });
      }
    }
  });
  mappings.forEach((mapping) => {
    const { domain } = locationSplitter(mapping.location);
    if (!Object.prototype.hasOwnProperty.call(newMappings, domain)) {
      newMappings[domain] = {
        ...mapping,
        location: `https://${domain}:443/`,
        routes: newRoutes[domain],
      };
    }
  });
  return Object.keys(newMappings).map((domain) => ({
    ...newMappings[domain],
  }));
};

const serverToClientTransformConfig = (
  config: EdgeConfigConfigServer
): string =>
  JSON.stringify({
    ...config,
    mapping: serverToClientTransformMappings(config.mapping),
  });

/**
 * Convert the data model from that used by the server to the data model used by the client application.
 *
 * @param edgeConfigs the edge configuration data received from the server
 */
export const serverToClient = (
  edgeConfigs: EdgeConfigResponse[]
): EdgeConfigResponse[] =>
  edgeConfigs.map((edgeConfig) => ({
    ...edgeConfig,
    config: serverToClientTransformConfig(JSON.parse(edgeConfig.config)),
  }));
