import { Button, Paper, Typography } from "@material-ui/core";
import React from "react";

import "./ReachabilityItem.css";

export type ReachabilityItemProps = {
  title: string;
  description: string;
  value: any;
  buttonLabel?: string;
  disabled?: boolean;
  selected?: boolean;
  extra?: JSX.Element;
  onSelect?: () => void;
};

const ReachabilityItem = ({
  title,
  description,
  buttonLabel,
  disabled = false,
  selected = false,
  extra,
  onSelect = () => {},
}: ReachabilityItemProps) => (
  <Paper
    className={`ReachabilityItem ${selected && "selected"} ${
      disabled && "disabled"
    }`}
  >
    <div className="ReachabilityItem-mainContent">
      <div className="ReachabilityItem-title">
        <Typography>{title}</Typography>
      </div>
      <div className="ReachabilityItem-description">
        <Typography
          className={`ReachabilityItem-description ${disabled && "disabled"}`}
        >
          {description}
        </Typography>
      </div>
      <div className="ReachabilityItem-button">
        <Button
          variant="contained"
          onClick={onSelect}
          color={selected ? "primary" : "primary"}
          disabled={selected || disabled}
          className="ReachabilityItem-button-select"
        >
          {buttonLabel || (selected ? "Selected" : "Select")}
        </Button>
      </div>
    </div>
    {extra && extra}
  </Paper>
);

export default ReachabilityItem;
