import { CircularProgress, Popover, Typography } from "@material-ui/core";
import React, { PureComponent } from "react";

import { edgeConfigsModels } from "../features/edgeConfigs";
import "./EdgeState.css";

const INIT_TIMER_MAX_SECONDS = 120;
const RESTART_TIMER_MAX_SECONDS = 45;

export type EdgeStateProps = {
  edgeConfig: edgeConfigsModels.EdgeConfig;
};

export type EdgeStateState = {
  timeRemaining: number;
  popoverAnchorEl: HTMLElement | null;
};

export default class EdgeState extends PureComponent<
  EdgeStateProps,
  EdgeStateState
> {
  public state = { timeRemaining: 0, popoverAnchorEl: null };
  private updateTimeRemainingInterval: NodeJS.Timer | null = null;

  public componentDidMount() {
    this.updateTimeRemainingInterval = setInterval(
      this.updateTimeRemaining,
      1000
    );
  }

  public componentWillUnmount() {
    if (this.updateTimeRemainingInterval) {
      clearInterval(this.updateTimeRemainingInterval);
    }
  }

  public render() {
    const { edgeState, edgeConfigState } = this.props.edgeConfig;
    if (edgeState === "ERROR") {
      return this.error();
    } else if (
      edgeState === "INIT" ||
      edgeState === "RESTARTING" ||
      edgeConfigState === "NEW"
    ) {
      return this.pending();
    } else {
      return this.deployed();
    }
  }

  private updateTimeRemaining = () => {
    const { edgeState, lastModified } = this.props.edgeConfig;
    const secondsElapsedSinceSave = Math.floor(
      (Number(new Date()) - Number(lastModified)) / 1000
    );
    if (edgeState === "INIT") {
      this.setState({
        timeRemaining: Math.max(
          0,
          INIT_TIMER_MAX_SECONDS - secondsElapsedSinceSave
        ),
      });
    } else if (edgeState === "RESTARTING") {
      this.setState({
        timeRemaining: Math.max(
          0,
          RESTART_TIMER_MAX_SECONDS - secondsElapsedSinceSave
        ),
      });
    } else {
      this.setState({ timeRemaining: 0 });
    }
  };

  private error() {
    return (
      <div className="EdgeState-container">
        <div className="EdgeState-error" />
        <Typography className="EdgeState-error-label">
          Instance shutdown. Waiting to restart...
        </Typography>
      </div>
    );
  }

  private pending() {
    const { edgeState } = this.props.edgeConfig;
    const { timeRemaining, popoverAnchorEl } = this.state;
    const open = Boolean(popoverAnchorEl);
    return (
      <div className="EdgeState-container">
        <CircularProgress size={16} />
        <Typography className="EdgeState-new-label">
          {edgeState === "INIT" ? "Starting service" : "Updating service"}
        </Typography>
        {!!timeRemaining && (
          <Typography variant="caption" className="EdgeState-new-label-time">
            {timeRemaining}s
          </Typography>
        )}
        <Typography className="EdgeState-pending-hint">
          (You can continue making changes&nbsp;
          <span
            className="EdgeState-pending-moreInfo"
            onClick={this.handleClickPopover}
          >
            More info
          </span>
          &nbsp;)
        </Typography>
        <Popover
          open={open}
          anchorEl={popoverAnchorEl}
          onClose={this.handleClosePopover}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Typography className="EdgeState-pending-popover">
            You can make changes to the configuration while an update is
            occurring.
            <br />
            Previous changes in progress won't be lost.
          </Typography>
        </Popover>
      </div>
    );
  }

  private deployed() {
    return (
      <div className="EdgeState-container">
        <div className="EdgeState-deployed" />
        <Typography className="EdgeState-deployed-label">Live</Typography>
      </div>
    );
  }

  private handleClickPopover = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ popoverAnchorEl: event.currentTarget });
  };

  private handleClosePopover = () => {
    this.setState({ popoverAnchorEl: null });
  };
}
