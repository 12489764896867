import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import * as partitionedTable from "../../utils/partitionedTable";
import * as actions from "./actions";
import { FETCH_DOMAINS_SUCCESS, FETCH_USAGE_SUCCESS } from "./constants";
import { Domain } from "./models";

export type DomainsAction = ActionType<typeof actions>;

export type DomainsState = {
  entries: partitionedTable.PartitionedTable<Domain>;
};

export default combineReducers<DomainsState, DomainsAction>({
  entries: (state = {}, action: DomainsAction) => {
    switch (action.type) {
      case FETCH_DOMAINS_SUCCESS:
        return action.payload.reduce((state, domain) => {
          if (
            partitionedTable.read(state, domain.organization, domain.domainName)
          ) {
            return state;
          } else {
            return partitionedTable.create(
              state,
              domain.organization,
              domain.domainName,
              {
                ...domain,
                usage: {},
              }
            );
          }
        }, state);
      case FETCH_USAGE_SUCCESS:
        return partitionedTable.update(
          state,
          action.payload.orgName,
          action.payload.domainName,
          { usage: action.payload.usage }
        );
      default:
        return state;
    }
  },
});
