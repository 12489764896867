import { createSelector } from "reselect";

import { MessageType } from "./models";
import { AddNewAppState } from "./reducer";

export const getIsCreateDialogOpen = (state: AddNewAppState) =>
  state.isDialogOpen;

export const getMessages = (state: AddNewAppState) => state.messages;

export const getIsCreating = createSelector(getMessages, (messages) =>
  messages.some((m) => m.type === MessageType.Progress)
);

export const getIsDone = createSelector(
  getMessages,
  (messages) =>
    messages.length > 0 && messages.every((m) => m.type === MessageType.Success)
);
