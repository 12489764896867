export const OPEN_CREATE_NEW_APP_DIALOG = "OPEN_CREATE_NEW_APP_DIALOG";
export const CLOSE_CREATE_NEW_APP_DIALOG = "CLOSE_CREATE_NEW_APP_DIALOG";
export const RESET_CREATE_NEW_APP_DIALOG = "RESET_CREATE_NEW_APP_DIALOG";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const START_TASK = "START_TASK";
export const COMPLETE_TASK = "COMPLETE_TASK";
export const FAIL_TASK = "FAIL_TASK";
export const CREATE_INTRO_SANDBOX_START = "CREATE_INTRO_SANDBOX_START";
export const CREATE_INTRO_SANDBOX_FINISH = "CREATE_INTRO_SANDBOX_FINISH";

export const SET_CA_CERT = "SET_CA_CERT";
export const SET_CLIENT_CERT = "SET_CLIENT_CERT";
export const SET_CLIENT_KEY = "SET_CLIENT_KEY";
