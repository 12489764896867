import { action } from "typesafe-actions";
import { ADD_USER, FETCH_SESSION_USER_SUCCESS } from "./constants";

export const addUser = (
  username: string,
  email: string,
  organizations: string[] | null = []
) => action(ADD_USER, { username, email, organizations });

export const fetchSessionUserSuccess = (username: string) =>
  action(FETCH_SESSION_USER_SUCCESS, username);
