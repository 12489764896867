import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

import ProgressButton from "./core/ProgressButton";

export type DeleteConfigDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  name: string;
  location: string;
  isDeleting: boolean;
};

const DeleteConfigDialog = ({
  open,
  onClose,
  onConfirm,
  name,
  location,
  isDeleting,
}: DeleteConfigDialogProps) => {
  const getUrl = () => {
    try {
      return new URL(location).href;
    } catch (error) {
      return null;
    }
  };
  const parsedLocation = getUrl();
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle>Delete App</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete <b>{name}</b>?
        </DialogContentText>
        <p />
        {parsedLocation && (
          <DialogContentText>
            Server-Sent Events will no longer be available at {parsedLocation}
          </DialogContentText>
        )}
        <p />
        <DialogContentText>This action cannot be undone.</DialogContentText>
        <DialogActions>
          <Button disabled={isDeleting} onClick={onClose}>
            Cancel
          </Button>
          <ProgressButton
            color="primary"
            isPending={isDeleting}
            onClick={onConfirm}
          >
            Delete
          </ProgressButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfigDialog;
