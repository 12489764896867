import {
  Theme,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import SvgIcon from "@material-ui/core/SvgIcon";
import HttpsIcon from "@material-ui/icons/Https";
import React from "react";

import dbIcon from "./db.png";

import "./Kafka.css";

const caCertSvg =
  "M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M12,7C13.4,7 14.8,8.1 14.8,9.5V11C15.4,11 16,11.6 16,12.3V15.8C16,16.4 15.4,17 14.7,17H9.2C8.6,17 8,16.4 8,15.7V12.2C8,11.6 8.6,11 9.2,11V9.5C9.2,8.1 10.6,7 12,7M12,8.2C11.2,8.2 10.5,8.7 10.5,9.5V11H13.5V9.5C13.5,8.7 12.8,8.2 12,8.2Z";
const clientCertSvg = caCertSvg;
const clientKeySvg =
  "M12,8A1,1 0 0,1 13,9A1,1 0 0,1 12,10A1,1 0 0,1 11,9A1,1 0 0,1 12,8M21,11C21,16.55 17.16,21.74 12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11M12,6A3,3 0 0,0 9,9C9,10.31 9.83,11.42 11,11.83V18H13V16H15V14H13V11.83C14.17,11.42 15,10.31 15,9A3,3 0 0,0 12,6Z";

type ProtocolProps = {
  protocol: "tcp" | "tls";
};

const styles = (theme: Theme) => ({
  chipRoot: {
    marginLeft: theme.spacing(1),
  },
  chipLabel: {
    // paddingLeft: theme.spacing(0.5),
  },
  chipAvatar: {
    backgroundColor: "inherit",
  },
  chipIcon: {
    color: "red",
  },
});

const Protocol = ({ protocol }: ProtocolProps) => {
  switch (protocol) {
    case "tcp":
      return <Typography>plaintext</Typography>;
    case "tls":
      return (
        <React.Fragment>
          <HttpsIcon color="primary" />
          <Typography>ssl</Typography>
        </React.Fragment>
      );
    default:
      return <Typography>Unknown ({protocol})</Typography>;
  }
};

export type KafkaProps = {
  address: string;
  caCertFilename: string | null;
  clientCertFilename: string | null;
  clientKeyFilename: string | null;
};

const Kafka = ({
  address,
  caCertFilename,
  clientCertFilename,
  clientKeyFilename,
  classes,
}: KafkaProps & WithStyles<typeof styles>) => {
  const [protocol, domainAndPort] = address.split("://");
  return (
    <div className="Kafka">
      <img src={dbIcon} alt="db" className="Kafka-kafkaIcon" />
      <Typography align="left" className="Kafka-label">
        Kafka: <b>{domainAndPort}</b>
      </Typography>
      <div className="Kafka-protocol">
        <Typography align="left">Protocol:</Typography>
        <Protocol protocol={protocol as "tcp" | "tls"} />
      </div>
      {caCertFilename && (
        <Tooltip
          title="Server CA certificate"
          placement="top"
          disableFocusListener={true}
        >
          <Chip
            classes={{
              root: classes.chipRoot,
              label: classes.chipLabel,
              avatar: classes.chipAvatar,
            }}
            avatar={
              <Avatar>
                <SvgIcon color="primary">
                  <path d={caCertSvg} />
                </SvgIcon>
              </Avatar>
            }
            label={caCertFilename}
          />
        </Tooltip>
      )}
      {clientCertFilename && (
        <Tooltip
          title="Client certificate"
          placement="top"
          disableFocusListener={true}
        >
          <Chip
            classes={{
              root: classes.chipRoot,
              label: classes.chipLabel,
              avatar: classes.chipAvatar,
            }}
            avatar={
              <Avatar>
                <SvgIcon color="secondary">
                  <path d={clientCertSvg} />
                </SvgIcon>
              </Avatar>
            }
            label={clientCertFilename}
          />
        </Tooltip>
      )}
      {clientKeyFilename && (
        <Tooltip title="Client key" placement="top" disableFocusListener={true}>
          <Chip
            classes={{
              root: classes.chipRoot,
              label: classes.chipLabel,
              avatar: classes.chipAvatar,
            }}
            avatar={
              <Avatar>
                <SvgIcon color="secondary">
                  <path d={clientKeySvg} />
                </SvgIcon>
              </Avatar>
            }
            label={clientKeyFilename}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default withStyles(styles)(Kafka);
