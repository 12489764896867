import { Typography } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Types from "Types";

import { edgeConfigsModels } from "../features/edgeConfigs";

type OwnProps = {};

type TestContainerProps = {
  edgeConfigs: ReadonlyArray<edgeConfigsModels.EdgeConfig>;
};

type TestContainerState = {};

/**
 * A place where code can be tested for debugging. Has access to data store and typescript.
 * To activate, add query parameter ?test=true to URL.
 */
class TestContainer extends Component<
  TestContainerProps & OwnProps,
  TestContainerState
> {
  public render() {
    // console.log(`TestContainer.render()`);
    const { edgeConfigs } = this.props;
    const orgName = edgeConfigs ? edgeConfigs[0].organization : "unknown";
    // console.log(`orgName:`, orgName);
    // console.log(`edgeConfigs:`, edgeConfigs);
    return (
      <Typography
        variant="h6"
        align="left"
        className="Organization-header-title"
      >
        You are in test mode, {orgName}
      </Typography>
    );
  }
}

const mapStateToProps = (state: Types.RootState, ownProps: OwnProps) => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TestContainer);
