import { ThunkAction } from "redux-thunk";
import Types from "Types";

import {
  DEBUG_INTERVALS,
  TENEFIT_SANDBOX_CONFIG_NAME,
} from "../../globalConstants";
import * as api from "../../services/api";
import * as organizationsSelectors from "../organizations/selectors";
import * as actions from "./actions";
import * as selectors from "./selectors";

let didFetchConfigs = false;

export const streamEdgeConfigs = (
  orgName: string
): ThunkAction<() => void, Types.RootState, void, Types.RootAction> => {
  return (dispatch, getState) => {
    dispatch(fetchEdgeConfigs(orgName));
    // To help with debugging, stop fetching once the edge configs are fetched the first time.
    // This will stop the regular polling, which makes debugging network connectivity and
    // redux in Chrome more difficult.
    const intervalId = setInterval(() => {
      didFetchConfigs =
        getState().organizations.byName[orgName] &&
        getState().organizations.byName[orgName].edgeConfigs.didFetchEdgeConfig;
      if (!DEBUG_INTERVALS || (DEBUG_INTERVALS && !didFetchConfigs)) {
        dispatch(fetchEdgeConfigs(orgName));
      }
    }, 2000);
    return () => clearInterval(intervalId);
  };
};

const fetchEdgeConfigs = (
  orgName: string
): ThunkAction<Promise<void>, Types.RootState, void, Types.RootAction> => {
  return async (dispatch, getState) => {
    const organization = organizationsSelectors.getOrganization(
      getState().organizations,
      orgName
    );
    if (!organization) {
      return;
    }
    const response = await api.getEdgeConfigs(orgName);
    // If the user signs out while the api call above is executing, by the time it returns the state
    // is no longer valid. If there is no sessionUserName, then the has logged out and there is no
    // need to continue.
    if (!getState().users.sessionUserName) {
      return;
    }
    dispatch(actions.fetchEdgeConfigSuccess(orgName));
    response.forEach((edgeConfig) => {
      const existingEdgeConfig = selectors.getEdgeConfig(
        organization.edgeConfigs,
        edgeConfig.name
      );
      if (
        !existingEdgeConfig ||
        edgeConfig.version > existingEdgeConfig.version ||
        edgeConfig.edgeState !== existingEdgeConfig.edgeState ||
        edgeConfig.edgeConfigState !== existingEdgeConfig.edgeConfigState
      ) {
        const parsedConfig = tryParseJson(edgeConfig.config);
        if (!parsedConfig) {
          // tslint:disable-next-line:no-console
          console.error(
            "Failed to parse edgeConfig from server:",
            edgeConfig.name,
            edgeConfig.config
          );
          return;
        }
        dispatch(
          actions.addEdgeConfig(
            edgeConfig.name,
            orgName,
            edgeConfig.version,
            edgeConfig.caCertFilename,
            edgeConfig.clientCertFilename,
            edgeConfig.clientKeyFilename,
            parsedConfig,
            edgeConfig.edgeState,
            edgeConfig.edgeConfigState
          )
        );
      }
      if (!existingEdgeConfig) {
        dispatch(testConnection(edgeConfig.name, edgeConfig.organization));
      }
    });
    const edgeConfigNames = response.map((edgeConfig) => edgeConfig.name);
    const temporaryEdgeConfig = selectors.getTemporaryEdgeConfig(
      organization.edgeConfigs
    );
    const existingEdgeConfigNames = selectors
      .getEdgeConfigs(organization.edgeConfigs)
      .map((edgeConfig) => edgeConfig.name)
      .filter(
        (name) => !temporaryEdgeConfig || name !== temporaryEdgeConfig.name
      );
    existingEdgeConfigNames
      .filter((name) => edgeConfigNames.indexOf(name) === -1)
      .forEach((name) => {
        dispatch(actions.remove(name, orgName));
      });
  };
};

const tryParseJson = (str: string): any => {
  let parsed: any = null;
  try {
    parsed = JSON.parse(str);
  } catch (err) {}
  return parsed;
};

export const testConnection = (
  configName: string,
  orgName: string
): ThunkAction<Promise<void>, Types.RootState, void, Types.RootAction> => {
  return async (dispatch, getState) => {
    const organization = organizationsSelectors.getOrganization(
      getState().organizations,
      orgName
    );
    const edgeConfig = selectors.getEdgeConfig(
      organization.edgeConfigs,
      configName
    );
    if (!edgeConfig) {
      throw new Error(`${configName} does not exist`);
    }
    dispatch(actions.testKafkaConnection(configName, orgName));
    try {
      const response = await api.getKafkaTopics(orgName, configName);
      if (response.connected) {
        let topics = response.topics;
        if (configName === TENEFIT_SANDBOX_CONFIG_NAME) {
          topics = topics.filter(
            (topic) =>
              topic.startsWith("sandbox.") || topic.startsWith(`${orgName}.`)
          );
        }
        // Make sure the user's topics are first in the list.
        topics.sort((a, b) => {
          if (a.startsWith(orgName) && !b.startsWith(orgName)) {
            return -1;
          } else if (!a.startsWith(orgName) && b.startsWith(orgName)) {
            return 1;
          } else {
            return a.localeCompare(b);
          }
        });
        dispatch(actions.testKafkaConnectionDone(configName, orgName, topics));
      } else {
        throw new Error("Connection failed");
      }
    } catch (error) {
      dispatch(
        actions.testKafkaConnectionDone(configName, orgName, null, error)
      );
    }
  };
};

export const saveNextVersion = (
  configName: string,
  orgName: string
): ThunkAction<Promise<void>, Types.RootState, any, Types.RootAction> => {
  return async (dispatch, getState) => {
    const organization = organizationsSelectors.getOrganization(
      getState().organizations,
      orgName
    );
    const edgeConfig = selectors.getEdgeConfig(
      organization.edgeConfigs,
      configName
    );
    if (!edgeConfig) {
      throw new Error(`${configName} does not exist`);
    }
    // If a new app is being added, then the user may have specified certificate files.
    const caCert = getState().addNewApp.caCert;
    const clientCert = getState().addNewApp.clientCert;
    const clientKey = getState().addNewApp.clientKey;
    // However if this function is being called because a config was updated, there are no
    // certificate files, but the config will contain the names of the files (or null if none are used).
    const caCertFilename = caCert ? caCert.name : edgeConfig.caCertFilename;
    const clientCertFilename = clientCert
      ? clientCert.name
      : edgeConfig.clientCertFilename;
    const clientKeyFilename = clientKey
      ? clientKey.name
      : edgeConfig.clientKeyFilename;
    try {
      const newVersion = edgeConfig.version + 1;
      dispatch(
        actions.updateConfig(
          configName,
          orgName,
          edgeConfig.config,
          newVersion,
          caCertFilename,
          clientCertFilename,
          clientKeyFilename
        )
      );
      await api.postEdgeConfig(
        orgName,
        configName,
        newVersion,
        edgeConfig.config,
        caCertFilename,
        clientCertFilename,
        clientKeyFilename
      );
      if (caCert || clientCert) {
        try {
          await api.uploadCerts(
            orgName,
            configName,
            caCert,
            clientCert,
            clientKey
          );
        } catch (error) {
          api.deleteEdgeConfig(orgName, configName);
          throw error;
        }
      }
    } catch (error) {
      dispatch(
        actions.updateConfig(
          configName,
          orgName,
          edgeConfig.config,
          edgeConfig.version,
          caCertFilename,
          clientCertFilename,
          clientKeyFilename
        )
      );
      throw error;
    }
  };
};
