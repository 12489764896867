// Show all code templates for streaming out of tenefit.cloud.
// Can be used by the developer intro and by each topic row in the app config.
// Properties:
// isIntro - If true, show supplemental text and additional details, and hide the close button

import { Tab, Tabs, Theme, Typography } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React, { ChangeEvent, PureComponent } from "react";

import { edgeConfigsModels } from "../../features/edgeConfigs";
import CodeTemplate from "../CodeTemplate";
import { introNoteHeaderStyle, introTextStyle } from "./DeveloperIntro";
import "./DeveloperIntro.css";

export type StreamTemplatesProps = {
  isIntro: boolean;
  origin: string;
  route: edgeConfigsModels.Route & { topicName: string };
  language: number;
  selectDeveloperIntroStepLanguage: (step: string, language: number) => void;
  className?: string;
};

const styles = (theme: Theme) => ({
  introNoteHeader: introNoteHeaderStyle,
  introText: introTextStyle,
});

export const languages = ["HTML5/JS", "cURL", "Python"];

class StreamTemplates extends PureComponent<
  StreamTemplatesProps & WithStyles<typeof styles>
> {
  public static defaultProps = {
    className: "",
  };

  public render() {
    const { classes, isIntro, origin, route, language, className } = this.props;
    const endpoint = `${origin}${route.routePattern}`;

    const commonNotes = (
      <React.Fragment>
        <li>
          <Typography className={classes.introText}>
            Make sure you change <code>{route.topicName}</code> to match a topic
            name that you have published to.
          </Typography>
        </li>
        <li>
          <Typography className={classes.introText}>
            Replace <code>{origin}</code> with a domain that has been allocated
            to you in one of your application configurations, further below.
          </Typography>
        </li>
      </React.Fragment>
    );

    return (
      <div className={`developer-intro-languages ${className}`}>
        <Tabs
          aria-label="Language"
          name="language"
          value={language}
          onChange={this.handleChange}
        >
          <Tab label={languages[0]} />
          <Tab label={languages[1]} />
          <Tab label={languages[2]} />
        </Tabs>
        <div className={isIntro ? "intro-tab-details" : ""}>
          {language === 0 && (
            <div>
              {isIntro && (
                <React.Fragment>
                  <Typography className={classes.introText}>
                    For an HTML5 Javascript application, such as one running in
                    a browser, the following example is a simple page that
                    streams data from tenefit.cloud.
                  </Typography>
                  <Typography className={classes.introText}>
                    Simply save the following code a file to a file, such as{" "}
                    <code>index.html</code>, and open it in a browser:
                  </Typography>
                </React.Fragment>
              )}
              <CodeTemplate
                className={isIntro ? "developer-intro-code" : ""}
                language="html"
                canSave={!isIntro}
                saveFilename={
                  !isIntro ? `template-${route.topicName}.html` : undefined
                }
                code={`<!DOCTYPE html>
<html lang="en">
  <head>
    <!-- Polyfill for older browsers without native support for the HTML5 EventSource API. -->
    <script src="https://cdn.polyfill.io/v2/polyfill.min.js?features=EventSource"></script>
  </head>
  <body>
    <div id="payload"></div>
    <script>
      const stream = new EventSource('${endpoint}');
      const handler = function(event) {
        document.getElementById('payload').innerText = event.data;
      };
      stream.addEventListener('message', handler, false);
    </script>
  </body>
</html>`}
              />
              {isIntro && (
                <div className="note">
                  <Typography className={classes.introNoteHeader}>
                    Note
                  </Typography>
                  <ul>{commonNotes}</ul>
                </div>
              )}
            </div>
          )}
          {language === 1 && (
            <div>
              {isIntro && (
                <Typography className={classes.introText}>
                  You can easily stream data to a terminal using the{" "}
                  <code>curl</code>
                  command.
                </Typography>
              )}
              <CodeTemplate
                className={isIntro ? "developer-intro-code" : ""}
                language="shell"
                commandPrefix="$ "
                code={`curl -N --http2 -f -H "Accept:text/event-stream" ${endpoint}`}
              />
              {isIntro && (
                <div className="note">
                  <Typography className={classes.introNoteHeader}>
                    Note
                  </Typography>
                  <ul>{commonNotes}</ul>
                </div>
              )}
            </div>
          )}
          {language === 2 && (
            <div>
              {isIntro && (
                <React.Fragment>
                  <Typography className={classes.introText}>
                    Stream data from tenefit.cloud into your Python application.
                  </Typography>
                  <Typography className={classes.introText}>
                    Save the following code a file to a file and run it.
                  </Typography>
                </React.Fragment>
              )}
              <CodeTemplate
                className={isIntro ? "developer-intro-code" : ""}
                language="py"
                canSave={!isIntro}
                saveFilename={
                  !isIntro ? `template-${route.topicName}.py` : undefined
                }
                code={`#!/usr/bin/env python

url = '${endpoint}'

import requests                                   # pip install requests
from hyper.contrib import HTTP20Adapter           # pip install hyper
import sseclient                                  # pip install sseclient-py

session = requests.Session()
session.mount('https://', HTTP20Adapter())
response = session.get(url, stream=True)
client = sseclient.SSEClient(response)

for event in client.events():
    print event.data
`}
              />
              {isIntro && (
                <div className="note">
                  <Typography className={classes.introNoteHeader}>
                    Note
                  </Typography>
                  <ul>
                    {commonNotes}
                    <li>
                      <Typography className={classes.introText}>
                        Make sure your SSL library (e.g. <code>pyOpenSSL</code>)
                        is fairly current.
                      </Typography>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  private handleChange = (event: ChangeEvent<object>, value: number) => {
    // Don't do anything if the user clicked the same tab they're already on.
    if (value !== this.props.language) {
      this.props.selectDeveloperIntroStepLanguage("step2", value);
    }
  };
}

export default withStyles(styles)(StreamTemplates);
