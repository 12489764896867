export enum RouteType {
  sse = "sse",
  rest = "rest",
  mqttOverTls = "mqttOverTls",
  mqttOverWss = "mqttOverWss",
  amqpOverTls = "amqpOverTls",
  amqpOverWss = "amqpOverWss",
  kafka = "kafka",
}

// routePatternExpression is a regex that can be used to match against other.
// routePatterns to look for colliding routes. It is a client-side only field.
// replyTo only applies to REST routes, and even then is optional.
export type Route = {
  routeType: RouteType;
  routePattern: string;
  topicName?: string;
  replyTo?: string;
  uid?: string;
  routePatternExpression?: string;
};

export type Mapping = Readonly<{
  location: string;
  store: string;
  routes: ReadonlyArray<Route>;
}>;

export type EdgeConfigConfig = Readonly<{
  kafka: string;
  isMsk: boolean;
  store: string | null;
  mapping: ReadonlyArray<Mapping>;
}>;

export type EdgeConfig = Readonly<{
  name: string;
  organization: string;
  version: number;
  caCertFilename: string | null;
  clientCertFilename: string | null;
  clientKeyFilename: string | null;
  config: Readonly<EdgeConfigConfig>;
  edgeState: EdgeState;
  edgeConfigState: EdgeConfigState;
  lastModified: Date;
  topics: string[];
  isConnected: boolean;
  isTestingConnection: boolean;
}>;

export type EdgeState = "INIT" | "RESTARTING" | "RUNNING" | "ERROR";

export type EdgeConfigState = "NEW" | "DEPLOYED";
