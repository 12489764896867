import { combineEpics, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { filter, ignoreElements, mergeMap, tap } from "rxjs/operators";
import Types from "Types";
import { isOfType } from "typesafe-actions";

import * as gtm from "../../services/googleTagManager";
import * as api from "../../services/api";
import { FETCH_SESSION_USER_SUCCESS } from "../users/constants";
import * as userPrefsActions from "./actions";
import {
  EXPAND_DEVELOPER_INTRO_STEP,
  HIDE_DEVELOPER_INTRO,
  HINT_NEWCONFIG_CONFIG_NAME,
  HINT_NEWCONFIG_DISMISS_ADD_ENDPOINT,
  HINT_NEWCONFIG_DISMISS_ENDPOINT,
  HINT_NEWCONFIG_DISMISS_SAVE,
  HINT_NEWCONFIG_DISMISS_TOPIC,
  SELECT_DEVELOPER_INTRO_STEP_LANGUAGE,
  SHOW_DEVELOPER_INTRO,
} from "./constants";

export const fetchUserPrefs: Epic<
  Types.RootAction,
  Types.RootAction,
  Types.RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(FETCH_SESSION_USER_SUCCESS)),
    filter((_) => state$.value.users.sessionUserName != null),
    mergeMap((action) =>
      from(api.fetchUserPrefs(state$.value.users.sessionUserName!)).pipe(
        mergeMap((userPrefs) =>
          of(userPrefsActions.fetchUserPrefsSuccess(userPrefs))
        )
      )
    )
  );

export const storeUserPrefs: Epic<
  Types.RootAction,
  Types.RootAction,
  Types.RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      (action) =>
        isOfType(HIDE_DEVELOPER_INTRO)(action) ||
        isOfType(SHOW_DEVELOPER_INTRO)(action) ||
        isOfType(EXPAND_DEVELOPER_INTRO_STEP)(action) ||
        isOfType(SELECT_DEVELOPER_INTRO_STEP_LANGUAGE)(action) ||
        isOfType(HINT_NEWCONFIG_CONFIG_NAME)(action) ||
        isOfType(HINT_NEWCONFIG_DISMISS_ADD_ENDPOINT)(action) ||
        isOfType(HINT_NEWCONFIG_DISMISS_ENDPOINT)(action) ||
        isOfType(HINT_NEWCONFIG_DISMISS_TOPIC)(action) ||
        isOfType(HINT_NEWCONFIG_DISMISS_SAVE)(action)
    ),
    filter((_) => state$.value.users.sessionUserName != null),
    mergeMap((action) =>
      api.storeUserPrefs(
        state$.value.users.sessionUserName!,
        state$.value.userPrefs
      )
    ),
    ignoreElements()
  );

export const showDevIntro: Epic<
  Types.RootAction,
  Types.RootAction,
  Types.RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(SHOW_DEVELOPER_INTRO)),
    filter((action) => !action.payload), // Only send event if user clicked show, not when new user signs in first time
    tap((_) => gtm.devIntroShow()),
    ignoreElements()
  );

export const hideDevIntro: Epic<
  Types.RootAction,
  Types.RootAction,
  Types.RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(HIDE_DEVELOPER_INTRO)),
    tap((_) => gtm.devIntroHide()),
    ignoreElements()
  );

export default combineEpics(
  fetchUserPrefs,
  hideDevIntro,
  showDevIntro,
  storeUserPrefs
);
