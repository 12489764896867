import { combineEpics, Epic } from "redux-observable";
import { concat, of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import Types from "Types";
import { isOfType } from "typesafe-actions";

import * as api from "../../services/api";
import { addNewAppThunks } from "../addNewApp";
import { domainsActions } from "../domains";
import { keysActions } from "../keys";
import { userPrefsActions } from "../userPrefs";
import * as actions from "./actions";
import { CREATE_ORGANIZATION, FETCH_ORGANIZATION } from "./constants";

export const fetchOrganization: Epic<
  Types.RootAction,
  Types.RootAction,
  Types.RootState
> = (action$) =>
  action$.pipe(
    filter(isOfType(FETCH_ORGANIZATION)),
    mergeMap((action) =>
      api.getOrganization(action.payload.orgName).pipe(
        mergeMap((organization) =>
          concat(
            of(actions.fetchOrganizationSuccess(organization)),
            of(domainsActions.fetchDomains(organization.name)),
            of(keysActions.fetchKeys(organization.name))
          )
        ),
        catchError((err) =>
          of(actions.fetchOrganizationFailure(action.payload.orgName, err))
        )
      )
    )
  );

export const createOrganization: Epic<
  Types.RootAction,
  any,
  Types.RootState
> = (action$) =>
  action$.pipe(
    filter(isOfType(CREATE_ORGANIZATION)),
    mergeMap((action) =>
      api.createOrganization(action.payload.orgName).pipe(
        mergeMap((organization) => {
          return concat(
            of(actions.createOrganizationSuccess(organization)),
            of(userPrefsActions.showDeveloperIntro(true)),
            api
              .getDomains(organization.name)
              .pipe(
                map((domains) => domainsActions.fetchDomainsSuccess(domains))
              ),
            of(addNewAppThunks.createSandbox(organization.name, true))
          );
        }),
        catchError((err) =>
          of(actions.createOrganizationFailure(action.payload.orgName, err))
        )
      )
    )
  );

export default combineEpics(fetchOrganization, createOrganization);
