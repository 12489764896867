import {
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteAllIcon from "@material-ui/icons/DeleteForever";
import DoneIcon from "@material-ui/icons/Done";
import Number3Icon from "@material-ui/icons/Looks3";
import Number1Icon from "@material-ui/icons/LooksOne";
import Number2Icon from "@material-ui/icons/LooksTwo";
import RefreshIcon from "@material-ui/icons/Refresh";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import React, { PureComponent } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import KafkaTopicTableRowContainer from "../containers/KafkaTopicTableRowContainer";
import { edgeConfigsModels } from "../features/edgeConfigs";
import { RouteType } from "../features/edgeConfigs/models";
import { UserPrefsState } from "../features/userPrefs";
import { detokenizePath, routeTypeToNiceName } from "../utils/utils";
import { routeTypeToSchemeGui } from "../utils/utils";
import HintArrow from "./core/HintArrow";
import TooltipIconButton from "./core/TooltipIconButton";
import KafkaTopicSelect from "./KafkaTopicSelect";

import "./KafkaTopicTable.css";
import "./KafkaTopicTableRow.css";

export type KafkaTopicTableProps = {
  location: string;
  routes: ReadonlyArray<edgeConfigsModels.Route>;
  isTesting: boolean;
  kafkaTopics: string[];
  edgeState: edgeConfigsModels.EdgeState;
  userPrefs: UserPrefsState;
  isMsk: boolean;
  configName?: string;
  openFAQ: () => void;
  onAddRoute: (
    routeType: RouteType,
    topicName: string,
    routePattern: string,
    replyTo: string | null
  ) => void;
  onEditRoutePattern: (
    route: edgeConfigsModels.Route,
    newRoutePattern: string
  ) => void;
  onReorderRoutes: (startIndex: number, endIndex: number) => void;
  onDeleteRoute: (route: edgeConfigsModels.Route) => void;
  onDeleteAllRoutes: () => void;
  onClickMapSseEndpoints: () => void;
  onTestConnection: () => void;
  dismissNewConfigAddEndpointHint: () => void;
  dismissNewConfigEndpointHint: () => void;
  dismissNewConfigTopicHint: () => void;
  dismissNewConfigSaveHint: () => void;
};

export type KafkaTopicTableState = {
  isAdding: boolean;
  addingRouteType: RouteType;
  addingTopicName: string;
  addingTopicPath: string;
  addingReplyTo: string;
  addButtonRef: HTMLElement | null;
  endpointRef: HTMLElement | null;
  topicRef: HTMLElement | null;
  saveRef: HTMLElement | null;
};

type CollisionMap = {
  [routePattern: string]: string[];
};

// Used to minimize how often new RegExp's are created
export type TableRoute = edgeConfigsModels.Route & {
  regexp: RegExp;
};

class KafkaTopicTable extends PureComponent<
  KafkaTopicTableProps,
  KafkaTopicTableState
> {
  public state = {
    isAdding: false,
    addingRouteType: RouteType.sse,
    addingTopicName: "",
    addingTopicPath: "",
    addingReplyTo: "",
    addButtonRef: null,
    endpointRef: null,
    topicRef: null,
    saveRef: null,
  };

  public render() {
    const {
      configName,
      location,
      routes,
      isTesting,
      kafkaTopics,
      edgeState,
      userPrefs,
      isMsk,
      onTestConnection,
      onEditRoutePattern,
      onDeleteRoute,
      onDeleteAllRoutes,
      onClickMapSseEndpoints,
      openFAQ,
      dismissNewConfigAddEndpointHint,
      dismissNewConfigEndpointHint,
      dismissNewConfigTopicHint,
      dismissNewConfigSaveHint,
    } = this.props;
    const {
      isAdding,
      addingRouteType,
      addingTopicName,
      addingTopicPath,
      addingReplyTo,
      addButtonRef,
      endpointRef,
      topicRef,
      saveRef,
    } = this.state;
    const { usedTopics, unusedTopics } = this.partitionTopics();
    const existingPaths = routes.map((route) => route.routePattern);
    const addingTopicPathSlashPrefixed = `/${addingTopicPath}`;

    const collisionMap = {} as CollisionMap;
    const tableRoutes: ReadonlyArray<TableRoute> = this.calculateCollisions(
      routes,
      collisionMap,
      addingTopicPath,
      addingTopicPathSlashPrefixed,
      addingRouteType
    );

    const addingTopicPathCollides =
      addingTopicPathSlashPrefixed in collisionMap &&
      collisionMap[addingTopicPathSlashPrefixed].length > 0;

    const showHintAddEndpoint =
      configName === userPrefs.hints.newConfig.configName &&
      !userPrefs.hints.newConfig.dismissedAddEndpoint;
    const showHintEndpoint =
      configName === userPrefs.hints.newConfig.configName &&
      !userPrefs.hints.newConfig.dismissedEndpoint;
    const showHintTopic =
      configName === userPrefs.hints.newConfig.configName &&
      !userPrefs.hints.newConfig.dismissedTopic;
    const showHintSave =
      configName === userPrefs.hints.newConfig.configName &&
      !userPrefs.hints.newConfig.dismissedSave;

    return (
      <div className="KafkaTopicTable">
        <div className="KafkaTopicTableRow KafkaTopicTable-header">
          <div className="KafkaTopicTableRow-endpoint">
            <Typography variant="caption" align="left">
              Endpoint
            </Typography>
          </div>
          <div className="KafkaTopicTableRow-topic">
            <Typography variant="caption" align="left">
              Kafka Topic
            </Typography>
          </div>
          <div className="KafkaTopicTableRow-replyTo">
            <Typography variant="caption" align="left">
              Reply-To
            </Typography>
          </div>
          <div className="KafkaTopicTableRow-actions KafkaTopicTable-buttonContainer">
            <TooltipIconButton
              title="Open auto-mapping of SSE endpoints dialog"
              className="KafkaTopicTableRow-actions-sseMappings"
              onClick={onClickMapSseEndpoints}
            >
              <SettingsInputComponentIcon />
            </TooltipIconButton>
            <TooltipIconButton
              title="Add a new endpoint"
              onClick={this.handlePressAdd}
            >
              <span
                style={{ display: "inline-flex" }}
                ref={this.onAddButtonRefChange}
              >
                <AddIcon />
              </span>
            </TooltipIconButton>
            {showHintAddEndpoint && (
              <HintArrow
                anchor={addButtonRef}
                isOpen={showHintAddEndpoint}
                handleClose={dismissNewConfigAddEndpointHint}
                margin="10px"
                placement="top"
              >
                <Typography style={{ color: "#1a1a1c", padding: 5 }}>
                  Click here to add your first endpoint
                </Typography>
              </HintArrow>
            )}
            <TooltipIconButton
              title="Delete all endpoints"
              onClick={onDeleteAllRoutes}
              disabled={routes.length === 0}
            >
              <DeleteAllIcon />
            </TooltipIconButton>
          </div>
        </div>
        {isAdding && (
          <div className="KafkaTopicTableRow adding">
            <div className="KafkaTopicTableRow-endpoint adding">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="KafkaTopicTableRow-endpoint-routeType"
                value={addingRouteType}
                onChange={this.handleAddingRouteTypeChange}
              >
                <MenuItem value={RouteType.sse}>
                  {routeTypeToNiceName(RouteType.sse)}
                </MenuItem>
                <MenuItem value={RouteType.rest}>
                  {routeTypeToNiceName(RouteType.rest)}
                </MenuItem>
                <MenuItem value={RouteType.mqttOverTls}>
                  {routeTypeToNiceName(RouteType.mqttOverTls)}
                </MenuItem>
                <MenuItem value={RouteType.mqttOverWss}>
                  {routeTypeToNiceName(RouteType.mqttOverWss)}
                </MenuItem>
                <MenuItem value={RouteType.amqpOverTls}>
                  {routeTypeToNiceName(RouteType.amqpOverTls)}
                </MenuItem>
                <MenuItem value={RouteType.amqpOverWss}>
                  {routeTypeToNiceName(RouteType.amqpOverWss)}
                </MenuItem>
                <MenuItem value={RouteType.kafka} disabled={!isMsk}>
                  {routeTypeToNiceName(RouteType.kafka)}
                </MenuItem>
              </Select>
              <Typography align="left">
                {`${routeTypeToSchemeGui(addingRouteType)}${
                  new URL(location).host
                }/`}
              </Typography>
              <TextField
                inputRef={this.onEndpointRefChange}
                className="KafkaTopicTableRow-endpoint-pattern"
                value={addingTopicPath}
                onChange={this.handleAddingTopicPathChange}
                error={addingTopicPathCollides}
                disabled={addingRouteType === RouteType.kafka}
                helperText={
                  addingTopicPathCollides &&
                  `Collides with existing path patterns: ${collisionMap[
                    addingTopicPathSlashPrefixed
                  ].join(", ")}`
                }
              />
              {showHintEndpoint && (
                <HintArrow
                  anchor={endpointRef}
                  isOpen={showHintEndpoint}
                  handleClose={dismissNewConfigEndpointHint}
                  margin="15px"
                  placement="bottom"
                >
                  <div className="hintContent">
                    <Number1Icon className="hintContentNumber" />
                    <Typography>Enter a URL-compatible path</Typography>
                  </div>
                </HintArrow>
              )}
            </div>
            <div className="KafkaTopicTableRow-topic adding">
              <KafkaTopicSelect
                className="KafkaTopicTableRow-topic-select"
                fullWidth={true}
                usedTopics={usedTopics}
                unusedTopics={unusedTopics}
                value={addingTopicName}
                topicRef={this.onTopicRefChange}
                onChange={this.handleAddingTopicNameChange}
                disabled={addingRouteType === RouteType.kafka}
              />
              <TooltipIconButton
                title="Refresh Kafka topics"
                size="small"
                disabled={isTesting || addingRouteType === RouteType.kafka}
                onClick={onTestConnection}
              >
                {isTesting ? <CircularProgress size={24} /> : <RefreshIcon />}
              </TooltipIconButton>
            </div>
            {showHintTopic && (
              <HintArrow
                anchor={topicRef}
                isOpen={showHintTopic}
                handleClose={dismissNewConfigTopicHint}
                margin="15px"
                placement="right"
              >
                <div className="hintContent">
                  <Number2Icon className="hintContentNumber" />
                  <Typography>Choose a topic</Typography>
                </div>
              </HintArrow>
            )}
            <div className="KafkaTopicTableRow-replyTo adding">
              <KafkaTopicSelect
                fullWidth={true}
                disabled={addingRouteType !== RouteType.rest}
                helperText="Optional for REST endpoints"
                usedTopics={usedTopics}
                unusedTopics={unusedTopics}
                value={addingReplyTo}
                onChange={this.handleAddingReplyToChange}
              />
            </div>
            <div className="KafkaTopicTableRow-actions adding">
              <TooltipIconButton
                title="Save this endpoint"
                size="small"
                disabled={
                  (!addingTopicPath || !addingTopicName) &&
                  addingRouteType !== RouteType.kafka
                }
                onClick={this.handleSaveAddingTopic}
              >
                <span
                  style={{ display: "inline-flex" }}
                  ref={this.onSaveRefChange}
                >
                  <DoneIcon />
                </span>
              </TooltipIconButton>
              {showHintSave && (
                <HintArrow
                  anchor={saveRef}
                  isOpen={showHintSave}
                  handleClose={dismissNewConfigSaveHint}
                  margin="15px"
                  placement="bottom-start"
                >
                  <div className="hintContent">
                    <Number3Icon className="hintContentNumber" />
                    <Typography>Save your new endpoint</Typography>
                  </div>
                </HintArrow>
              )}
              <TooltipIconButton
                title="Discard"
                size="small"
                onClick={this.handleDiscardAddingTopic}
              >
                <ClearIcon />
              </TooltipIconButton>
            </div>
          </div>
        )}
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="routes">
            {(provided) => (
              <div ref={provided.innerRef}>
                {routes.map((route, i) => {
                  const routeWithTopicName: edgeConfigsModels.Route & {
                    topicName: string;
                  } = {
                    ...route,
                    topicName: route.topicName || "",
                  };
                  return (
                    <Draggable
                      key={route.uid}
                      draggableId={`${route.topicName}::${route.routePattern}::${route.uid}`}
                      index={i}
                    >
                      {(provided) => (
                        <div
                          key={route.uid}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <KafkaTopicTableRowContainer
                            location={location}
                            route={routeWithTopicName}
                            edgeState={edgeState}
                            existingPaths={existingPaths}
                            isTesting={isTesting}
                            topics={kafkaTopics}
                            canEditRoutePattern={true}
                            canDeleteRoute={true}
                            collisions={collisionMap[route.uid!]}
                            tableRoutes={tableRoutes}
                            onEditRoutePattern={onEditRoutePattern.bind(
                              undefined,
                              route
                            )}
                            onDelete={onDeleteRoute.bind(null, route)}
                            openFAQ={openFAQ}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }

  private calculateCollisions = (
    routes: ReadonlyArray<edgeConfigsModels.Route>,
    collisionMap: CollisionMap,
    addingTopicPath: string,
    addingTopicPathSlashPrefixed: string,
    addingRouteType: RouteType
  ): ReadonlyArray<TableRoute> => {
    // Initialize collisionMap and create regexp's once
    const tableRoutes: ReadonlyArray<TableRoute> = routes.map((route) => {
      collisionMap[route.uid!] = [];
      return {
        ...route,
        regexp: new RegExp(route.routePatternExpression!),
      };
    });
    if (addingTopicPath) {
      collisionMap[addingTopicPathSlashPrefixed] = [];
    }

    const addingTopicRegExp = new RegExp(
      detokenizePath(addingTopicPathSlashPrefixed)
    );
    // Look for routePattern collisions between routes. If adding a new route,
    // include checking that for collisions.
    // Collision detection is within the same route type.
    // e.g. MQTT over TCP paths do not collide with MQTT over WSS path.
    // Exception is that SSE and REST route type paths can collide.
    tableRoutes.forEach((route) => {
      const targetRouteTypes = [route.routeType];
      if (route.routeType === RouteType.sse) {
        targetRouteTypes.push(RouteType.rest);
      } else if (route.routeType === RouteType.rest) {
        targetRouteTypes.push(RouteType.sse);
      }
      tableRoutes
        .filter((r) => r.uid !== route.uid)
        .filter((r) => targetRouteTypes.includes(r.routeType))
        .forEach((r) => {
          if (route.routePattern.search(r.regexp) > -1) {
            if (
              collisionMap[route.uid!].indexOf(r.routePattern) === -1 &&
              collisionMap[route.uid!].indexOf(r.routePattern) === -1
            ) {
              collisionMap[route.uid!].push(r.routePattern);
            }
            if (
              collisionMap[r.uid!].indexOf(route.routePattern) === -1 &&
              collisionMap[r.uid!].indexOf(route.routePattern) === -1
            ) {
              collisionMap[r.uid!].push(route.routePattern);
            }
          }
        });
      if (addingTopicPath && targetRouteTypes.includes(addingRouteType)) {
        if (
          addingTopicPathSlashPrefixed.search(route.regexp) > -1 &&
          collisionMap[addingTopicPathSlashPrefixed].indexOf(
            route.routePattern
          ) === -1
        ) {
          collisionMap[addingTopicPathSlashPrefixed].push(route.routePattern);
        }
        if (
          route.routePattern.search(addingTopicRegExp) > -1 &&
          collisionMap[addingTopicPathSlashPrefixed].indexOf(
            route.routePattern
          ) === -1
        ) {
          collisionMap[addingTopicPathSlashPrefixed].push(route.routePattern);
        }
      }
    });

    return tableRoutes;
  };

  private partitionTopics = (): {
    usedTopics: string[];
    unusedTopics: string[];
  } => {
    const routeTopics = new Set(
      this.props.routes.map((route) => route.topicName)
    );
    const usedTopics: string[] = [];
    const unusedTopics: string[] = [];
    this.props.kafkaTopics.forEach((topic) => {
      if (routeTopics.has(topic)) {
        usedTopics.push(topic);
      } else {
        unusedTopics.push(topic);
      }
    });
    return { usedTopics, unusedTopics };
  };

  private handlePressAdd = () => {
    this.setState({ isAdding: true });
    this.props.dismissNewConfigAddEndpointHint();
  };

  private handleAddingRouteTypeChange = (
    evt: React.ChangeEvent<{ value: unknown }>
  ) => {
    const prevRouteType = this.state.addingRouteType;
    const target = evt.target as HTMLInputElement;
    const newRouteType = RouteType[target.value as keyof typeof RouteType];
    this.setState({
      addingRouteType: newRouteType,
    });
    if (newRouteType === RouteType.kafka) {
      this.setState({
        addingTopicPath: "",
        addingTopicName: "",
        addingReplyTo: "",
      });
    }
    // If changing away from rest, clear the replyTo field, otherwise it looks confusing on the screen.
    if (prevRouteType) {
      this.setState({
        addingReplyTo: "",
      });
    }
  };

  private handleAddingTopicNameChange = (
    evt: React.ChangeEvent<{ value: unknown }>
  ) => {
    const target = evt.target as HTMLInputElement;
    this.setState({
      addingTopicName: target.value,
      addingTopicPath: this.state.addingTopicPath || `topic/${target.value}`,
    });
  };

  private handleAddingReplyToChange = (
    evt: React.ChangeEvent<{ value: unknown }>
  ) => {
    const target = evt.target as HTMLInputElement;
    this.setState({
      addingReplyTo: target.value,
    });
  };

  private handleAddingTopicPathChange: React.FormEventHandler = (evt) => {
    const target = evt.target as HTMLInputElement;
    let newPath = target.value;

    let valueChanged = false;
    let cursorPos = target.selectionStart || 1;

    // Eliminate leading slash as it's automatically provided
    if (newPath.startsWith("/")) {
      newPath = newPath.substring(1);
      valueChanged = true;
      cursorPos--;
    }

    // Double slashes should be prevented.
    if (newPath.indexOf("//") > -1) {
      newPath = newPath.replace(/\/\//g, "/");
      valueChanged = true;
      cursorPos--;
    }

    this.setState({ addingTopicPath: newPath });

    if (valueChanged) {
      // setTimeout() is used because the browser will move cursor to the end
      // of input field if the value changed.
      setTimeout(() => {
        target.setSelectionRange(cursorPos, cursorPos);
      });
    }
  };

  private handleSaveAddingTopic = () => {
    this.props.dismissNewConfigEndpointHint();
    this.props.dismissNewConfigTopicHint();
    this.props.dismissNewConfigSaveHint();
    const replyTo =
      this.state.addingRouteType === RouteType.rest &&
      this.state.addingReplyTo.trim().length > 0
        ? this.state.addingReplyTo
        : null;
    this.props.onAddRoute(
      this.state.addingRouteType,
      this.state.addingTopicName,
      `/${this.state.addingTopicPath}`,
      replyTo
    );
    this.handleDiscardAddingTopic();
  };

  private handleDiscardAddingTopic = () =>
    this.setState({
      isAdding: false,
      addingTopicName: "",
      addingTopicPath: "",
      addingReplyTo: "",
    });

  private onDragEnd = (result: any) => {
    const { source, destination } = result;
    if (destination && source.index !== destination.index) {
      this.props.onReorderRoutes(source.index, destination.index);
    }
  };

  private onAddButtonRefChange = (node: HTMLElement): void => {
    this.setState({ addButtonRef: node });
  };

  private onEndpointRefChange = (node: HTMLElement): void => {
    this.setState({ endpointRef: node });
  };

  private onTopicRefChange = (node: HTMLElement): void => {
    this.setState({ topicRef: node });
  };

  private onSaveRefChange = (node: HTMLElement): void => {
    this.setState({ saveRef: node });
  };
}

export default KafkaTopicTable;
