import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import * as actions from "./actions";
import {
  EXPAND_DEVELOPER_INTRO_STEP,
  FETCH_USER_PREFS_SUCCESS,
  HIDE_DEVELOPER_INTRO,
  HINT_NEWCONFIG_CONFIG_NAME,
  HINT_NEWCONFIG_DISMISS_ADD_ENDPOINT,
  HINT_NEWCONFIG_DISMISS_ENDPOINT,
  HINT_NEWCONFIG_DISMISS_SAVE,
  HINT_NEWCONFIG_DISMISS_TOPIC,
  SELECT_DEVELOPER_INTRO_STEP_LANGUAGE,
  SHOW_DEVELOPER_INTRO,
} from "./constants";

export type UserPrefsAction = ActionType<typeof actions>;

type HintsType = {
  newConfig: {
    configName?: string;
    dismissedAddEndpoint: boolean;
    dismissedEndpoint: boolean;
    dismissedTopic: boolean;
    dismissedSave: boolean;
  };
};

export type UserPrefsState = {
  isShowingDeveloperIntro: boolean;
  expandedStep: string | null;
  step1Language: number;
  step2Language: number;
  hints: HintsType;
};

const initialHints = {
  newConfig: {
    dismissedAddEndpoint: false,
    dismissedEndpoint: false,
    dismissedTopic: false,
    dismissedSave: false,
  },
} as HintsType;

const reduceStepLanguage = (
  state: number = 0,
  action: UserPrefsAction,
  stepNumber: number
) => {
  const step = `step${stepNumber}Language`;
  switch (action.type) {
    case SELECT_DEVELOPER_INTRO_STEP_LANGUAGE:
      if (action.payload.step === `step${stepNumber}`) {
        return action.payload.language;
      } else {
        return state;
      }
    case FETCH_USER_PREFS_SUCCESS:
      return step in action.payload ? action.payload[step] : state;
    default:
      return state;
  }
};

export default combineReducers<UserPrefsState, UserPrefsAction>({
  isShowingDeveloperIntro: (state = false, action) => {
    switch (action.type) {
      case HIDE_DEVELOPER_INTRO:
        return false;
      case SHOW_DEVELOPER_INTRO:
        return true;
      case FETCH_USER_PREFS_SUCCESS:
        return "isShowingDeveloperIntro" in action.payload
          ? action.payload.isShowingDeveloperIntro
          : state;
      default:
        return state;
    }
  },

  expandedStep: (state = "step3", action) => {
    switch (action.type) {
      case FETCH_USER_PREFS_SUCCESS:
        return "expandedStep" in action.payload
          ? action.payload.expandedStep
          : state;
      case EXPAND_DEVELOPER_INTRO_STEP:
        return action.payload.step;
      default:
        return state;
    }
  },

  step1Language: (state = 0, action) => reduceStepLanguage(state, action, 1),

  step2Language: (state = 0, action) => reduceStepLanguage(state, action, 2),

  hints: (state = initialHints, action) => {
    switch (action.type) {
      case HINT_NEWCONFIG_CONFIG_NAME:
        return {
          ...state,
          newConfig: {
            ...state.newConfig,
            configName: action.payload.configName,
          },
        };
      case HINT_NEWCONFIG_DISMISS_ADD_ENDPOINT:
        return {
          ...state,
          newConfig: { ...state.newConfig, dismissedAddEndpoint: true },
        };
      case HINT_NEWCONFIG_DISMISS_ENDPOINT:
        return {
          ...state,
          newConfig: { ...state.newConfig, dismissedEndpoint: true },
        };
      case HINT_NEWCONFIG_DISMISS_TOPIC:
        return {
          ...state,
          newConfig: { ...state.newConfig, dismissedTopic: true },
        };
      case HINT_NEWCONFIG_DISMISS_SAVE:
        return {
          ...state,
          newConfig: { ...state.newConfig, dismissedSave: true },
        };
      default:
        return state;
    }
  },
});
