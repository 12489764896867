import {
  IconButton,
  Snackbar,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";
import deepPurple from "@material-ui/core/colors/deepPurple";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import { fade } from "@material-ui/core/styles/colorManipulator";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import DoneIcon from "@material-ui/icons/Done";
import classnames from "classnames";
import React, { PureComponent } from "react";

import { copyToClipboard } from "../clipboard";
import { edgeConfigsModels } from "../features/edgeConfigs";
import {
  detokenizePath,
  routeTypeToAnnotation,
  routeTypeToSchemeGui,
} from "../utils/utils";
import CopyIcon from "./icons/Copy";
import { TableRoute } from "./KafkaTopicTable";

import "./Endpoint.css";

const ENTER_KEY_CODE = 13;
const ESCAPE_KEY_CODE = 27;

export type EndpointProps = {
  uid: string;
  routeType: edgeConfigsModels.RouteType;
  baseUrl: string;
  routePattern: string;
  routePatternBlacklist: string[];
  canEditRoutePattern: boolean;
  tableRoutes: ReadonlyArray<TableRoute>;
  setEditing: (isEditing: boolean) => void;
  onChange?: (routePattern: string) => void;
};

export type EndpointState = {
  isEditing: boolean;
  dirtyRoutePattern?: string;
  isDirtyRoutePatternValid: boolean;
  errorText?: string;
  isEditorFocused: boolean;
  copiedSnackbarOpen: boolean;
};

const styles = (theme: Theme) => ({
  endpointAnnotation: {
    backgroundColor: fade(theme.palette.primary.light, 0.75),
    borderRadius: "2px",
    padding: "1px 3px",
    paddingBottom: 0,
    top: 3,
    color: "#ffffff",
    marginRight: 10,
    fontSize: "9px",
    width: "32px",
    fontWeight: 300,
  },
  sse: {
    backgroundColor: orange[50],
    color: orange[900],
  },
  rest: {
    backgroundColor: blue[50],
    color: blue[900],
  },
  mqttOverTls: {
    backgroundColor: green[50],
    color: green[900],
  },
  mqttOverWss: {
    backgroundColor: green[50],
    color: green[900],
  },
  amqpOverTls: {
    backgroundColor: red[50],
    color: red[800],
  },
  amqpOverWss: {
    backgroundColor: red[50],
    color: red[800],
  },
  kafka: {
    backgroundColor: deepPurple[50],
    color: deepPurple[900],
  },
  iconButton: {
    height: 20,
    width: 20,
    marginLeft: 8,
    padding: 0,
  },
  endpointEdit: {
    flexGrow: 1,
    top: 10,
  },
});

class Endpoint extends PureComponent<
  EndpointProps & WithStyles<typeof styles>,
  EndpointState
> {
  public state = {
    isEditing: false,
    dirtyRoutePattern: undefined,
    isDirtyRoutePatternValid: false,
    errorText: undefined,
    isEditorFocused: false,
    copiedSnackbarOpen: false,
  };

  // tslint:disable-next-line:variable-name
  private _isMounted = false;

  public componentDidMount() {
    this._isMounted = true;
    window.addEventListener("keydown", this.handleKeyDown);
  }

  public componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  public render() {
    const { routeType, baseUrl, canEditRoutePattern, classes } = this.props;
    const {
      isEditing,
      dirtyRoutePattern,
      isDirtyRoutePatternValid,
      errorText,
      copiedSnackbarOpen,
    } = this.state;
    const routeTypeEl = (
      <Typography
        className={classnames(classes.endpointAnnotation, classes[routeType])}
        align="center"
      >
        {routeTypeToAnnotation(routeType)}
      </Typography>
    );
    return isEditing ? (
      <div className="Endpoint-wrapper">
        {routeTypeEl}
        <Typography>{new URL(baseUrl).href.slice(0, -1)}</Typography>
        <TextField
          className={classes.endpointEdit}
          autoFocus={true}
          value={dirtyRoutePattern}
          error={!isDirtyRoutePatternValid}
          helperText={!isDirtyRoutePatternValid ? errorText : " "}
          onChange={this.handleChange}
          onFocus={this.handleTextFieldFocus}
          onBlur={this.handleTextFieldBlur}
        />
        <IconButton onClick={this.commitChange} className={classes.iconButton}>
          <DoneIcon className="Endpoint-button-icon" />
        </IconButton>
        <IconButton onClick={this.discardChange} className={classes.iconButton}>
          <ClearIcon className="Endpoint-button-icon" />
        </IconButton>
      </div>
    ) : (
      <div className="Endpoint-wrapper">
        {routeTypeEl}
        <Typography>{this.buildEndpoint()}</Typography>
        {canEditRoutePattern ? (
          <Tooltip
            placement="top"
            title="Edit endpoint"
            disableFocusListener={true}
          >
            <IconButton
              onClick={this.startEditing}
              className={classes.iconButton}
            >
              <CreateIcon className="Endpoint-button-icon" />
            </IconButton>
          </Tooltip>
        ) : (
          <span />
        )}
        <Tooltip
          placement="top"
          title="Copy endpoint"
          disableFocusListener={true}
        >
          <IconButton onClick={this.handleCopy} className={classes.iconButton}>
            <CopyIcon className="Endpoint-button-icon" />
          </IconButton>
        </Tooltip>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={copiedSnackbarOpen}
          message={<span>Copied to clipboard</span>}
        />
      </div>
    );
  }

  private buildEndpoint = () => {
    const { routeType, baseUrl, routePattern } = this.props;
    return `${routeTypeToSchemeGui(routeType)}${
      new URL(baseUrl).host
    }${routePattern}`;
  };

  private handleKeyDown = (e: KeyboardEvent) => {
    const { isEditorFocused } = this.state;
    if (isEditorFocused) {
      if (e.keyCode === ENTER_KEY_CODE) {
        this.commitChange();
      } else if (e.keyCode === ESCAPE_KEY_CODE) {
        this.discardChange();
      }
    }
  };

  /**
   * Checks if the current routePattern collides with existing routePatterns.
   * Returns a list of routePatterns that collide.
   * Collision detection is within the same route type.
   * e.g. MQTT over TCP paths do not collide with MQTT over WSS path.
   * Exception is that SSE and REST route type paths can collide.
   */
  private checkRoutePatternCollisions = (routePattern: string): string[] => {
    const collisions = [] as string[];
    const valueRegExp = new RegExp(detokenizePath(routePattern));
    const targetRouteTypes = [this.props.routeType];
    if (this.props.routeType === edgeConfigsModels.RouteType.sse) {
      targetRouteTypes.push(edgeConfigsModels.RouteType.rest);
    } else if (this.props.routeType === edgeConfigsModels.RouteType.rest) {
      targetRouteTypes.push(edgeConfigsModels.RouteType.sse);
    }
    this.props.tableRoutes
      .filter((route) => route.uid !== this.props.uid)
      .filter((route) => targetRouteTypes.includes(route.routeType))
      .forEach((route) => {
        if (
          routePattern.search(route.regexp) > -1 &&
          collisions.indexOf(route.routePattern) === -1
        ) {
          collisions.push(route.routePattern);
        }
        if (
          route.routePattern.search(valueRegExp) > -1 &&
          collisions.indexOf(route.routePattern) === -1
        ) {
          collisions.push(route.routePattern);
        }
      });
    return collisions;
  };

  private handleChange: React.FormEventHandler = (evt) => {
    const target = evt.target as HTMLInputElement;
    let { value } = target;

    let valueChanged = false;
    let cursorPos = target.selectionStart || 1;

    let isDirtyRoutePatternValid = true;
    let errorText = "";

    // We should really verify the path is valid format for a URL, but
    // for now, at least, don't allow spaces.
    if (value.indexOf(" ") > -1) {
      value = value.replace(/\s/g, "");
      valueChanged = true;
      cursorPos--;
    }

    // The path must always have a slash (/) at the front.
    if (value.length === 0 || value[0] !== "/") {
      value = `/${value}`;
      valueChanged = true;
    }

    // Double slashes should be prevented.
    if (value.indexOf("//") > -1) {
      value = value.replace(/\/\//g, "/");
      valueChanged = true;
      cursorPos--;
    }

    const collisions = this.checkRoutePatternCollisions(value);
    if (collisions.length > 0) {
      isDirtyRoutePatternValid = false;
      errorText = `Collides with existing path patterns: ${collisions.join(
        ", "
      )}`;
    }

    this.setState({
      dirtyRoutePattern: value,
      isDirtyRoutePatternValid,
      errorText,
    });

    if (valueChanged) {
      // setTimeout() is used because the browser will move cursor to the end
      // of input field if the value changed.
      setTimeout(() => {
        target.setSelectionRange(cursorPos, cursorPos);
      });
    }
  };

  private handleTextFieldFocus = () => {
    this.setState({ isEditorFocused: true });
  };

  private handleTextFieldBlur = () => {
    this.setState({ isEditorFocused: false });
  };

  private handleCopy = () => {
    copyToClipboard(this.buildEndpoint());
    this.setState({ copiedSnackbarOpen: true });
    setTimeout(() => {
      this.setState({ copiedSnackbarOpen: false });
    }, 2000);
  };

  private startEditing = () => {
    this.setState({
      isEditing: true,
      dirtyRoutePattern: this.props.routePattern,
      isDirtyRoutePatternValid: true,
      errorText: "",
      isEditorFocused: false,
    });
    this.props.setEditing(true);

    const collisions = this.checkRoutePatternCollisions(
      this.props.routePattern
    );
    if (collisions.length > 0) {
      this.setState({
        isDirtyRoutePatternValid: false,
        errorText: `Collides with existing path patterns: ${collisions.join(
          ", "
        )}`,
      });
    }
  };

  private commitChange = () => {
    if (this.state.dirtyRoutePattern !== this.props.routePattern) {
      if (this.props.onChange) {
        this.props.onChange(this.state.dirtyRoutePattern!);
      }
    }
    this.discardChange();
  };

  private discardChange = () => {
    if (this._isMounted) {
      this.setState({ isEditing: false, dirtyRoutePattern: undefined });
      this.props.setEditing(false);
    }
  };
}

export default withStyles(styles)(Endpoint);
