export const FETCH_USER_PREFS_SUCCESS = "FETCH_USER_PREFS_SUCCESS";

export const HIDE_DEVELOPER_INTRO = "HIDE_DEVELOPER_INTRO";

export const SHOW_DEVELOPER_INTRO = "SHOW_DEVELOPER_INTRO";

export const EXPAND_DEVELOPER_INTRO_STEP = "EXPAND_DEVELOPER_INTRO_STEP";

export const SELECT_DEVELOPER_INTRO_STEP_LANGUAGE =
  "SELECT_DEVELOPER_INTRO_STEP_LANGUAGE";

export const HINT_NEWCONFIG_CONFIG_NAME = "HINT_NEWCONFIG_CONFIG_NAME";
export const HINT_NEWCONFIG_DISMISS_ADD_ENDPOINT =
  "HINT_NEWCONFIG_DISMISS_ADD_ENDPOINT";
export const HINT_NEWCONFIG_DISMISS_ENDPOINT =
  "HINT_NEWCONFIG_DISMISS_ENDPOINT";
export const HINT_NEWCONFIG_DISMISS_TOPIC = "HINT_NEWCONFIG_DISMISS_TOPIC";
export const HINT_NEWCONFIG_DISMISS_SAVE = "HINT_NEWCONFIG_DISMISS_SAVE";
