import { EdgeConfigsState } from "./reducer";

export const getEdgeConfigs = (state: EdgeConfigsState) => {
  const temporaryEdgeConfigName = state.temporaryEdgeConfigName;
  return Object.keys(state.byName)
    .filter((key) => key !== temporaryEdgeConfigName)
    .map((key) => state.byName[key]);
};

export const getEdgeConfig = (state: EdgeConfigsState, configName: string) =>
  configName in state.byName ? state.byName[configName] : null;

export const getTemporaryEdgeConfig = (state: EdgeConfigsState) => {
  return state.temporaryEdgeConfigName
    ? getEdgeConfig(state, state.temporaryEdgeConfigName)
    : null;
};
