import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { ChangeEvent, PureComponent } from "react";

import { edgeConfigsModels } from "../../features/edgeConfigs";
import * as gtm from "../../services/googleTagManager";
import "./DeveloperIntro.css";
import DeveloperIntroStepsStep1 from "./DeveloperIntroStepsStep1";
import DeveloperIntroStepsStep2 from "./DeveloperIntroStepsStep2";
import DeveloperIntroStepsStep3 from "./DeveloperIntroStepsStep3";

export type DeveloperIntroProps = {
  username: string;
  sandboxEdgeConfig: edgeConfigsModels.EdgeConfig;
  expandedStep: string | null;
  step1Language: number;
  step2Language: number;
  hideDeveloperIntro: () => void;
  handleExpandDeveloperIntroStep: (step: string | null) => void;
  selectDeveloperIntroStepLanguage: (step: string, language: number) => void;
};

export const introTextStyle = { marginBottom: "1em" };

export const introNoteHeaderStyle = {
  fontWeight: 700,
  marginBottom: 10,
};

const styles = (theme: Theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "50%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  introText: introTextStyle,
  introNoteHeader: introNoteHeaderStyle,
});

class DeveloperIntro extends PureComponent<
  DeveloperIntroProps & WithStyles<typeof styles>
> {
  public render() {
    const {
      username,
      sandboxEdgeConfig,
      expandedStep,
      hideDeveloperIntro,
      step1Language,
      step2Language,
      selectDeveloperIntroStepLanguage,
      classes,
    } = this.props;
    const origin: string =
      sandboxEdgeConfig &&
      sandboxEdgeConfig.config &&
      sandboxEdgeConfig.config.mapping.length > 0
        ? new URL(sandboxEdgeConfig.config.mapping[0].location).origin
        : "https://YOUR-SUBDOMAIN.streaming.tenefit.cloud";
    const topicName = `${username}.TOPIC_NAME`;
    const route: edgeConfigsModels.Route & {
      topicName: string;
    } = {
      uid: `/${topicName}`,
      routeType: edgeConfigsModels.RouteType.sse,
      routePattern: `/${topicName}`,
      topicName,
    };
    return (
      <div className="dev-intro">
        <div className="dev-intro-header">
          <Typography variant="h6" className="dev-intro-header-title">
            Developer Intro
          </Typography>
          <Tooltip
            title="Hide the intro help (it can be shown again from the menu, above)"
            placement="top"
            disableFocusListener={true}
          >
            <IconButton onClick={hideDeveloperIntro} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className="dev-intro-body">
          <div className="dev-intro-body-description">
            <Typography className={classes.introText} gutterBottom={true}>
              Build resilient, real-time apps powered by Server-Sent Events
              (SSE) and Apache Kafka with tenefit.cloud. With just a few steps
              and relying only on web-standards, you’ll be able to stream
              messages to mobile and desktop apps at scale.
            </Typography>
            <Typography className={classes.introText} gutterBottom={true}>
              To quickly get started, we offer a pre-configured Kafka that
              tenefit.cloud is connected to (the “Sandbox”). Simply publish
              messages to it as described below and tenefit.cloud will
              automatically generate live SSE endpoints that you can use to
              receive message streams on your clients.
            </Typography>
            <Typography className={classes.introText} gutterBottom={true}>
              <strong>Disclaimer:</strong> The tenefit.cloud sandbox is designed
              for prototyping purposes only and is provided without any security
              or performance guarantees. If you’re interested in using
              tenefit.cloud in a production environment please{" "}
              <a
                href="https://tenefit.cloud/contact/"
                target="_blank"
                rel="noopener noreferrer"
              >
                talk to us
              </a>
              .
            </Typography>
          </div>

          <div className={classes.root}>
            <Accordion
              expanded={expandedStep === "step1"}
              onChange={this.handleStepChange("step1")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  <strong>Step 1:</strong> Publish messages to the Sandbox
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DeveloperIntroStepsStep1
                  username={username}
                  language={step1Language}
                  selectDeveloperIntroStepLanguage={
                    selectDeveloperIntroStepLanguage
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedStep === "step2"}
              onChange={this.handleStepChange("step2")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  <strong>Step 2:</strong> Stream Messages to Your Client
                  Application
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DeveloperIntroStepsStep2
                  origin={origin}
                  route={route}
                  language={step2Language}
                  selectDeveloperIntroStepLanguage={
                    selectDeveloperIntroStepLanguage
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedStep === "step3"}
              onChange={this.handleStepChange("step3")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  <strong>Additional Information</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DeveloperIntroStepsStep3 username={username} />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    );
  }

  private handleStepChange = (step: string) => (
    event: ChangeEvent<object>,
    expanded: boolean
  ) => {
    // Only send a Google Tag Manager event if the user opens a step, not closes it.
    if (step !== this.props.expandedStep) {
      gtm.devIntroExpandStep(step);
    }
    this.props.handleExpandDeveloperIntroStep(expanded ? step : null);
  };
}

export default withStyles(styles)(DeveloperIntro);
