import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Types from "Types";

import DeveloperIntro, {
  DeveloperIntroProps,
} from "../components/developerIntro/DeveloperIntro";
import { userPrefsActions } from "../features/userPrefs";
import { TENEFIT_SANDBOX_CONFIG_NAME } from "../globalConstants";

const DeveloperIntroContainer = (props: DeveloperIntroProps) => {
  return <DeveloperIntro {...props} />;
};

const mapStateToProps = (state: Types.RootState) => {
  return {
    username: state.users.sessionUserName!,
    sandboxEdgeConfig:
      state.organizations.byName[state.users.sessionUserName!].edgeConfigs
        .byName[TENEFIT_SANDBOX_CONFIG_NAME],
    isFetchingOrganization: state.organizations.isFetchingOrganization,
    isShowingDeveloperIntro: state.userPrefs.isShowingDeveloperIntro,
    expandedStep: state.userPrefs.expandedStep,
    step1Language: state.userPrefs.step1Language,
    step2Language: state.userPrefs.step2Language,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  hideDeveloperIntro: () => dispatch(userPrefsActions.hideDeveloperIntro()),
  showDeveloperIntro: () => dispatch(userPrefsActions.showDeveloperIntro()),
  handleExpandDeveloperIntroStep: (step: string | null) =>
    dispatch(userPrefsActions.expandDeveloperIntroStep(step)),
  selectDeveloperIntroStepLanguage: (step: string, language: number) =>
    dispatch(userPrefsActions.selectDeveloperIntroStepLanguage(step, language)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeveloperIntroContainer);
