import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import * as actions from "./actions";
import {
  AUTH_ERROR,
  CHANGE_STATE,
  SET_DELIVERY,
  SET_VERIFY_ATTRIBUTE,
  SIGN_OUT,
} from "./constants";
import { AuthState as State } from "./models";

export type AuthState = {
  readonly state: State;
  readonly data: any;
  readonly error: Error | null;
  readonly delivery: any;
  readonly verifyAttribute: string | null;
};

export type AuthAction = ActionType<typeof actions>;

export default combineReducers<AuthState, AuthAction>({
  data: (state = null, action) => {
    switch (action.type) {
      case CHANGE_STATE:
        return action.payload.data || null;
      case SIGN_OUT:
        return null;
      default:
        return state;
    }
  },
  delivery: (state = null, action) => {
    switch (action.type) {
      case SET_DELIVERY:
        return action.payload;
      default:
        return state;
    }
  },
  error: (state = null, action) => {
    switch (action.type) {
      case AUTH_ERROR:
        return action.payload;
      case CHANGE_STATE:
      case SIGN_OUT:
        return null;
      default:
        return state;
    }
  },
  state: (state = State.signIn, action) => {
    switch (action.type) {
      case CHANGE_STATE:
        return action.payload.state;
      case SIGN_OUT:
        return State.signIn;
      default:
        return state;
    }
  },
  verifyAttribute: (state = null, action) => {
    switch (action.type) {
      case SET_VERIFY_ATTRIBUTE:
        return action.payload;
      default:
        return state;
    }
  },
});
