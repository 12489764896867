import { ThunkAction } from "redux-thunk";
import Types from "Types";

import {
  MSK_HOSTNAME,
  TENEFIT_SANDBOX_CONFIG_NAME,
} from "../../globalConstants";
import * as gtm from "../../services/googleTagManager";
import {
  edgeConfigsActions,
  edgeConfigsModels,
  edgeConfigsSelectors,
  edgeConfigsThunks,
} from "../edgeConfigs";
import { organizationsSelectors } from "../organizations";
import { userPrefsActions } from "../userPrefs";
import * as actions from "./actions";

export const TENEFIT_SANDBOX_AUTHORITY = "kafka-sandbox.tenefit.cloud:9092";
export const TENEFIT_SANDBOX_URL = `tcp://${TENEFIT_SANDBOX_AUTHORITY}`;

const SANDBOX_CREATE_START_MESSAGE = `Creating sandbox configuration named ${TENEFIT_SANDBOX_CONFIG_NAME}...`;
const SANDBOX_CREATE_COMPLETE_MESSAGE = `Sandbox configuration named ${TENEFIT_SANDBOX_CONFIG_NAME} created.`;
const SANDBOX_CREATE_FAIL_MESSAGE = `You already have a sandbox app, named ${TENEFIT_SANDBOX_CONFIG_NAME}.`;
const SANDBOX_CONNECT_START_MESSAGE = "Connecting to the sandbox...";
const SANDBOX_CONNECT_COMPLETE_MESSAGE = "Connected to the sandbox.";
const SANDBOX_CONNECT_FAIL_MESSAGE =
  "The tenefit.cloud sandbox is unavailable at this time. Please try again later.";
const SANDBOX_POPULATE_START_MESSAGE =
  "Populating the sandbox configuration with sample endpoints...";
const SANDBOX_POPULATE_COMPLETE_MESSAGE = (domain: string) =>
  `Created a sandbox app at https://${domain}`;
const SANDBOX_POPULATE_FAIL_MESSAGE =
  "Unable to update sandbox configuration. Please try again later.";

const DIRECT_CREATE_START_MESSAGE = "Creating Kafka configuration...";
const DIRECT_CREATE_COMPLETE_MESSAGE = "Kafka configuration created.";
const DIRECT_CREATE_FAIL_MESSAGE = (errorMesssage: string): string =>
  `Unable to create your configuration: ${errorMesssage}`;
const DIRECT_CLIENT_CERT_MISSING_FILE_FAIL_MESSAGE =
  "For a client certificate, both the certificate file AND key file must be provided.";
const DIRECT_TEST_START_MESSAGE = (address: string, isMskSandbox: boolean) =>
  `Testing our connection to ${
    isMskSandbox ? "the MSK sandbox cluster" : address
  }`;
const DIRECT_TEST_COMPLETE_MESSAGE = (numberOfTopics: number) =>
  `We successfully connected to your Kafka cluster and discovered ${numberOfTopics} topics.`;
const DIRECT_TEST_FAIL_MESSAGE =
  "We were unable to connect to your Kafka cluster. Please verify that your cluster is reachable at the specified address.";
const DIRECT_TEST_FAIL_MESSAGE_CERTS =
  "We were unable to connect to your Kafka cluster. It is either unreachable or there was a problem with a cert file.";
const DIRECT_APP_START_MESSAGE = "Creating a new empty app...";
const DIRECT_APP_COMPLETE_MESSAGE = (domain: string, isMskSandbox: boolean) =>
  `New app created.`;
const DIRECT_APP_FAIL_MESSAGE = "Unable to update your configuration";

export const confirmCreate = (
  orgName: string
): ThunkAction<void, Types.RootState, void, Types.RootAction> => {
  return (dispatch, getState) => {
    const organization = organizationsSelectors.getOrganization(
      getState().organizations,
      orgName
    )!;
    const createdKafka = edgeConfigsSelectors.getTemporaryEdgeConfig(
      organization.edgeConfigs
    );
    dispatch(edgeConfigsActions.finalizeTemporaryEdgeConfig(organization.name));
    dispatch(actions.resetCreateNewAppDialog());
    dispatch(actions.closeCreateNewAppDialog());
    if (createdKafka!.name === TENEFIT_SANDBOX_CONFIG_NAME) {
      gtm.addNewAppDialogCreateConfigSandbox(
        createdKafka!.name,
        createdKafka!.config.kafka
      );
    } else {
      gtm.addNewAppDialogCreateConfigCustom(
        createdKafka!.name,
        createdKafka!.config.kafka
      );
    }
  };
};

export const cancelCreate = (
  orgName: string
): ThunkAction<Promise<void>, Types.RootState, void, Types.RootAction> => {
  return async (dispatch, getState) => {
    const organization = organizationsSelectors.getOrganization(
      getState().organizations,
      orgName
    )!;
    const createdKafka = edgeConfigsSelectors.getTemporaryEdgeConfig(
      organization.edgeConfigs
    );
    if (createdKafka) {
      dispatch(edgeConfigsActions.remove(createdKafka.name, organization.name));
    }
    dispatch(actions.resetCreateNewAppDialog());
    dispatch(actions.closeCreateNewAppDialog());
    gtm.addNewAppDialogCancel();
  };
};

// If isIntroSandox is true, then this is sandbox that is automatically created when a
// new user signs in for the first time.
// If it is false, then it was created by the user using the Add New App button.
export const createSandbox = (
  orgName: string,
  isIntroSandox: boolean = false
): ThunkAction<Promise<void>, Types.RootState, void, Types.RootAction> => {
  return async (dispatch, getState) => {
    if (isIntroSandox) {
      dispatch(actions.createIntroSandboxStart());
    }

    dispatch(actions.clearMessages());

    // create and save the sandbox config
    if (!isIntroSandox) {
      dispatch(actions.startTask(SANDBOX_CREATE_START_MESSAGE));
    }
    try {
      await dispatch(
        createOrUpdate(
          TENEFIT_SANDBOX_CONFIG_NAME,
          orgName,
          TENEFIT_SANDBOX_URL,
          null,
          null,
          null
        )
      );
      await dispatch(
        edgeConfigsThunks.saveNextVersion(TENEFIT_SANDBOX_CONFIG_NAME, orgName)
      );
      if (!isIntroSandox) {
        dispatch(actions.completeTask(SANDBOX_CREATE_COMPLETE_MESSAGE));
      }
    } catch (err) {
      dispatch(actions.failTask(SANDBOX_CREATE_FAIL_MESSAGE));
      if (isIntroSandox) {
        dispatch(actions.createIntroSandboxFinish());
      }
      return;
    }

    // test the connection and discover topics
    if (!isIntroSandox) {
      dispatch(actions.startTask(SANDBOX_CONNECT_START_MESSAGE));
    }
    await dispatch(
      edgeConfigsThunks.testConnection(TENEFIT_SANDBOX_CONFIG_NAME, orgName)
    );
    const organization = organizationsSelectors.getOrganization(
      getState().organizations,
      orgName
    );
    const edgeConfig = edgeConfigsSelectors.getTemporaryEdgeConfig(
      organization.edgeConfigs
    )!;
    if (!edgeConfig.isConnected) {
      dispatch(actions.failTask(SANDBOX_CONNECT_FAIL_MESSAGE));
      if (isIntroSandox) {
        dispatch(actions.createIntroSandboxFinish());
      }
      return;
    }
    if (!isIntroSandox) {
      dispatch(actions.completeTask(SANDBOX_CONNECT_COMPLETE_MESSAGE));
    }

    // create an app with default mappings
    if (!isIntroSandox) {
      dispatch(actions.startTask(SANDBOX_POPULATE_START_MESSAGE));
    }
    const freeDomain = organizationsSelectors.getUnusedDomainNames(
      organization,
      getState().domains
    )[0];
    try {
      const routes = [
        {
          routeType: edgeConfigsModels.RouteType.sse,
          routePattern: "/$topic",
        },
      ];
      dispatch(
        edgeConfigsActions.addMapping(
          TENEFIT_SANDBOX_CONFIG_NAME,
          orgName,
          `https://${freeDomain.domainName}:443`,
          undefined,
          routes
        )
      );
      await dispatch(
        edgeConfigsThunks.saveNextVersion(TENEFIT_SANDBOX_CONFIG_NAME, orgName)
      );
      if (!isIntroSandox) {
        dispatch(
          actions.completeTask(
            SANDBOX_POPULATE_COMPLETE_MESSAGE(freeDomain.domainName)
          )
        );
      }
    } catch (error) {
      dispatch(actions.failTask(SANDBOX_POPULATE_FAIL_MESSAGE));
    }
    if (isIntroSandox) {
      dispatch(
        edgeConfigsActions.finalizeTemporaryEdgeConfig(organization.name)
      );
      dispatch(actions.createIntroSandboxFinish());
    }
  };
};

export const createAndTest = (
  name: string,
  orgName: string,
  protocol: string,
  domain: string,
  port: number,
  keyName?: string
): ThunkAction<Promise<void>, Types.RootState, void, Types.RootAction> => {
  return async (dispatch, getState) => {
    dispatch(actions.clearMessages());
    const address = `${protocol}://${domain}:${port}`;

    dispatch(actions.startTask(DIRECT_CREATE_START_MESSAGE));

    dispatch(userPrefsActions.setNewConfigName(name));

    const caCert = getState().addNewApp.caCert;
    const clientCert = getState().addNewApp.clientCert;
    const clientKey = getState().addNewApp.clientKey;
    // Ensure that if doing a client cert, that both the client cert AND client have been provided.
    if ((clientCert && !clientKey) || (!clientCert && clientKey)) {
      dispatch(actions.failTask(DIRECT_CLIENT_CERT_MISSING_FILE_FAIL_MESSAGE));
      return;
    }

    try {
      await dispatch(
        createOrUpdate(
          name,
          orgName,
          address,
          caCert ? caCert.name : null,
          clientCert ? clientCert.name : null,
          clientKey ? clientKey.name : null,
          keyName
        )
      );
      dispatch(actions.completeTask(DIRECT_CREATE_COMPLETE_MESSAGE));
    } catch (err) {
      dispatch(actions.failTask(DIRECT_CREATE_FAIL_MESSAGE(err)));
      return;
    }

    dispatch(
      actions.startTask(
        DIRECT_TEST_START_MESSAGE(address, domain === MSK_HOSTNAME)
      )
    );
    await dispatch(edgeConfigsThunks.testConnection(name, orgName));
    const organization = organizationsSelectors.getOrganization(
      getState().organizations,
      orgName
    )!;
    const edgeConfig = edgeConfigsSelectors.getTemporaryEdgeConfig(
      organization.edgeConfigs
    )!;
    if (!edgeConfig) {
      // The edgeConfig can be undefined in certain corner cases. e.g. The kafkaTopics API call
      // took a long time to time out because it couldn't connect to the Kafka. In the meantime
      // the store was cleaned up, but the API returns with an error asynchronously later, and
      // continues here.
      // In that case, the error was already handled, and the message given to the user. So simply
      // stop here.
      return;
    }
    if (!edgeConfig.isConnected) {
      if (caCert || clientCert) {
        dispatch(actions.failTask(DIRECT_TEST_FAIL_MESSAGE_CERTS));
      } else {
        dispatch(actions.failTask(DIRECT_TEST_FAIL_MESSAGE));
      }
      return;
    }
    dispatch(
      actions.completeTask(
        DIRECT_TEST_COMPLETE_MESSAGE(edgeConfig.topics.length)
      )
    );

    dispatch(actions.startTask(DIRECT_APP_START_MESSAGE));
    const freeDomain = organizationsSelectors.getUnusedDomainNames(
      organization,
      getState().domains
    )[0];
    try {
      const routes = [] as edgeConfigsModels.Route[];
      await dispatch(
        edgeConfigsActions.addMapping(
          name,
          orgName,
          `https://${freeDomain.domainName}:443/`,
          undefined,
          routes
        )
      );
      await dispatch(edgeConfigsThunks.saveNextVersion(name, orgName));
      dispatch(
        actions.completeTask(
          DIRECT_APP_COMPLETE_MESSAGE(
            freeDomain.domainName,
            freeDomain.domainName === MSK_HOSTNAME
          )
        )
      );
    } catch (err) {
      dispatch(actions.failTask(DIRECT_APP_FAIL_MESSAGE));
      return;
    }
  };
};

const createOrUpdate = (
  name: string,
  orgName: string,
  address: string,
  caCertFilename: string | null,
  clientCertFilename: string | null,
  clientKeyFilename: string | null,
  keyName?: string
): ThunkAction<Promise<void>, Types.RootState, void, Types.RootAction> => {
  return async (dispatch, getState) => {
    const organization = organizationsSelectors.getOrganization(
      getState().organizations,
      orgName
    )!;
    const createdKafka = edgeConfigsSelectors.getTemporaryEdgeConfig(
      organization.edgeConfigs
    );
    if (createdKafka && name !== createdKafka.name) {
      dispatch(
        edgeConfigsActions.remove(createdKafka.name, createdKafka.organization)
      );
    }
    if (createdKafka) {
      dispatch(
        edgeConfigsActions.updateConfig(
          name,
          orgName,
          {
            ...createdKafka.config,
            kafka: address,
            store: keyName ? `${orgName}/${keyName}` : null,
          },
          createdKafka.version,
          caCertFilename,
          clientCertFilename,
          clientKeyFilename
        )
      );
    } else {
      dispatch(
        edgeConfigsActions.createEdgeConfig(
          name,
          orgName,
          address,
          caCertFilename,
          clientCertFilename,
          clientKeyFilename,
          keyName
        )
      );
    }
    await dispatch(edgeConfigsThunks.saveNextVersion(name, orgName));
  };
};
